import {
    Component,
    OnInit,
    ViewChild,
    Output,
    EventEmitter,
    Injector,
    ChangeDetectorRef,
    ElementRef
} from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"
import { Usuario, Usuarios, UsuarioRegistroUah, UsuarioRegistroUahForm } from "@puntaje/puntaje/api-services"
import { EmbedPreferenciaCarrerasBySedeComponent } from "../../preferencia_carreras/embed_preferencia_carreras_by_sede.component"

@Component({
    selector: "app-register-uah",
    templateUrl: "./register-uah.component.html",
    styleUrls: ["./register-uah.component.scss"]
})
export class RegisterUahComponent implements OnInit {
    usuarioRegistro: UsuarioRegistroUah = new UsuarioRegistroUah()
    usuarioRegistroForm: UsuarioRegistroUahForm
    params: any = UsuarioRegistroUahForm.formParams
    form: UntypedFormGroup
    save_button_text = "Guardar"
    saved: boolean = false
    universidades: string[] = ["Universidad Alberto Hurtado"]

    egresado: Clasificacion

    @Output() onTitleChange: EventEmitter<string> = new EventEmitter<string>()
    @ViewChild("preferencias") preferencias: EmbedPreferenciaCarrerasBySedeComponent

    constructor(
        protected usuariosService: Usuarios,
        protected clasificacionesService: Clasificaciones,
        protected router: Router,
        protected injector: Injector,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.form = UsuarioRegistroUahForm.getForm(this.usuarioRegistro, null, this.injector)

        this.clasificacionesService
            .where({
                clasificacion_tipo: { clasificacion_tipo: "curso" },
                clasificacion: { clasificacion: "Egresado" }
            })
            .then((clasificaciones: Clasificacion[]) => {
                this.egresado = clasificaciones[0]
            })
    }

    save() {
        UsuarioRegistroUahForm.markFormControlsAsTouched(this.form)
        if (this.form.valid) {
            this.usuariosService.save(this.usuarioRegistro.toUsuario()).then(response => {
                this.onTitleChange.emit("¡Ya casi estás listo!")
                this.saved = true
            })
        }
    }

    public clear() {
        setTimeout(() => {
            this.usuarioRegistro = new UsuarioRegistroUah()
            UsuarioRegistroUahForm.markFormControlsAsPristine(this.form)
            UsuarioRegistroUahForm.markFormControlsAsUntouched(this.form)
            this.preferencias.reset()
        }, 150)
    }

    back() {
        this.router.navigate([""])
    }

    updatePreferencias(preferencias) {
        this.usuarioRegistro.preferencias_carrera = preferencias
    }
}
