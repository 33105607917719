import { BaseForm, Validations, GroupValidator, AppConfig } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { Clasificaciones } from "@puntaje/nebulosa/api-services"
import { UsuariosCompletarRegistroSocialUahChileForm } from "./usuarios_completar_registro_social_uah_chile.form"

declare const config: AppConfig

export class UsuariosCompletarRegistroSocialUahForm extends BaseForm {
    public static get globalValidations(): GroupValidator[] {
        return [Validations.equalPasswords(["password", "password_confirmation"])]
    }

    companyName: string = ""

    public static get formParams(): any {
        const companyName = config.plataforma.info.companyName
        const cursos: string[] = ["Egresado", "Cuarto Medio", "Tercero Medio", "Segundo Medio", "Primero Medio"]

        const params: any = {
            id: { label: "ID", type: "hidden", visible: false },
            nombre: {
                label: "Nombre",
                type: "text",
                validations: [Validators.required],
                visible: true
            },
            apellido_paterno: {
                label: "Apellido Paterno",
                type: "text",
                validations: [Validators.required],
                visible: true
            },
            apellido_materno: {
                label: "Apellido Materno",
                type: "text",
                validations: [],
                visible: true
            },
            terminos_y_condiciones: {
                innerhtml:
                    "He leído y acepto las <a href='/condicionesUso' target='_blank'>condiciones generales de uso de " +
                    companyName +
                    "</a>",
                type: "checkbox",
                visible: true,
                validations: [Validators.requiredTrue],
                customError: "Debe aceptar los términos generales de uso de " + companyName
            },
            nivel_id: {
                label: "Curso",
                type: "select",
                visible: true,
                options: {
                    class: Clasificaciones,
                    params: {
                        clasificacion_tipo: { clasificacion_tipo: "curso" },
                        clasificacion: { clasificacion: cursos }
                    }
                }
            },
            preferencia: { label: "Carrera", type: "select", visible: true },
            usuario_chile: {
                type: "model",
                class: UsuariosCompletarRegistroSocialUahChileForm
            }
        }

        return params
    }
}
