import { ChangeDetectorRef, Component, Input, SimpleChanges } from "@angular/core"
import { Pregunta, Preguntas, GrupoPregunta, Clasificacion } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "tabla-clasificaciones-pregunta",
    templateUrl: "tabla_clasificaciones_pregunta.component.html",
    styleUrls: ["tabla_clasificaciones_pregunta.component.scss"]
})
export class TablaClasificacionesPreguntaComponent {
    _pregunta: Pregunta
    clasificacionesEnabled: any = {}
    clasificaciones: any = {}
    disableIfSelectedEmpty: boolean = true
    @Input() evaluacionTipo: string = null

    constructor(private cdr: ChangeDetectorRef) {
        this.clasificaciones = {
            "habilidad cognitiva": { clasificacion: "Habilidades cognitivas", lista: [], enabled: false },
            "habilidad cognitiva LGE": { clasificacion: "Habilidades cognitivas LGE", lista: [], enabled: false },
            "objetivo de aprendizaje": { clasificacion: "Objetivo de Aprendizaje", lista: [], enabled: false },
            "eje temático": { clasificacion: "Eje temático", lista: [], enabled: false },
            "sub eje tematico": { clasificacion: "Sub-eje temático", lista: [], enabled: false },
            "eje temático LGE": { clasificacion: "Eje temático LGE", lista: [], enabled: false },
            "indicador LGE": { clasificacion: "indicador LGE", lista: [], enabled: false },
            curso: { clasificacion: "Curso", lista: [], enabled: false },
            dificultad: { clasificacion: "Dificultad declarada por docente", lista: [], enabled: false },
            "nivel colombia": { clasificacion: "Nivel", lista: [], enabled: false },
            competencia: { clasificacion: "Competencia", lista: [], enabled: false },
            componente: { clasificacion: "Componente", lista: [], enabled: false },
            "nivel 1 prueba de transición": {
                clasificacion: "nivel 1 prueba de transición",
                lista: [],
                enabled: false
            },
            "nivel 2 prueba de transición": {
                clasificacion: "nivel 2 prueba de transición",
                lista: [],
                enabled: false
            },
            "nivel 3 prueba de transición": {
                clasificacion: "nivel 3 prueba de transición",
                lista: [],
                enabled: false
            },
            "habilidad prueba de transición": {
                clasificacion: "habilidad prueba de transición",
                lista: [],
                enabled: false
            },
            "subhabilidad prueba de transición": {
                clasificacion: "subhabilidad prueba de transición",
                lista: [],
                enabled: false
            },
            Bloque_mx: {
                clasificacion: "Bloque de contenidos",
                lista: [],
                enabled: false
            },
            Temas_mx: {
                clasificacion: "Componente",
                lista: [],
                enabled: false
            },
            Subtemas_mx: {
                clasificacion: "Sub-Componente",
                lista: [],
                enabled: false
            },
            "componente turetoco": {
                clasificacion: "Componente",
                lista: [],
                enabled: false
            },
            "competencia turetoco": {
                clasificacion: "Competencia",
                lista: [],
                enabled: false
            },
            "Categoría organizadora_CO": {
                clasificacion: "Categoria organizadora",
                lista: [],
                enabled: false
            },
            "Derechos básicos de aprendizaje_CO": {
                clasificacion: "Derecho básico de aprendizaje",
                lista: [],
                enabled: false
            },
            "Eje PAES": {
                clasificacion: "Eje PAES",
                lista: [],
                enabled: false
            },
            "Unidad Temática PAES": {
                clasificacion: "Unidad Temática PAES",
                lista: [],
                enabled: false
            },
            "Tema PAES": {
                clasificacion: "Tema PAES",
                lista: [],
                enabled: false
            },
            "Habilidad PAES": {
                clasificacion: "Habilidad PAES",
                lista: [],
                enabled: false
            },
            "Situación de lectura PAES": {
                clasificacion: "Situación de lectura PAES",
                lista: [],
                enabled: false
            }
        }
    }

    @Input()
    get pregunta(): Pregunta {
        return this._pregunta
    }

    set pregunta(p: Pregunta) {
        this._pregunta = p

        this.updateClasificaciones()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["evaluacionTipo"]) {
            this.updateClasificaciones()
        }
    }

    updateClasificaciones() {
        this.enableClasificaciones()
        if (this.pregunta) {
            this.pregunta.clasificaciones.forEach(c => {
                if (
                    this.clasificaciones[c.clasificacion_tipo.clasificacion_tipo] &&
                    this.clasificaciones[c.clasificacion_tipo.clasificacion_tipo].enabled
                ) {
                    this.clasificaciones[c.clasificacion_tipo.clasificacion_tipo].lista.push(c)
                    //Suponemos que si encontró al menos 1 clasificacion dentro de las
                    //categorías habilitadas, debería mostrar algo
                    this.disableIfSelectedEmpty = false
                }
            })
        }
        this.cdr.detectChanges()
    }

    resetClasificaciones() {
        Object.keys(this.clasificaciones).forEach(clasificacionTipo => {
            this.clasificaciones[clasificacionTipo].enabled = false
            this.clasificaciones[clasificacionTipo].lista = []
        })
    }

    //Esto debería hacerse en un config, pero por ahora una versión preliminar
    enableClasificaciones() {
        this.resetClasificaciones()

        switch (config.plataforma.name) {
            case "Aprendolibre Pro":
            case "Aprendolibre":
                this.clasificaciones["habilidad cognitiva LGE"].enabled = true
                this.clasificaciones["habilidad cognitiva LGE"].lista = []

                this.clasificaciones["objetivo de aprendizaje"].enabled = true
                this.clasificaciones["objetivo de aprendizaje"].lista = []

                this.clasificaciones["eje temático LGE"].enabled = true
                this.clasificaciones["eje temático LGE"].lista = []

                this.clasificaciones["curso"].enabled = true
                this.clasificaciones["curso"].lista = []

                this.clasificaciones["dificultad"].enabled = true
                this.clasificaciones["dificultad"].lista = []
                break

            case "Puntaje Nacional Colombia":
                this.clasificaciones["nivel colombia"].enabled = true
                this.clasificaciones["nivel colombia"].lista = []

                this.clasificaciones["competencia"].enabled = true
                this.clasificaciones["competencia"].lista = []

                this.clasificaciones["componente"].enabled = true
                this.clasificaciones["componente"].lista = []
                break

            case "Aprendolibre Colombia":
                this.clasificaciones["dificultad"].enabled = true
                this.clasificaciones["dificultad"].lista = []

                break

            case "Aprendolibre Mexico":
                this.clasificaciones["Bloque_mx"].enabled = true
                this.clasificaciones["Bloque_mx"].lista = []
                this.clasificaciones["Temas_mx"].enabled = true
                this.clasificaciones["Temas_mx"].lista = []
                this.clasificaciones["dificultad"].enabled = true
                this.clasificaciones["dificultad"].lista = []

                break

            case "Guanajuato":
                this.clasificaciones["Subtemas_mx"].enabled = true
                this.clasificaciones["Subtemas_mx"].lista = []
                this.clasificaciones["Temas_mx"].enabled = true
                this.clasificaciones["Temas_mx"].lista = []
                this.clasificaciones["dificultad"].enabled = true
                this.clasificaciones["dificultad"].lista = []

                break

            case "TuRetoCo":
                this.clasificaciones["componente turetoco"].enabled = true
                this.clasificaciones["componente turetoco"].lista = []
                this.clasificaciones["competencia turetoco"].enabled = true
                this.clasificaciones["competencia turetoco"].lista = []
                this.clasificaciones["dificultad"].enabled = true
                this.clasificaciones["dificultad"].lista = []

                break
            case "Nivelate":
                this.clasificaciones["Categoría organizadora_CO"].enabled = true
                this.clasificaciones["Categoría organizadora_CO"].lista = []
                this.clasificaciones["Derechos básicos de aprendizaje_CO"].enabled = true
                this.clasificaciones["Derechos básicos de aprendizaje_CO"].lista = []
                this.clasificaciones["dificultad"].enabled = true
                this.clasificaciones["dificultad"].lista = []

                break
            default:
                if (this.evaluacionTipo == "ensayo" || this.evaluacionTipo == null) {
                    this.clasificaciones["habilidad cognitiva"].enabled = true
                    this.clasificaciones["habilidad cognitiva"].lista = []

                    this.clasificaciones["eje temático"].enabled = true
                    this.clasificaciones["eje temático"].lista = []

                    this.clasificaciones["sub eje tematico"].enabled = true
                    this.clasificaciones["sub eje tematico"].lista = []
                }

                this.clasificaciones["curso"].enabled = true
                this.clasificaciones["curso"].lista = []

                this.clasificaciones["dificultad"].enabled = true
                this.clasificaciones["dificultad"].lista = []

                if (this.evaluacionTipo == "prueba de transición" || this.evaluacionTipo == null) {
                    this.clasificaciones["nivel 1 prueba de transición"].enabled = true
                    this.clasificaciones["nivel 1 prueba de transición"].lista = []

                    this.clasificaciones["nivel 2 prueba de transición"].enabled = true
                    this.clasificaciones["nivel 2 prueba de transición"].lista = []

                    this.clasificaciones["nivel 3 prueba de transición"].enabled = true
                    this.clasificaciones["nivel 3 prueba de transición"].lista = []

                    this.clasificaciones["habilidad prueba de transición"].enabled = true
                    this.clasificaciones["habilidad prueba de transición"].lista = []

                    this.clasificaciones["subhabilidad prueba de transición"].enabled = true
                    this.clasificaciones["subhabilidad prueba de transición"].lista = []
                }

                if (this.evaluacionTipo == "curricular" || this.evaluacionTipo == null) {
                    this.clasificaciones["eje temático LGE"].enabled = true
                    this.clasificaciones["eje temático LGE"].lista = []

                    this.clasificaciones["habilidad cognitiva LGE"].enabled = true
                    this.clasificaciones["habilidad cognitiva LGE"].lista = []

                    this.clasificaciones["objetivo de aprendizaje"].enabled = true
                    this.clasificaciones["objetivo de aprendizaje"].lista = []

                    this.clasificaciones["indicador LGE"].enabled = true
                    this.clasificaciones["indicador LGE"].lista = []
                }

                if (this.evaluacionTipo == "paes") {
                    this.clasificaciones["Eje PAES"].enabled = true
                    this.clasificaciones["Eje PAES"].lista = []

                    this.clasificaciones["Unidad Temática PAES"].enabled = true
                    this.clasificaciones["Unidad Temática PAES"].lista = []

                    this.clasificaciones["Tema PAES"].enabled = true
                    this.clasificaciones["Tema PAES"].lista = []

                    this.clasificaciones["Habilidad PAES"].enabled = true
                    this.clasificaciones["Habilidad PAES"].lista = []

                    this.clasificaciones["curso"].enabled = false
                    this.clasificaciones["curso"].lista = []

                    this.clasificaciones["dificultad"].enabled = false
                    this.clasificaciones["dificultad"].lista = []
                }

                break
        }
    }
}
