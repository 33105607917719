<div *ngIf="evaluacionSugerida" class="evaluacion-card {{ asignatura?.clase }}" title="{{ evaluacionSugerida.nombre }}">
    <ogr-icon *ngIf="asignatura" name="{{ asignatura?.icono }}" class="card-icon"></ogr-icon>
    <span class="inner-wrap">
        <span class="evaluacion">{{ evaluacionSugerida.nombre }}</span>
        <span *ngIf="asignatura" class="asignatura">
            {{ asignatura["confByEvaluacionTipo"][evaluacionTipo].abreviacion }}
        </span>
    </span>
    <span class="info-wrap">
        <span class="info preguntas">
            <fa name="pencil-square-o" class="icono"></fa>
            {{ evaluacionSugerida.evaluacion.instrumento.numero_preguntas }}
            {{ "evaluacion_recomendada.preguntas_abreviacion" | t }}.
        </span>
        <span class="info tiempo" *ngIf="evaluacionSugerida.evaluacion.instrumento.tiempo">
            <fa name="clock-o" class="icono"></fa>
            {{ evaluacionSugerida.evaluacion.instrumento.tiempo }} min
        </span>
    </span>
    <span class="comenzar">
        <a [routerLink]="['/evaluacion', evaluacionSugerida.evaluacion.id, 'estadistica']">
            <fa name="bar-chart" class="icono left"></fa>
            {{ "evaluacion_recomendada.estadisticas" | t }}
        </a>
        <a [routerLink]="[baseUrl, evaluacionSugerida.evaluacion.id, 'realizar']">
            <fa *ngIf="evaluacionSugerida?.evaluacion_realizada" name="repeat" class="icono left"></fa>
            {{ textButton }}
            <fa *ngIf="!evaluacionSugerida?.evaluacion_realizada" name="caret-right" class="icono right"></fa>
        </a>
    </span>
</div>
