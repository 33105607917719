<ng-container *ngIf="!saved">
    <form novalidate [formGroup]="form" *ngIf="form">
        <div class="row">
            <div class="col-md-6">
                <h3>Datos Personales</h3>
                <form-input [form]="form" [params]="params['id']" [(value)]="usuario['id']" [key]="'id'"></form-input>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['usuario_id']"
                    [(value)]="usuario['usuario_' + pais]['usuario_id']"
                    [key]="'usuario_id'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['nombre']"
                    [(value)]="usuario['nombre']"
                    [key]="'nombre'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['apellido_paterno']"
                    [(value)]="usuario['apellido_paterno']"
                    [key]="'apellido_paterno'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['apellido_materno']"
                    [(value)]="usuario['apellido_materno']"
                    [key]="'apellido_materno'"
                ></form-input>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['telefono']"
                    [(value)]="usuario['usuario_' + pais]['telefono']"
                    [key]="'telefono'"
                ></form-input>
            </div>

            <div class="col-md-6">
                <h3>Datos Académicos</h3>

                <form-input
                    [form]="form"
                    [params]="params['nivel_id']"
                    [(value)]="usuario['nivel_id']"
                    [key]="'nivel_id'"
                ></form-input>

                <!-- Descomentar si se necesita usar la api de carreras
                <embed-preferencia-carrera-sedes-by-sede
                    #preferencias
                    [universidadesNombres]="universidades"
                    [form]="form"
                    [amount]="1"
                    (emitPreferencias)="updatePreferencias($event)"
                ></embed-preferencia-carrera-sedes-by-sede> -->
                <embed-preferencia-carrera
                    [amount]="1"
                    [form]="form"
                    (emit_preferencias)="updatePreferencias($event)"
                ></embed-preferencia-carrera>

                <form-input
                    [form]="form"
                    [params]="params['terminos_y_condiciones']"
                    [(value)]="usuario['terminos_y_condiciones']"
                    [key]="'terminos_y_condiciones'"
                ></form-input>

                <button (click)="save()" type="button" class="btn btn-default btn-block submit-btn">Actualizar</button>
                <button (click)="back()" type="button" class="btn btn-default btn-block cancel-btn">
                    Volver a la página de Inicio
                </button>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="saved">
    Hemos actualizado correctamente tus datos. Te redirigemos a la página principal en un instante.
</ng-container>
