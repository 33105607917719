<div class="main-container">
    <div class="plan-clase-line">
        <div
            class="default-line-first"
            [class.active-line]="
                (contenidosVistosPorcentaje >= 100 && contenidosVistosAnteriorPorcentaje >= 100) ||
                isFirstElementActive ||
                (contenidosVistosPorcentaje < 100 && contenidosVistosAnteriorPorcentaje >= 100)
            "
        ></div>
        <div class="default-circle" [class.active-circle]="contenidosVistosPorcentaje >= 100"></div>
        <div
            class="default-line-second"
            [class.active-line]="contenidosVistosPorcentaje >= 100"
            [class.is-last-line]="isLast"
        ></div>
    </div>
    <div
        class="bubble-box"
        [class.right-bubble]="position === 'right'"
        [class.left-bubble]="position === 'left'"
        [class.active-bubble]="contenidosVistosPorcentaje >= 100"
    >
        <div class="plan-container">
            <div class="left-content">
                <div class="info-container-icon">
                    <ogr-icon class="icono" [name]="'libro2-o'"></ogr-icon>
                </div>
                <div class="session-type">{{ "plan_clases.plan_clase_semanas_alumnos_new.sesion" | t }}</div>
            </div>
            <div class="plan-divider" [class.not-complete]="contenidosVistosPorcentaje < 100"></div>
            <div class="content">
                <ng-container *ngIf="aprendolibreCondition; else elseDescripcion">
                    <div class="title">{{ planPersonalSesion.plan_personal_sesion }}</div>
                </ng-container>
                <ng-template #elseDescripcion>
                    <div class="session-number">{{ nombreSesion }} {{ index + 1 }}</div>
                    <div class="title">
                        {{ planPersonalSesion.plan_personal_sesion }}
                    </div>
                </ng-template>
                <ng-container *ngIf="visible; else visiblePronto">
                    <p class="plan-clase-bar-label">{{ "plan_clases.plan_clase_semanas_alumnos_new.avance" | t }}</p>
                    <progressbar
                        class="plan-clase-bar"
                        [animate]="false"
                        [value]="contenidosVistosPorcentaje"
                        type="primary"
                    ></progressbar>
                    <div class="btn-container">
                        <button
                            class="btn-go-to"
                            *ngIf="!vistaPrevia"
                            [class.not-complete-button]="contenidosVistosPorcentaje === 0"
                            [routerLink]="[baseRoute, planPersonal.id, 'sesiones', planPersonalSesion.id]"
                            [queryParams]="{ grupo_usuario_id: grupoUsuarioId }"
                        >
                            {{ "plan_clases.plan_clase_semanas_alumnos_new.ver_clase" | t }}
                        </button>
                        <button
                            class="btn-go-to"
                            *ngIf="vistaPrevia"
                            [class.not-complete-button]="contenidosVistosPorcentaje === 0"
                            [routerLink]="[
                                '/vista_previa_plan_clases_alumno',
                                planPersonal.id,
                                'sesiones',
                                planPersonalSesion.id
                            ]"
                            [queryParams]="{ grupo_usuario_id: grupoUsuarioId }"
                        >
                            {{ "plan_clases.plan_clase_semanas_alumnos_new.ver_clase" | t }}
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <ng-template #visiblePronto>
        <div class="title" *ngIf="planPersonalSesion.fecha_inicial === null">
            {{ "plan_clases.plan_clase_semanas_alumnos_new.no_disponible" | t }}
        </div>
        <div class="available-soon" *ngIf="planPersonalSesion.fecha_inicial !== null">
            <fa name="clock-o" class="icono-clock"></fa>
            <div>
                <h4 class="available-soon-title">
                    {{ "plan_clases.plan_clase_semanas_alumnos_new.estara_disponible" | t }}
                </h4>
                <p class="available-soon-date">
                    {{ planPersonalSesion.fecha_inicial | date: "dd-MM-yyyy h:mm a" }}
                </p>
            </div>
        </div>
    </ng-template>
</div>
