import { PlanClasesDeAlumnosComponent, PlanClaseAlumnoComponent, ClasePlanClaseComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"

import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

export const routes: Routes = [
    {
        path: "planes_estudio",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanClasesDeAlumnosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "asignatura/:asignatura_id/planes_estudio",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanClasesDeAlumnosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "plan_estudios/:id/sesiones/:sesion_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ClasePlanClaseComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "plan_estudios/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanClaseAlumnoComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
