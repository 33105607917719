export const pt = {
    notificacion_tipos: {
        Pruebas: "Testes",
        Dudas: "Dúvidas",
        "Respuestas Correctas Dudas": "Respostas corretas dúvidas",
        "Respuestas Dudas": "Dúvidas de respostas",
        Retroalimentaciones: "Feedbacks"
    },
    file: {
        cargar_archivo: "Carregar arquivo",
        cargar_archivo_mobile: "Tirar foto"
    },
    puntaje_core: {
        carreras: {
            buscador_carreras: {
                titulo: "Pesquisar Cursos de Formação"
            },
            carrera_sede: {
                titulo: "Carreira"
            }
        },
        estadisticas: {
            evaluacion_instancia_estadisticas: {
                titulo: "Estatísticas"
            }
        },
        revisar_ensayos: {
            titulo: "Revisar avaliações criadas"
        },
        evaluaciones_corregir: {
            titulo_alumnos: "Corrigir Avaliação",
            titulo: "Cargar Resultados"
        },
        estadisticas_evaluacion_recomendada: {
            titulo: "Estadísticas Evaluacion Sugerida #%{id}"
        },
        evaluacion: {
            titulo: "Avaliação: %{nombreEvaluacion}"
        },
        plan_clases: {
            titulo: "Planos de aula",
            tab_planes_alumnos: "Planos de aula do aluno",
            tab_mis_planes: "Meus planos de aula",
            planes_compartidos: "Planos de aula compartilhados por mim",
            planes_recomendados: "Planos do aula recomendados"
        },
        completar_datos_core: {
            titulo: "Complete seus dados"
        },
        actualizar_datos_core: {
            titulo: "Atualizar dados"
        },
        home_ayudas: {
            label_preguntas_frecuentes: "Perguntas Frequentes",
            text_preguntas_frecuentes:
                "Se tiver dúvidas sobre as principais funcionalidades da plataforma, recomendamos que entre aqui.",
            label_tutoriales: "Tutoriais",
            text_tutoriales: "Se precisar de ajuda personalizada, entre em contato com seu monitor."
        }
    },
    puntaje_modules: {
        completar_datos: {
            omitir: "Omitir",
            guardar: "Salvar"
        },
        actualizar_datos: {
            omitir: "Omitir",
            guardar: "Salvar"
        }
    },
    corregir_ensayos_tabs_profesor: {
        partir_imagen: "A partir de uma imagem/foto",
        partir_imagenes: "A partir de imagems/fotos",
        partir_csv: "A partir de arquivo CSV",
        partir_camara: "A partir da câmera"
    },
    subir_respuestas_imagen: {
        titulo: "Siga os passos seguintes",
        titulo_step_1: "Anexe uma foto do gabarito",
        desc_step_1_1: "Tire uma foto da",
        desc_step_1_2: "folha de respostas (gabarito)",
        desc_step_1_3:
            "Certifique-se de que a folha esteja reta e que a iluminação seja adequada para um bom contraste entre o fundo branco e as respostas",
        desc_step_1_4: "Pode encontrar uma folha de respostas",
        desc_step_1_5: "aqui",
        desc_step_1_6: "Para usá-lo, não é necessário preencher seus dados",
        siguiente: "Seguinte",
        step_1_error: "O arquivo não corresponde a uma imagem válida",
        step_1_error_desc_1: "Verifique se o arquivo correto foi anexado",
        step_1_error_desc_2: "Se for uma fotografia, tente endireitar a folha e melhorar a iluminação e o contraste",
        subir_foto: "Carregar Foto",
        titulo_step_2: "Verifique os resultados",
        desc_step_2:
            "Verifique se os resultados da imagem foram lidos corretamente. Você pode corrigir marcando a alternativa correspondente respondida em caso de erro de leitura, clicando nas seções azuis da imagem",
        hoja_respuesta: "Folha de resposta",
        resultados: "Resultados",
        titulo_step_3: "Carregue os resultados",
        desc_step_3: "Conclua a operação clicando no botão “carregar resultados”",
        step_3_error: "A operação foi concluída com avisos. A imagem não pôde ser carregada devido a um ou mais erros",
        subir_resultados: "Carregar Resultados"
    },
    subir_respuestas_imagen_multiple: {
        titulo: "Siga os seguintes passos",
        titulo_step_1: "Arraste as imagens:",
        desc_step_1:
            "Tirar uma foto de cada folha de respostas. Verifique se as folhas estão correta e a iluminação adequada para um boa definição com o fundo branco da folha de resposta",
        file_box: "Arraste as imagens aqui",
        opcion_contraste: "Aplicar correção de contraste (para imagens com pouca definição / muito claras)",
        progreso: "%{n} archivos procesados",
        titulo_malos: "Archivos con problemas",
        archivos_no_imagenes: "Archivos que no son imágenes",
        imagenes_malas: "Imágenes con problemas",
        titulo_step_2: "Comprueba los resultados",
        desc_step_2:
            "Revisa que los resultados se hayan leído correctamente a partir de la imagen. Puedes corregir marcando la alternativa contestada que corresponda en caso de un error de lectura, haciendo click en las secciones azules de la imagen.",
        siguiente: "Siguiente",
        forma_global: "Forma Global",
        tooltip_forma_global:
            "Forma utilizada por todas las imágenes. Si su valor esta especificado, las formas de las imágenes se ignorarán.",
        selecciona_imagen: "Selecciona una imagen",
        hoja_respuesta: "Hoja de respuesta",
        resultados: "Resultados",
        forma: "Forma",
        editar: "Editar",
        listo: "Listo",
        tipo_forma: "Tipo de forma",
        titulo_step_3: "Sube los resultados",
        desc_step_3: "Completa la operación haciendo click en el botón subir resultados.",
        warning_no_completado:
            "La operación para la imagen <b>%{fileName}</b> no pudo completarse por uno o más errores:",
        ver_resultados: "Ver resultados",
        aviso_completado: "La operación para la imagen <b>%{fileName}</b> se completó exitosamente.&nbsp;",
        subir_resultados: "Subir Resultados",
        nueva_carga: "Nueva carga",
        modal_instructivo: {
            link: "Instrutivo e melhores práticas",
            no_mostrar: "Não mostrar esta mensagem novamente",
            titulo: "Melhores informações para correção automáticacom a folha de respostas impressa",
            desc: "Para evitar algum erro, na hora de escanear as folhas de respostas dos seus testes, segue asseguintes recomendações:",
            instr1: "As folhas de respostas se devem ser <b>impressas para melhor qualidade</b>. <b>Não é recomendável xerocá-las.</b>",
            instr2: "Prestar atenção se utilizou folhas <b>tamanho carta</b> versão 2022 da plataforma e com boa qualidade de impressão.",
            instr3: "Certificar se de que, estejam completos todos os campos obrigatórios <b>(CPF, Formato)</b> e inteiramente preenchidas os círculos das respostas dos estudantes.",
            instr4: "Limpe a <b>lente da câmera</b> e certifique-se de ter a <b>iluminaçãoa dequada</b> para tirar as fotografias. O formato do arquivo deve ser JPEG ou PNG.",
            instr5: "Faça uma revisão para que os <b>códigos QR</b> das folhas não estejam <b>rasurados, amassados ou riscados.</b>",
            instr6: "Verifique se os círculos das respostas estejam preenchidos corretamentre, assim como os dados solicitados.",
            instr7: "Ao escanear a folha, procure posicionar nas margens especificadas no dispositivo, evitando espaços restantes que não correspondem ao formato da folha.",
            instr8: "Quando você chegar no passo 2, poderá <b>editar o número da avaliação, CPF e alternativas</b> marcadas dos seus estudantes.",
            advertencia: "Folhas de respostas que não serão aceitas pela plataforma:",
            adv1: "Impressão pouco nítidas",
            adv2: "Folhas rasuradas",
            adv3: "Folhas rasgadas ou enrugadas",
            adv4: "Código QR danificados",
            adv5: "Arquivos carregados formato PDF",
            aceptar: "Aceitar"
        }
    },
    subir_respuestas_csv: {
        titulo: "Siga os seguintes passos:",
        titulo_step_1: "Carregar um arquivo CSV",
        desc_step_1_1: "Carregar um arquivo tipo CSV com as informações que deseja enviar.",
        desc_step_1_2:
            'Pode obter o formato <a href="%{urlFormato}">aquí</a>. Se tua prova tem formato A/B pode obter o formato <a href="%{urlFormatoAB}">aqui</a>.',
        titulo_step_2: "Editar arquivo",
        desc_step_2: "Ao abrir o arquivo clique em colunas separadas por virgúla e utilize o arquivo normalmente",
        subir_archivo: "Subir arquivo"
    },
    plan_clase_sesion_form: {
        agregar_material: {
            titulo: "Adicionar material",
            tab_biblioteca: "Da biblioteca",
            tab_mi_material: "Do meu material",
            tab_subir: "Carregar materiais",
            tab_agregar_evaluacion: "Adicionar avaliação",
            tab_agregar_prueba_propia: "Adicionar próprio teste"
        }
    },
    subir_material_profesor: {
        titulo: "Carregar materiais",
        error_nombre: "Você deve escolher um nome para o material",
        error_video_url: "Você deve colocar a URL do vídeo",
        error_video_url_youtube_valida: "Você deve colocar um URL válido de um vídeo do YouTube",
        error_pdf: "O arquivo não é um PDF",
        error_pdf_archivo: "Você deve selecionar um arquivo PDF",
        error_ppt: "O arquivo não é um PPT",
        error_ppt_archivo: "Você deve selecionar um arquivo PPT",
        error_word: "O arquivo não é um Word",
        error_word_archivo: "Você deve selecionar um arquivo Word",
        error_excel: "O arquivo não é uma planilha do Excel",
        error_excel_archivo: "Você deve selecionar um arquivo Excel",
        error_mp4: "O arquivo não é um MP4",
        error_mp4_archivo: "Você deve selecionar um arquivo MP4",
        error_link: "Você deve colocar a URL do link",
        tab_subir_pdf: "Carregar arquivo PDF",
        subir_pdf_nombre: "Nome do material",
        subir_pdf_archivo: "Arquivo para carregar",
        tab_subir_ppt: "Carregar PPT",
        subir_ppt_nombre: "Nome do material",
        subir_ppt_archivo: "Arquivo para carregar",
        tab_subir_word: "Carregar Word",
        subir_word_nombre: "Nome do material",
        subir_word_archivo: "Arquivo para carregar",
        tab_subir_excel: "Carregar Excel",
        subir_excel_nombre: "Nome do material",
        subir_excel_archivo: "Arquivo para carregar",
        tab_agregar_video: "Adicionar um vídeo",
        agregar_video_nombre: "Nome do material",
        agregar_video_url: "Link de vídeo",
        tab_subir_video: "Carregar um vídeo",
        subir_video_nombre: "Nome do material",
        subir_video_archivo: "Vídeo a subir",
        tab_cargar_link: "Carregar um link",
        cargar_link_nombre: "Nome do material",
        cargar_link_url: "Link externo",
        con_clasificacion: "Com classificação",
        sin_clasificacion: "Sem classificação",
        pdf_advertencia: "O arquivo deve estar em formato PDF e não deve exceder 35 MB",
        ppt_advertencia: "O arquivo deve estar em formato PPT (ppt, pptx) e não deve exceder 35 MB",
        word_advertencia: "O arquivo deve estar em formato Word (doc, docx) e não deve exceder 35 MB",
        excel_advertencia: "O arquivo deve estar em formato Excel (xls, xlsx) e não deve exceder 35 MB",
        mp4_advertencia: "O arquivo deve estar em formato MP4 e não deve exceder 35 MB",
        boton_subir_pdf: "Carregar PDF",
        boton_subir_ppt: "Carregar PPT",
        boton_subir_word: "Carregar Word",
        boton_subir_excel: "Carregar Excel",
        boton_subir_video_url: "Agregar video",
        boton_subir_mp4: "Carregar video",
        boton_cargar_link: "Carregar link"
    },
    request_reset_password: {
        titulo: "Atualização de senha",
        parrafo1: "Sua senha foi atualizada recentemente.",
        parrafo2: "Antes de continuar, pedimos para trocar sua senha por uma mais segura.",
        label_password_actual: "Senha atual",
        error_vacia_password_actual: "A senha atual não pode estar vazia.",
        error_invalida_password_actual: "A senha atual é inválida.",
        label_password_nueva: "Nova Senha",
        error_tamano_password_nueva: "A senha deve ter no mínimo %{passwordMinLength} caracteres.",
        error_vulnerable_password_nueva: "Esta senha é vulnerável. Tente colocar outro.",
        label_password_confirmar: "Confirmar nova senha",
        error_match_password_confirmar: "A confirmação da senha não corresponde à senha.",
        save_success: "Senha atualizada com sucesso.<br />Em breve iremos redirecioná-lo para o site.",
        btn_save: "Alterar senha"
    },
    datos_generales_evaluacion: {
        titulo: "Dados gerais da avaliação",
        titulo_card: "Avaliação #%{evaluacionId}",
        nombre: "Nome",
        creacion: "Criação",
        inicio: "Início",
        cierre: "Fechamento",
        resultados: "Resultado",
        presencial: "Presencial",
        si: "Sim",
        no: "Não",
        compartido_con: "Compartihado com:",
        fecha: "Data",
        acciones: "Ações",
        editar_button: "Editar",
        tipo: "Tipo de avaliação",
        sumativa: "Sumativo",
        formativa: "Formativo"
    },
    resultados_evaluacion: {
        historial_label: "Registro",
        historial_text: "Encontre aqui a lista de todas as avaliações online, impressas e não configuradas",
        historial_link_text: "Ver Registros",
        estadisticas_label: "Estatísticas",
        estadisticas_text: "Encontre aqui as estatísticas geradas a partir dos resultados obtidos nas avaliações.",
        estadisticas_link_text: "Ver estatísticas",
        progreso_label: "Progresso dos meus alunos",
        progreso_text: "Observar a evolução ao longo do tempo dos resultados obtidos pelos alunos.",
        progreso_link_text: "Ver progresso",
        informes_label: "Relatório do Aluno",
        informes_text:
            "Aqui pode consultar e descarregar os Relatórios de Utilização e Desempenho na plataforma de cada um dos seus alunos.",
        informes_link_text: "Exibir relatório"
    },
    dudas: {
        titulo: "Comunidade",
        titulo_crear: "Criar tema",
        titulo_editar: "Editar tema",
        comentario: {
            editar: "Editar",
            editado: "editado",
            comentario_eliminado: "A mensagem foi removida.",
            deshabilitar: "Desabilitar"
        },
        comentarios_list: {
            mostrar_todos: "Mostre tudo",
            ocultar: "Esconder"
        },
        duda: {
            por: "Por",
            eliminada: "A dúvida foi removida.",
            editar: "Editar",
            compartir: "Compartilhar",
            responder: "Responder",
            ban: "Desabilitar"
        },
        duda_avatar: {
            dudas: "Dúvidas",
            respuestas_correctas: "Respostas corretas",
            comentarios: "Comentários",
            lirgos: "Conquistas",
            inicio: "Começo",
            termino: "Concluído",
            deshabilitar: "Desabilitar"
        },
        duda_form: {
            seleccione: "-- Selecionar turma --"
        },
        duda_item: {
            expandir: "Expandir",
            contraer: "reduzir"
        },
        duda_respuesta_new: {
            cancelar: "Cancelar",
            contestar: "Responder",
            guardar: "Guardar mudanças"
        },
        duda_respuesta: {
            por: "Por",
            eliminada: "A resposta foi removida.",
            aceptar: "Aceitar como resposta",
            aceptada: "Resposta aceita",
            deshabilitar: "Desabilitar",
            editar: "Editar",
            comentar: "Comentar"
        },
        duda_respuestas: {
            titulo: "Respostas",
            no_hay_respuestas: "Não foram encontradas respostas para este tópico. Seja o primeiro a responder!"
        }
    },
    entrenador: {
        entrenador_showcasebox: {
            titulo: "Treinador virtual",
            no_config: "Você não tem seu treinador virtual configurado. <br /> Configure-o ",
            link_config: "aqui",
            btn_config: "Configuração",
            preguntas_contestadas: "Perguntas respondidas<br />a semana passada: ",
            comenzar: "O que você está esperando para começar?",
            sigue_asi: "Continue assim!",
            ya_casi: "Você está quase pronto. Você pode!",
            buen_trabajo: "Bom trabalho!"
        },
        entrenador_usuarios_edit: {
            descripcion:
                "Quantas perguntas você se propõe a responder por semana? Selecione uma meta e fique motivado monitorando seu progresso na página inicial.",
            descripcion_dos:
                "Você também pode alterar sua meta para adaptá-la a feriados ou períodos de estudo mais intensos",
            semanal: "Objectivo semanal",
            meta_actual: "Sua meta atual para perguntas por semana é",
            guardar: "Salvar",
            entrenador_virtual: "O seu treinador virtual",
            selecciona_entreador: "Selecione um treinador",
            cancelar: "Cancelar"
        }
    },
    /*   estadisticass: {
        ensayos_recientes_showcasebox: {
            titulo: "Ensaios recentes",
            ver: "Assistir",
            ver_todos: "ver tudo",
            none: "Sem testes recentes"
        },
        estadistica_evaluacion_alumno: {},
        estadistica_usuarios_preguntas_flex: {},
        estadisticas_multiples_curso: {},
        estadisticas_multiples_cursos: {},
        estadisticas_multiples_evaluacion: {},
        estadisticas_multiples_evaluacion_alumno: {},
        estadisticas_multiples_portada: {},
        estadisticas_multiples_profesor: {},
        estadisticas_portada: {},
        filtro_estadistica_multiples: {},
        grafico_mi_progreso_showcasebox: {
            titulo: "Resumo do seu progresso"
        },
        grafico_comparacion_puntaje_grupos: {},
        grafico_desempeno_clasificacion_compuesto: {},
        grafico_desempeno_promedio: {
            titulo: "Desempenho médio por tipo de resposta para %{asignatura}",
            descripcion:
                "Número de respostas dos alunos classificadas por tipo de resultado: correto, incorreto e omitido.",
            nombre: "Desempenho médio %{asignatura}",
            correctas: "Corretas",
            incorrectas: "Incorretas",
            omitidas: "Omitido"
        },
        grafico_distribucion_tramo_puntaje: {
            titulo: "Distribuição dos resultados por faixa de ",
            descripcion:
                "Número de avaliações realizadas, ordenadas por faixas de %{escala} de acordo com os resultados obtidos em cada uma delas.",
            eje_x: "Intervalos de %{escala}",
            eje_y: "Número de avaliações",
            leyenda: "Número de avaliações por segmento"
        },
        graficos_clasificacion_tipo_container: {},
        graficos_tipo_clasificacion: {},
        informe_anual: {},
        numero_estudiantes_activos: {},
        resumen_estadisticas_showcasebox: {
            resumen: "Resumo estatístico",
            reciente: "Mais recente",
            tendencia: "Tendência",
            promedio: "Média",
            maximo: "Máximo"
        },
        tabla_desempeno_estudiantes: {},
        tabla_desempeno_por_usuario_clasificacion: {},
        tabla_numero_estudiantes_por_curso: {},
        tabla_numero_evaluaciones_realizadas: {},
        tabla_promedio_general_y_asignaturas: {},
        tabla_resumen_evaluacion_instancia: {
            estudiante: "Aluno",
            resultado: "Resultados",
            puntaje: "Pontuação",
            fecha: "Data",
            correctas: "Correto",
            incorrectas: "Incorreta",
            omitidas: "Omitido",
            intento: "Tentou",
            intentos: "Tentativas"
        },
        top_alumnos_anual: {},
        top_docentes_anual: {},
        avances_asignaturas: {},
        barra_avance_asignatura: {},
        barra_distribucion_asignatura: {},
        desempeno_asignaturas: {},
        estadisticas_cb_showcasebox: {},
        estadisticas_curso: {},
        estadisticas_cursos: {},
        estadisticas_declarados: {},
        estadisticas_evaluacion_portada: {},
        estadisticas_evaluacion: {},
        estadisticas_profesor: {},
        estadisticas_showcasebox: {},
        estadisticas_simple: {
            eje_y: "Número de avaliações",
            leyenda: "Número de avaliações por segmento"
        },
        estadisticas_usuario_portada: {},
        estadisticas_usuarios: {},
        estadisticas: {},
        filtro_estadisticas_declarados: {},
        filtro_estadisticas: {},
        grafico_mi_progreso_cb: {},
        grafico_mi_progreso: {
            titulo: "evolutiva",
            descripcion: "Aqui você pode ver a evolução de suas",
            progreso_por: "Progresso por",
            desenpenio_para: "Desempenho para"
        },
        informacion_general_grupos: {},
        informacion_general_usuario: {},
        listado_preguntas_mas_incorrectas: {},
        pregunta_informe_estadistica: {},
        resumen_estadisticas_generales: {},
        resumen_utp_anual: {},
        tabla_kpi: {
            n_ensayos: "Nº Ensaio",
            ultimo_ptje: "Última Ptje.",
            correctas: "Corretas",
            incorrectas: "Incorretas",
            omitidas: "Omitidas",
            maxima: "classificação Máxima",
            promedio: " classificação média"
        },
        tabla_mi_progreso: {},
        tabla_resultados_por_pregunta: {},
        tabla_resumen_evaluacion_por_alumno: {}
    }, */ //hay que hacer mach con los cambios de ricardo
    plan_clases: {
        agregar_evaluacion_plan_clase: {},
        agregar_prueba_propia_plan_clase: {},
        barra_avance_plan_clase: {},
        card_material_alumno: {},
        card_material_profesor: {},
        carousel_semanal: {},
        carousel_semanal_alumno: {},
        clase_plan_clase: {},
        clase_showcase: {},
        clase_showcase_profesor: {},
        clases_home_alumno: {},
        cursos_plan_clase: {},
        datos_generales_plan_clase: {},
        plan_clase: {},
        plan_clase_alumno: {},
        plan_clase_box: {
            button_ver: "Ver",
            button_editar: "Editar"
        },
        plan_clase_contenido_box: {},
        plan_clase_edit: {},
        plan_clase_form: {},
        plan_clase_modelo: {},
        plan_clase_modelos: {},
        plan_clase_new: {},
        plan_clase_semanas: {},
        plan_clase_semanas_alumnos: {
            vamos_aqui: "¡Vamos aquí!",
            ver_clase: "Ver Aulas"
        },
        plan_clase_sesion_avance_por_alumno: {},
        plan_clase_sesion_detalle_por_contenido: {},
        plan_clase_sesion_form: {},
        plan_clases_alumnos: {},
        plan_clases_profesor: {},
        replicar_plan: {},
        respuestas_actividad: {},
        resultados_actividad: {},
        plan_clase_semanas_alumnos_new: {
            ver_clase: "Ver Aulas",
            estara_disponible: "Esta sessão será visível a partir de:",
            no_disponible: " Esta sessão não está disponível",
            avance: "Avançar",
            sesion: "Sessão de estudo"
        }
    },
    plan_personales: {
        diagnostico: {
            generando: "Estamos criando uma avaliação de conteúdo.\n Isso pode levar alguns minutos"
        }
    },
    universidad: {
        buscador_universidades: {
            titulo: "Pesquisar universidade",
            universidad: "Universidade",
            buscador_placeholder: "Universidade...",
            buscar: "Pesquisar",
            not_found: ""
        },
        universidad: {
            presentacion: "Apresentação:",
            universidad: "Universidade",
            universidades: "Universidades",
            les_gusta: " usuários gostam",
            sede: "Campus",
            sedes: "Campus",
            carrera: "Carreira",
            carreras: "Carreiras",
            ver: "Ver",
            seguir: "Seguir",
            siguiendo: "Seguindo",
            carreras_not_found: "Nenhuma carreira foi encontrada com esses critérios de pesquisa."
        },
        universidad_recomendada: { titulo: "Universidade do mês" }
    },
    materiales: {
        titulo: "Material:",
        curso_select: {
            seleccione: "Selecione"
        },
        filtros_clasificaciones_evaluacion_tipo: {
            seleccione: "-- Selecione um/a",
            mas: "+ Mais ",
            menos: "- Menos "
        }
    },
    usuarios: {
        preferencia_carreras: {
            mis_preferencia_carrera: {
                title: "Caursos de preferência",
                agregar: "Adicionar",
                guardar: "Salvar"
            }
        }
    },
    ////////////////////////////////
    alternativas: {
        max: "O número de alternativas não pode ser maior que 5 ",
        min: "O número de alternativas não pode ser menor ou igual a 1 "
    },
    ayuda: {
        descargar: "Baixar",
        fecha_subida: "Data de carregamento",
        ver: "Ver",
        visualizar: "Visualizar"
    },
    ayudas: {
        ayuda: {
            descargar: "Baixar",
            imprimir: "Imprimir",
            ver_youtube: "Ver no Youtube",
            volver: "Voltar",
            titulo: "Ajuda"
        },
        buscar: "Pesquisar...",
        no_disponible: "Ajuda não disponível.",
        titulo_alumnos: "Material de ajuda para alunos",
        titulo_profesores: "Material de ajuda para professores",
        home: {
            contacto: {
                enviado: "Su información foi enviada com sucesso. Entraremos em contato com você em breve.",
                contactanos: "Contate-nos",
                establecimiento: "Estabelecimento",
                placeholder_establecimiento: "Ej: nome da escola",
                telefono: "Telefone",
                telefono_requerido: "Telefone é obrigatório (e deve ser válido).",
                mensaje: "Mensagem",
                motivo: "Motivo do contato",
                motivo_requerido: "Motivo do contato é obrigatório.",
                enviar_mensaje: "Enviar mensagem"
            }
        }
    },
    base_topmenu: {
        alumno: {
            blog: "Blog",
            practica: "Prática",
            resultados: "Resultados",
            plan_de_clase: "Planos de aula",
            dudas_curso: "Dúvidas da turma",
            comunidad: "Comunidade",
            ejercitar: "Gerador de teste",
            practicar: "Prática",
            planes_adaptativos: "Plano de Estudo Adaptado",
            centro_ayuda: "Centro de ajuda",
            carreras: "Cursos de Formação",
            planes_de_clase: "Planos de estudo",
            planes_estudio: "Planos de estudo",
            biblioteca: "Biblioteca"
        },
        profe: {
            evaluar: "Avaliar",
            corregir: "Corrigir",
            resultados: "Resultados",
            planes_de_clase: "Planos de aula",
            mis_noticias: "Minhas notícias",
            comunidad: "Comunidade",
            planes_clase_predefinido: "Planos de aula predefinidos",
            centro_ayuda: "Centro de ajuda",
            resumen_uso: "Resumo de uso",
            blog: "Blog"
        }
    },
    biblioteca: {
        abrir_boton: "Abrir",
        descargar: "Baixar",
        detalles_boton: "Detalhes",
        mostrando_resultados: "Mostrando os resultados para",
        not_found: "O material não foi encontrado",
        prev_boton: "Pré-visualização",
        resultados_warn: "Não foram encontrados resultados para",
        search_placeholder: "Pesquisar...",
        subtitulo: "Material por",
        subtitulo_clasificacion: "Material do %{clasificacion}",
        titulo: "Biblioteca",
        vista_previa: "Pré-visualização do material"
    },
    clase_plan_clase: {
        volver: "Voltar",
        ver_libro: "ver livro digital",
        anterior: "Anterior",
        volver_plan: "Voltar ao plano",
        siguiente: "Seguinte",
        enviar: "Enviar",
        volver_a_realizar: "Refazer"
    },
    compartir_inst: {
        cancelar: "Cancelar",
        cerrar: "Fechar",
        comp_cursos_1: "Foram compartilhados os seguintes",
        comp_cursos_2: "Avaliações com IDs",
        comp_cursos_3: "para os seguintes cursos",
        compartir: "Compartilhar %{evaluacionTipo}",
        compartir_button: "Compartilhar",
        con_forma: "Com forma/fila aqui",
        copiar: "Cópia",
        crear: "Criar nova prova",
        crear_escala: "Criar uma escala",
        desde: "A partir do dia",
        desde_ver_warn: "deve ter uma data de publicação dos resultados",
        desde_ver: "Os estudantes poderão ver as respostas desta prova a partir do dia",
        desde_warn: "deve ter uma data de início",
        editar: "Editar",
        error_nombre: "deve ter um nome",
        exito: "com sucesso",
        exp_fecha: "Os estudantes poderão fazer desta prova",
        fechas_warn: "A data de início deve ser antes da data final",
        formativo: "Formativo",
        grupos_ver: "Turmas ao qual compartilhar esta prova",
        grupos_ver_warn: "Deve seleccionar pelo menos um",
        hasta: "Até o dia",
        hasta_warn: "deve ter uma data final",
        marque1: "Marque a seguinte opção se",
        marque2: "possui formas",
        nombre: "Nome",
        see_list: "Ver a lista de",
        see_list_here: "aqui",
        sel_escala: "Seleccionar o tipo de escala",
        sel_eval_masiva: "Marque esta opção para avaliações em massa",
        sel_eval_masiva_all: "Comartilhar com todos os estudantes no site",
        sel_tipo: "Selecionar o tipo de avaliação",
        sin_grupos1: "Não pode compartilhar uma avaliação: Não é proprietário",
        sin_grupos2: "activos",
        success_can1: "Os estudantes poderão",
        success_can2: "no link",
        success_can3: "nos link",
        success1: "Compartilhou a",
        success2: "aos seguintes cursos",
        sumativo: "Sumativo",
        ver_mas: "Ver mais",
        warn_pauta: "Atenção: Não será possível editar a diretriz depois desta ter sido compartilhada.",
        what: "O que é isto?",
        what_exp:
            "Esta opção irá gerar duas avaliações com as mesmas questões, mas em ordem diferente (Formulário/Linha A e B).",
        what_exp2: "Avaliação sumativa é a avaliação que é aplicada para obter uma nota e avaliar os alunos.",
        what_exp3:
            "A avaliação formativa é um processo em que a informação sobre a aprendizado dos estudantes é obtida e depois utilizada para modificar as atividades de ensino e aprendizagem.",
        what_exp4_1:
            "Opção para administradores. Os estudantes não receberão uma notificação para esta avaliação. Recomenda-se que não selecione",
        what_exp4_2: "da lista acima ao utilizar esta opção"
    },
    contestable_tipos: {
        titulo: {
            alternativas: "Perguntas de múltipla escolha",
            archivo: "Resposta com um arquivo",
            libre: "Resposta dissertativa",
            mixto: "Misto"
        },
        contenido: {
            alternativas: "O aluno responderá às perguntas, selecionando alternativas.",
            archivo: "O aluno carregará um pdf, documento Word ou imagem como resposta para o teste.",
            libre: "O estudante responderá ao teste inteiro dentro de uma caixa de texto.",
            mixto: "Inclui perguntas de todos os tipos acima mencionados. Deve ser marcado manualmente."
        }
    },
    duda: {
        comentario: "comentário",
        comentarios: "comentários",
        contestada: "Respondido",
        creado_por: "Criado por",
        editado: "editado",
        pendiente: "Pendente",
        sin_comentarios: "Sem comentários",
        titulo: "Título"
    },
    ensayos: {
        crear_duda: "Criar dúvida ",
        confirmacion: "Confirmação",
        confirmacion_pregunta: "Tem certeza de que deseja enviar uma pergunta?",
        a_quien: "Selecione para quem irá enviar a pergunta",
        comunidad_general: "Comunidade em geral",
        curso: "Minha turma",
        aceptar: "Aceitar",
        realizar: {
            no_disponible: "A avaliação estará disponível de %{fechaInicio} até %{fechaTermino}"
        }
    },
    ensayo_instancias: {
        titulo: "Avaliações realizadas",
        n_preguntas: "N perguntas",
        realizar: "Executar",
        de: "de",
        puntaje: "Pontuação"
    },
    /*   entrenador: {
        cancelar: "Cancelar",
        descripcion:
            "Quantas perguntas você se propõe a responder por semana? Selecione uma meta e fique motivado monitorando seu progresso na página inicial.",
        descripcion_dos:
            "Você também pode alterar sua meta para adaptá-la a feriados ou períodos de estudo mais intensos",
        entrenador_virtual: "O seu treinador virtual",
        guardar: "Salvar",
        meta_actual: "Sua meta atual para perguntas por semana é",
        selecciona_entreador: "Selecione um treinador",
        semanal: "Objectivo semanal"
    }, */
    errores: {
        codigo_error: "Erro de código:",
        contacto:
            "<p>Se você tiver mais problemas, entre em contato conosco por e-mail:</p><p><b>%{contact_mail}</b></p>",
        contacto_codigo:
            "Se você tiver mais problemas, entre em contato conosco em <b>%{contact_mail}</b> e envie-nos seu código de erro.",
        copiar: "Cópia de",
        error_400: "O servidor não conseguiu interpretar o pedido.",
        error_401: "Você precisa se autenticar para ter acesso a este recurso.",
        error_403: "Você não tem autorização para acessar este recurso.",
        error_404: "Recurso não encontrado.",
        error_404_front: "Parece que a página que procura não existe.",
        error_500: "O servidor encontrou problemas para acessar a este recurso.",
        error_500_pundit: "Você não tem autorização para acessar o recurso correspondente (Pundit).",
        error_503: "O servidor não está disponível no momento.",
        error_default: "Ocorreu um erro.",
        error_inesperado: "Ocorreu um erro inesperado, entre em contato conosco.",
        error_no_manejado: "Ocorreu um erro não tratado pelo aplicativo.",
        gracias: "Obrigado",
        status_front: "do aplicativo cliente",
        titulo_error: "Erro %{status}",
        ver_mas: "Ver mais",
        ver_menos: "Veja menos",
        volver_pagina_principal: "Voltar à página principal"
    },
    estadisticas: {
        cursos: {
            pre_title: "Resultados para",
            title: "Os meus cursos"
        },
        dg: {
            comp: "composição",
            instrumento: "Teste",
            n_preguntas: "Nº perguntas",
            piloto: "Perguntas em proceso piloto",
            piloto_exp: "Estas questões não são consideradas para o cálculo da nota",
            preg_abbr: "perg.",
            tiempo: "Tempo",
            tipo: "Tipo",
            title: "Dados gerais do Teste de Avaliação"
        },
        filtro: {
            filtrar_res: "Filtrar os resultados",
            not_found1: "Não possui",
            not_found2: "parceiros",
            sel: "Seleccione",
            sel_est: "Seleccione o estabelecimento",
            title: "Filtrar os dados"
        },
        graficos: {
            cpg: {
                alumno: "Nota média",
                descripcion_alumno: "Comparação da média de todos os testes realizados por aluno.",
                descripcion_profe: "Comparação da média de todos os testes realizados em cada um das turmas.",
                title: "Comparação das pontuações",
                title_alumno: "Comparação das pontuações entre estudantes para ",
                title_profe: "Comparação das pontuações entre turmas para "
            },
            dcc: {
                correctas: "Corretas",
                descripcion1:
                    "Distribuição de respostas corretas, incorretas e omitidas, dos conteúdos avaliados de acordo com o/a ",
                descripcion2: " ao qual correspondem.",
                desempeno: "Desempenho por ",
                incorrectas: "Incorretas",
                omitidas: "Omitido",
                para: "para"
            },
            dtp: {
                alumno: "Estudante",
                not_found: "Não há estudantes nesta parcela",
                resultado: "Resultado",
                title: "Alunos por parcela"
            },
            lpmi: {
                ocultar: "Esconder",
                resp_incorrectas: "de respostas incorretas",
                title: "Perguntas ordenadas por porcentagem de respostas incorretas",
                ver_mas: "Ver mais"
            },
            ree: {
                correctas: "Corretas",
                estudiantes: "Estudantes",
                incorrectas: "Incorretas",
                maximo: "Máximo",
                minimo: "Mínimo",
                omitidas: "Omitido",
                promedio: "média"
            },
            rpp: {
                alternativas: "Alternativas",
                asignatura: "Matéria",
                curso: "Turma",
                desc: "O quadro mostra a percentagem de estudantes que responderam a esta alternativa em relação ao número total de estudantes que responderam à avaliação",
                msg_decimales: "Os valores podem ser números decimais aproximados a números inteiros.",
                forma_a: "Forma A",
                forma_b: "Forma B",
                incorrectas: "Incorretas",
                legend: "A caixa verde corresponde à alternativa correta",
                omitidas: "Omitido",
                porcentaje: "Porcentagem",
                pregunta: "Pergunta",
                tiempo_prom: "Tempo médio",
                title: "Resultados por Pergunta",
                universo: "Universo"
            },
            tdpuc: {
                descripcion: "Porcentagem de respostas corretas por aluno em cada uma das classificações",
                estudiante: "Estuante",
                not_found: "Sem dados disponíveis",
                ocultar: "Ocultar",
                title1: "Tabela de desempenho por",
                title2: "por aluno da turma",
                ver_mas: "Ver mais",
                descargar_excel: "Baixar como Excel"
            },
            trepa: {
                acciones: "Ações",
                anterior: "Anterior",
                anterior_desc: "Esta coluna indica o resultado obtido na última avaliação do aluno",
                aprob: "Taxa de aprovação para 4,0",
                cal: "Nota",
                cancelar: "Cancelar",
                colegio: "Colégio/escola",
                comparacion: "Comparação",
                comparacion_desc:
                    "Esta coluna indica a diferença percentual do resultado do aluno com a média da sua turma nesta avaliação",
                confirmar: "Confirmar",
                correctas: "Corretas",
                curso: "Turma",
                descargar_excel: "Baixar como Excel",
                escala: "Informação da escala",
                escala_desc: "As notas para esta avaliação foram calculadas utilizando a seguinte escala",
                estudiante: "Estudante",
                evolucion: "Evolução",
                evolucion_desc: "Esta coluna indica a mudança percentual entre a avaliação passada e a actual.",
                fecha: "Data",
                forma: "Forma",
                id_eval: "ID Avaliação",
                incorrectas: "Incorretas",
                max: "Nota Máxima",
                min: "Nota Mínima",
                nombre: "Nome",
                nombre_eval: "Nome Avaliação",
                nota: "nota",
                oficial: "Oficial",
                omitidas: "Omitidas",
                puntaje: "pontuação",
                puntuacion: "pontos",
                realizado1: "Foram realizados",
                realizado2: "de",
                realizado3: "avaliações apresentadas",
                realizado4: "os estudantes fizeram a avaliação",
                reforzamiento: "Reforço",
                reiniciar: "Reiniciar",
                resultados: "Resultados",
                rut: "CPF",
                seleccionar_oficial: "Selecione tentativa oficial",
                seleccionar_oficial_desc: "Selecione tentativa oficial ",
                seleccionar_oficial_detalle:
                    "Se mostra a quantidade de vezes que o aluno respondeu a avaliação e seus resultados. A avaliação selecionada será apresentada como a entrega oficial ao estabelecimento",
                tiempo: "Tempo",
                title: "Resumo por Estudante",
                ver: "Ver",
                ver_reforzamiento: "Veja o reforço",
                ver_sesion: "Veja Sessão"
            },
            trepa_buscador: {
                con_respuestas: "Con respuestas",
                sin_respuestas: "Sin respuestas",
                todos: "Todos"
            }
        },
        info: {
            acciones: "Ações",
            cursos: "turmas",
            n_ensayos: "Nº de Ensaios",
            promedio: "pontuação média",
            title: "Informação geral"
        },
        profe: {
            imprimir: "Imprimir relatório geral"
        }
    },
    estudiar: {
        itemsmenu: {
            biblioteca: {
                title: "Biblioteca de materiais %{evaluacionTipo}",
                curricular: "Biblioteca de materiais curricular",
                prueba_transicion: "Biblioteca de materiais do ENEM",
                paes: "Biblioteca de materiais do PAES"
            },
            plan_estudios: "Planos de estudo",
            plan_personales: "Plano de Estudo Adaptado",
            streaming: {
                title: "Aulas ao vivo: %{evaluacionTipo}",
                curricular: "Aulas ao vivo: Curricular",
                prueba_transicion: "Aulas virtuais Enem",
                paes: "Aulas ao vivo: PAES"
            }
        },
        materiales: {
            descargar: "Baixar",
            favorito: "Favorito",
            favoritos: "Adicionar aos favoritos",
            fullscreen: "Ver em tela inteira",
            imprimir: "Imprimir",
            material: "Material",
            volver: "Voltar",
            youtube: "Assistir no YouTube"
        },
        plan_de_clases: {
            aceptar: "Aceitar",
            actividad: "Atividade",
            agregar: "Adicionar",
            asignatura: "Assunto",
            avance: "Progresso",
            avance_est: "Progresso estimado",
            cancelar: "Cancelar",
            comenzar_modelo: "A partir de um modelo",
            compartido1: "O plano de aula foi compartilhado",
            compartido2: "aos seguintes",
            compartir: "Compartilhar",
            compartir_plan: "Compartilhar Plano de aula",
            confirm_message: "Tem a certeza de que quer apagar o Plano de aula?",
            confirm_message2:
                "Por favor escreva APAGAR no seguinte campo de texto para confirmar a sua decisão. Lembre-se de que esta ação é IRREVERSÍVEL",
            confirm_elimnar_text: "APAGAR",
            create: "Criar Plano de aula",
            cursos_comp: "Aulas compartilhadas",
            decir_vamos_aqui: "Diga à minha turma que vamos aqui",
            editar_comp: "Editar aula compartilhada",
            eliminar_plan: "Apagar plano de aula",
            enviar_retroalimentacion: "Enviar feedback",
            estudiantes: "estudantes",
            form_eliminar: "Apagar",
            form_error: "O plano de aula não pôde ser apagado, por favor tente novamente mais tarde.",
            form_exito: "Sucesso",
            form_exito_mensaje: "O plano de aula foi removido com sucesso.",
            listo: "pronto",
            marcada_vista: 'marcado como "visto"',
            marcar_vista1: "Marcar",
            marcar_vista2: "como visto",
            material_arrastre: "Arraste os materiais para ordená-los de uma sessão para outra",
            material_clases: "Material de aula",
            no_vamos_aqui: "Não vamos aqui",
            not_comp: "Este plano não foi compartilhado",
            not_found: "*Não foram encontrados planos.",
            not_found_grupos: "Não pode compartilhar um plano de aula: Não possui",
            plan_aplicado1: "O planejamento foi implementado",
            plan_aplicado2: "aos seguintes",
            promedio_logro: "Percentagem de sucesso médio",
            promedio_tiempo: "Percentagem de tempo médio",
            redirigido1: "Será redirecionado em poucos segundos para a lista de planejamento ou pode ir clicando em",
            redirigido2: "AQUI",
            redirigido3: "Será redireccionado dentro de alguns segundos para a lista de planos de aula.",
            replicar: "Replicar",
            replicar_context: "Replicar o Plano a outra turma",
            replicar_success: "Plano replicado com sucesso",
            replicar_title: "Replicar Plano",
            required_tipo: "a seção Tipo / nível é obrigatória",
            retroalimentacion: "feedback",
            sel_asignatura: "Selecionar assunto",
            sel_curso: "Selecionar uma turma",
            sel_curso_title: "Selecionar turma",
            sel_ano: "Selecionar ano",
            seleccionar_aplicar: "Selecionar e aplicar",
            seleccione_modelo:
                "Selecione um dos modelos propostos a partir do qual deseja começar a criar o seu plano de aula",
            select_modelo: 'Iniciar "plano" a partir deste modelo',
            title: "Compartilhar plano de aula",
            title_grupos: "a quem compartilhar o plano de aula:",
            vamos_aqui: "Vamos aqui",
            ver_actividad: "Ver atividade",
            ver_clase: "Ver aula",
            ver_respuestas: "Ver respostas",
            visible_desde: "Visível a partir de"
        },
        plan_estudios: {
            asignaturas: "Disciplinas",
            compartidos: {
                titulo: "Planos de estudo compartilhados por meu professor",
                titulo_simple: "Planos de estudo"
            },
            editar: "Editar",
            evaluacion: "Avaliação",
            msj_no_planes: "Nenhum plano encontrado.",
            recomendados: {
                titulo: "Planos de estudo recomendados"
            },
            titulo: "Planos de estudo",
            ver: "Ver"
        },
        plan_personales: {
            btn_comenzar: "Começar plano pessoal",
            plan_personal: {
                avance: "Avançar",
                comenzar: "Começar",
                continuar: "Continuar",
                continuar_estudiando: "Continue estudando",
                evaluacion_diagnostico: "Avaliação diagnóstica",
                mensaje_continuar: "Conclua as sessões de estudo antes de diagnosticar novamente.",
                pendiente: "Pendente",
                repasar: "Reveja",
                repaso_sesion: "Recomendamos que você analise:",
                resultados: "Resultados",
                subtitulo: "Progresso do seu plano pessoal",
                subtitulo_sesion: "Sessão de estudo",
                titulo_sesion: "Sessão",
                clase_actual: "Clase Actual"
            },
            plan_personal_evaluacion: {
                desempeno: "Sua porcentagem de desempenho foi %{evaluacionPorcentaje}%",
                soluciones: "Soluções",
                volver_a_realizar: "Refazer"
            },
            plan_personal_sesion: {
                anterior: "Anterior",
                siguiente: "Próxima",
                siguiente_sesion: "Próxima sessão",
                subtitulo: "Sessão",
                titulo: "Plano pessoal",
                titulo_evaluacion: "Avaliação",
                volver_planes_personales: "Volte aos planos pessoais",
                pronto: "Pronto",
                no_disponible: "Esta sección aún no se encuentra disponible",
                volver_plan: "Voltar para plano"
            },
            plan_personal_sesiones: {
                ciclo: "Ciclo",
                ciclo_anterior: "Ciclo anterior",
                ciclo_siguiente: "Ciclo siguiente"
            },
            side_menu: {
                instrucciones: {
                    parrafo1: "Selecciona o tipo de plano que lhe interessa e faça uma avaliação de conteúdo.",
                    parrafo2:
                        "Avaliaremos quais áreas você precisa reforçar e proporemos um plano de estudo pessoal de acordo com seus resultados.",
                    parrafo3: "Conclua as sessões deste plano e reavalie seus pontos fortes e fracos.",
                    titulo: "Instruções:"
                },
                select_tipo: "Seleccione um tipo",
                tipo_diagnostico: "Tipo de diagnóstico:",
                no_seleccionado_tipo_diagnostico: "Tipo de diagnóstico: não foi selecionado"
            },
            titulo: "Planos pessoais"
        },
        showcasebox: {
            material_favorito: {
                blurb: "Encontrou um guia e/ou um vídeo que você gostou? Pode adicioná-lo à sua lista de favoritos.",
                lista: "Lista de favoritos",
                titulo: "Material favorito",
                ver: "Assistir"
            },
            plan_estudios: {
                agregar: "Adicionar plano de estudo",
                continuar: "Continuar",
                desactivar: "Desativar",
                planesAdactativosTitulo: "Planos Adaptados",
                planesDeEstudioTitulo: "Planos de estudo",
                titulo: "Meus Planos de Estudo"
            }
        },
        streaming: {
            anteriores: "Aulas virtuais",
            horario: "Programação",
            materiales: {
                fecha: "data de envio:",
                ver: "assistir",
                tipo_evaluacion: "Evaluación de ejercitación",
                tipo_guia_estudio: "Guia de estudo"
            },
            offline: "Transmissão offline.",
            relacionado: "Material relacionado",
            search: {
                search_placeholder: "Procura por nome ...",
                todos: "Tudo"
            },
            sin_canal: "Não há canal associado.",
            visitar_canal: `Você pode visitar nosso canal
                                                <a href="https://www.youtube.com/channel/%{canal}?view_as=subscriber" target="_blank">
                                                                aqui
                                                </a>
                                                .`,
            vivo: "Ao vivo!",
            ultima_clase: "Última Clase"
        },
        titulo: "Estudar"
    },
    evaluacion: {
        editar: {
            alumnos_can: "Os estudantes poderão realizar",
            cancelar: "Cancelar",
            desde: "Desde o dia",
            desde_ver_warn: "deve ter uma data de publicação dos resultados",
            desde_ver1: "Os estudantes poderão ver as respostas de",
            desde_ver2: "a partir do dia",
            desde_warn: "deve ter uma data de início",
            editar: "Editar",
            fechas_warn: "A data de início deve ser antes da data final",
            grupos_ver: "a quem partilhar",
            grupos_ver_warn: "Deve seleccionar pelo menos um",
            grupos_ver_warn2_1: "Desmarcando um",
            grupos_ver_warn2_2: "A avaliação deixará de lhe ser acessível e não poderá ver os seus resultados.",
            hasta: "Até o dia",
            hasta_warn: "deve ter uma data final",
            nombre: "Nome",
            sin_grupos1: "Não pode compartilhar uma avaliação: Não é proprietário",
            sin_grupos2: "ativos"
        },
        compartidas: {
            evaluaciones: "Evaluaciones",
            fin: "Fin",
            historial: "Historial de simulacros compartidos",
            inicio: "Inicio",
            not_found: "No se encontraron evaluaciones compartidas.",
            publicacion: "Publicación resultados",
            realizar: "Realizar",
            ver: "Ver"
        }
    },
    evaluacion_recomendada: {
        comenzar: "Começar",
        estadisticas: "Estatisticas",
        preguntas_abreviacion: "Perg",
        repetir_evaluacion: "Execute novamente",
        recomendadas: "Testes recomendados"
    },
    evaluacion_secuencial: {
        anterior: "Anterior",
        duda_anterior: "Dúvida Anterior",
        duda_siguiente: "Próxima Dúvida",
        no_completar: "Não, quero voltar",
        pregunta_completar: "Deseja completar esta seção?",
        si_completar: "Sim, quero terminar",
        si_completar_param: "Sim, quero {completar}",
        siguiente: "Próxima"
    },
    evaluar: {
        buscador: {
            boton_buscar: "Pesquisar",
            placeholder: "Id Teste",
            buscar: "Pesquisa Teste existente",
            resultados: "Resultados da pesquisa",
            tabla: {
                id: "Instrumento nº",
                instrumento: "Instrumento",
                not_found: "Instrumento não encontrado.",
                preguntas: "perguntas",
                seleccionar: "Seleccionar",
                tipo: "Tipo"
            }
        },
        dynamic_tabs: {
            buscar: "Pesquisar um teste",
            compartir: "2. Compartilhar",
            compartir2: "do instrumento seleccionado",
            generar: "Gerar novo instrumento",
            instrumento: "Teste",
            mis_propias: "As minhas próprias avaliações",
            seleccione: "1. Seleccione um método para gerar uma prova!",
            seleccione_recomendados: "Seleccione um dos nossos instrumentos recomendados",
            seleccione2: "As provas são gerados a partir de um instrumento de avaliação.",
            seleccione3: "Seleccione um teste para gerar a avaliação a partir das seguintes opções:",
            subir: "Carregue a sua própria prova",
            tab_compartir: "Compartilhar",
            tab_pauta: "Gabarito",
            terminar: "Para terminar de gerar",
            terminar2: "Seleccione a opção de compartilha no menu.",
            utilizar: "Utilização do instrumento recomendado"
        },
        generar_instrumento: {
            opciones: {
                asignar: "Atribuir Prova Impressão",
                button_undo: "Salvar sem configuração (não será compartilhado)",
                cancel_button: "Cancelar",
                cerrar_button: "Fechar",
                compartir: "Compartilhar Prova",
                enviar: "Enviar Prova",
                error1: "Ocorreu um erro ao gerar o seu arquivo de impressão.",
                error2: "Por favor, aguarde alguns minutos e tente novamente.",
                impresion: {
                    tooltip:
                        "Ao atribuir um teste para impressão, você terá que carregar os resultados de seus alunos, pois eles não receberão uma notificação para serem respondidos online.",
                    tooltip_label: "O que é isso?",
                    alumnos: "Imprimir para estudantes",
                    alumnos_compartir_copiar: "Cópia",
                    alumnos_compartir1: "Os estudantes poderão",
                    alumnos_compartir2: "no link",
                    formas: "Impressão para alunos com formas",
                    formas_detail: "(sem respostas)",
                    hoja: "Imprimir folhas de resposta",
                    hoja_formas: "Imprimir folhas de resposta com formas",
                    hoja_formas_detail: "(por aluno)",
                    hoja_vacia: "Imprimir hoja de respuesta <b>vacía</b>",
                    hoja_vacia_detail: "(sin pre llenar)",
                    label: "Impressão",
                    profes: "Imprimir para professor",
                    profes_formas: "Impressão para professor com formas",
                    profes_formas_detail: "(com respostas)",
                    titulo: "Opções de impressão",
                    instructivo_hoja_respuestas: "Instrutivo e melhores práticas",
                    warning:
                        "Aviso: Estamos a optimizar a impressão dos ensaios, por este motivo podemos gerar vistas com pequenos erros de formatação. Para tal, contatar monitores@ogr.cl para uma solução no caso de haver um problema"
                },
                loading_message1: " Gerando arquivo para impressão.",
                loading_message2: "Esta operação pode demorar alguns minutos, aguarde, por favor.",
                mas: "Mais opções",
                tabla_espec: "Baixar tabela de especificações",
                popup: {
                    titulo: "Está certo em continuar com esta ação?",
                    contenido: "A prova será criada com %{num_preguntas} perguntas.",
                    contenido_pilotaje:
                        "La evaluación se creará con %{num_preguntas} preguntas en donde %{num_pilotaje} son de pilotaje. Estas corresponden a las preguntas %{id_pilotajes}."
                },
                download_instrumento_material: {
                    title: "Baixar arquivo original",
                    tooltip:
                        "Recomendamos que você baixe o arquivo word deste botão, pois nas opções de impressão você só pode ver a forma e a folha de respostas."
                }
            },
            profesor: {
                cantidad_tiempo: "Número de perguntas e tempo",
                generar: "Gerar Instrumento de Avaliação",
                personalizar: "Personalizar",
                select_asignatura: "Selecionar assunto",
                select_categorias: "Selecionar as categorias para personalizar o conteúdo do instrumento de avaliação.",
                total_preguntas: "Total de perguntas"
            }
        },
        pasos: {
            de: "de",
            sel_eval_asig: {
                asignatura: "Disciplina",
                button: "Confirmar e continuar",
                desc: "Indicar o tipo de avaliação, o disciplina e o tempo do próprio teste.",
                message: "Tipo de avaliação, disciplina e tempo.",
                missing: "Campos em falta a preencher.",
                sel_asig: "Seleccione Disciplina",
                tiempo: "Tempo (minutos)",
                tipo: "Tipo/Nível"
            },
            sel_tipo: {
                button: "Confirmar e continuar",
                desc: "Indicar o tipo de resposta de teste próprio.",
                message: "Tipo de resposta",
                missing: "Campos em falta a preencher.",
                tipo: "Tipo de resposta"
            },
            step: "Passo",
            subir_archivo: {
                button: "Carregar arquivo",
                desc: "O arquivo deve estar em formato PDF ou Word e não deve exceder os 35 MB.",
                error: "O arquivo não corresponde a um PDF válido.",
                header: "Siga estes passos",
                message: "Anexe o arquivo com a sua prova."
            },
            subir_pauta: {
                button: "Confirmar diretriz e continuar",
                desc: "Estabelecer quantas alternativas as perguntas terão",
                instr1: "Complete a seguinte folha de resposta com as alternativas corretas do seu teste, para ser usada como diretriz.",
                instr2: "Complete apenas o número de perguntas na sua avaliação.",
                instr3: "Se quiser deixar uma pergunta como processo piloto e não a considerar na classificação, clique em \n(P).",
                instr4: 'Uma vez completada a diretriz, seleccionar "Confirmar diretriz e continuar".".',
                instruccion_puntuacion: "Você pode adicionar uma pontuação personalizada a cada pergunta.",
                message: "Estabelecer diretriz.",
                warn1: "Deve preencher a diretriz.",
                warn2: "Não pode haver alternativas vazias no meio.",
                warn3: "O número de alternativas não pode ser inferior a 2 ou superior a 5.."
            },
            subir_pauta_mixta: {
                button: "Confirmar diretriz e continuar",
                desc: "Definir quantas perguntas o teste tem",
                instr1: "Completar e indicar o tipo de pergunta para cada uma, incluindo o número de alternativas \ne a alternativa correta se for uma pergunta alternativa",
                instr2: "Se quiser deixar uma pergunta como processo piloto e não a considerar na classificação, clique em \n(P).",
                instr3: 'Uma vez completada a diretriz, seleccionar "Confirmar diretriz e continuar".".',
                instruccion_puntuacion: "Você pode adicionar uma pontuação personalizada a cada pergunta.",
                message: "Estabelecer diretriz.",
                warn: "O número de perguntas é necessário e tem que ser maior que 0.",
                warn1: "Tem de preencher todos os campos, tanto o número de alternativas para cada pergunta \ncomo a sua alternativa correcta",
                warn2: "O número de alternativas para cada pergunta não pode ser inferior a 2.",
                warn3: "A alternativa correta tem de corresponder ao número de alternativas em cada \npergunta..",
                warn4: "A alternativa correta só pode ter uma carácter."
            },
            clasificar_preguntas: {
                button_clasificar: "Classificar",
                button_skip: "Não agora e continuar",
                button_prev: "Anterior",
                button_next: "Segue",
                button_save: "Salve classificações e continue",
                message: "Classificar perguntas",
                desc: "Quer classificar suas perguntas? Isso permitirá que você visualize a análise de conteúdo das respostas de seus alunos."
            },
            tc: {
                accept1: "Aceito os",
                accept2: "termos e condições",
                accept3: "em relação ao armazenamento e privacidade desta avaliação.",
                button: "Carregamento completo do próprio teste",
                desc: "Definir o número de perguntas",
                desc2: 'Finalmente, confirme por favor que concorda com os termos e condições antes de seleccionar o \nbotão "Carregamento completo do próprio teste".',
                message: "Número de perguntas.",
                message2: "termos e condições.",
                modal_part01: "Os Termos e Condições Gerais abaixo indicados regem a utilização do",
                modal_part02: "criado através do site",
                modal_part03: "(a partir de agora",
                modal_part04: "), que é colocada à disposição dos utilizadores da Internet.",
                modal_part05:
                    'A simples utilização do portal confere-lhe o estatuto de utilizador ( a seguir, o "utilizador") da \nComunidade de',
                modal_part06:
                    "e expressa a aceitação plena e sem reservas de todos e cada um dos seus Termos e Condições que são publicados ao aceitar ao portal",
                modal_part07:
                    "Consequentemente, o utilizador deve ler atentamente os Termos e Condições cada vez que pretende utilizar ou aceitar ao portal.",
                modal_part08: "Os Termos e Condições podem ser alterados, no todo ou em parte, por",
                modal_part09:
                    "e tais alterações serão efetivas a partir do momento em que são publicadas ou inseridas no sítio comunitário ou a partir do momento em que são notificadas ao utilizador por qualquer meio. Com base no acima exposto, sugere-se que os visite periodicamente.",
                modal_part10: "As violações dos Termos e Condições dão direito",
                modal_part11:
                    "o direito de suspender ou cessar a prestação do serviço ao utilizador que o tenha feito, por acção ou omissão.",
                modal_subcontent1_11:
                    '"conteúdo carregado" significa qualquer arquivo carregado por um utilizador para a plataforma.',
                modal_subcontent1_12: "ou professores.",
                modal_subcontent1_13:
                    'O utilizador compromete-se a carregar "conteúdo carregado" de uma forma diligente, correta e legal.',
                modal_subcontent1_21: "O utilizador autoriza",
                modal_subcontent1_22: "para manter o conteúdo carregado nos servidores de",
                modal_subcontent1_23: "em perpetuidade. O conteúdo só pode ser exibido por",
                modal_subcontent1_24:
                    "aos utilizadores que estão associados ao mesmo estabelecimento que o utilizador que carregou o conteúdo carregado.",
                modal_subcontent1_31:
                    "não será responsável por qualquer utilização indevida por parte dos utilizadores da comunidade",
                modal_subcontent1_32: "do conteúdo carregado para a plataforma através do ensino aos utilizadores.",
                modal_subcontent1_41:
                    "não garante a veracidade do conteúdo carregado por um utilizador docente para a plataforma.",
                modal_subtitle1: "1° Carregar conteúdo",
                modal_title: "Termos e Condições"
            },
            vista_previa: {
                button: "Confirmar e continuar",
                desc: "Confirmar que o documento foi carregado corretamente com pré-visualização.",
                message: "Pré-visualização."
            }
        },
        pruebas_propias: {
            abrir: "Abrir",
            acciones: "Ações",
            compartir: "Compartilhar",
            fecha: "Data de criação",
            instrumento: "Teste",
            not_found: "Não há resultados para esta categoria",
            title: "Teste próprios",
            tipo_contestables: "Tipo de respostas"
        },
        recomendados: {
            not_found: "Não foram encontrados instrumentos recomendados para esta categoria.",
            seleccionar: "Seleccionar",
            ver: "Ver descrição"
        },
        subir_propia: {
            asignar_impr: "Impressão",
            boton_subir: "Carregar novo instrumento próprio",
            cancel_button: "Cancelar",
            cerrar_button: "Fechar",
            comp_tarde: "Compartilhar mais tarde",
            enviar_online: "Enviar Online",
            error1: "Ocorreu um erro ao gerar o seu arquivo de impressão.",
            error2: "Por favor, aguarde alguns minutos e tente novamente.",
            exito: "O seu teste foi carregado e salvo com sucesso como o",
            instrumento: "Teste",
            loading_message1: "Estamos gerarando arquivo para impressão.",
            loading_message2: "Esta operação pode demorar alguns minutos, aguarde, por favor.",
            loading1: "Preparação do arquivo",
            loading2: "Carregamento do arquivo",
            loading3: "Recurso associado",
            loading4: "Criar instrumento (1/2)",
            loading5: "Criar instrumento (2/2)",
            sugerencia: "Se tiver sugestões para melhorar esta função, por favor escreva-nos para monitores@ogr.cl."
        }
    },
    extras: {
        Abril: "Abril",
        Agosto: "Agosto",
        Diciembre: "Dezembro",
        Enero: "Janeiro",
        Febrero: "Fevereiro",
        Julio: "Julho",
        Junio: "Junho",
        Marzo: "Março",
        Mayo: "Maio",
        Noviembre: "Novembro",
        Octubre: "Outubro",
        Septiembre: "Setembro"
    },
    footer: {
        aprende: {
            estudiar: "Estudar",
            inicio: "Começar",
            intro: "Aprenda com ",
            orientacion: "Orientação",
            participa: "Participa",
            practicar: "Pratica",
            progreso: "Seu progresso"
        },
        ayuda: {
            sobre: {
                item: "Aprendo livre em outros países",
                titulo: "SOBRE APRENDO LIVRE"
            },
            soporte: {
                item: "Termos e Condições",
                item2: "Perguntas mais frequentes",
                contacto: "contacto@ogr.cl",
                titulo: "SUPORTE"
            }
        },
        contacto: {
            correo: "contacto@ogr.cl",
            escribe: "Dúvidas? Escreva para nós:",
            titulo: "Contato"
        },
        derechos: 'Todos os direitos reservados a "Open Green Road S.A." ®',
        info: {
            ayuda: "Centro de ajuda",
            terms: "Termos e Condições de Uso",
            titulo: "Mais informação"
        },
        navegacion: "Navegação",
        siguenos: "Siga-nos!",
        social_titulo: "Encontre-nos nas redes sociais",
        informacion_contacto: "Informação de contato"
    },
    general: {
        detalles: "Detalhes",
        nombre: "nome",
        ver_mas: "Ver mais",
        ver_menos: "Ver menos"
    },
    generar_ensayos: {
        asignatura: "Escolha a disciplina",
        configurar: "Configuração",
        configurar_preguntas_tiempo: "Número de perguntas e tempo",
        descargar: "Baixar",
        descripcion_personalizar: "Clique nas diferentes categorias para personalizar o conteúdo da avaliação.",
        loading_mensaje_dos: "Esta operação pode demorar alguns segundos, por favor aguarde.",
        loading_mensaje_uno: "Gerando o arquivo para impressão.",
        modal_instrucciones_descargar: {
            boton: "Compreendido",
            parrafo1: ` Há uma folha de respostas na última página da avaliação.
                                                <br />
                                                 Preencha-o com seus dados e, em seguida, escaneie o código QR do seu telefone celular.`,
            parrafo2: ` O código QR irá direcioná-lo para uma página onde você deve carregar uma foto de sua folha de respostas
                                                Respondidas.
                                                <br />
                                                Tire a foto com luz clara a        lâmina certa .`,
            titulo: " Instruções para enviar seus resultados"
        },
        personalizar: "Personalizar",
        realizar: "Fazer",
        titulo: "Gerar"
    },
    generar_ensayo_profesor: {
        site_warning: "<p>Estamos trabalhando para habilitar novas funcionalidades nesta sessão.</p>",
        titulo: "Gerar prova"
    },
    generar_instrumento_select: {
        titulo_option: "Escolha a disciplina"
    },
    grafico_desempeno_clasificacion: {
        descripcion:
            "Em uma escala de 0 a 100, em que 0 não é alcançado e 100 é totalmente alcançado, as respostas corretas são apresentadas em relação ao total de questões aplicadas para cada ",
        desempeno: "Desempenho",
        titulo: "Desempenho por "
    },
    grupo_usuarios: {
        popup: {
            cancelar: "Cancelar",
            debe_seleccionar: "Deve seleccionar pelo menos um"
        },
        profesores: {
            all_establecimiento: "Todos os estabelecimentos",
            info: {
                establecimiento: "Estabelecimentos",
                integrantes: "membros",
                modals: {
                    restart_password: {
                        q1: "Tem a certeza de que quer redefinir a senha?",
                        q2: "?",
                        title: "Confirmação*"
                    },
                    restart_password2: {
                        a1: "O utilizador não possui ",
                        a2: " válido. Devido à falta de dados, por favor contactar o gestor antes de prosseguir..",
                        a3: "A senha foi redefinida com sucesso para os primeiros 6 dígitos do ",
                        a4: ".",
                        title: "Aviso"
                    },
                    restart_passwords: {
                        q1: "Tem a certeza de que quer redefinir a senha do curso ",
                        q2: "?",
                        title: "Confirmação"
                    },
                    restart_passwords2: {
                        a1: "A senha foi redefinida com sucesso para os primeiros 6 dígitos do",
                        a2: ".",
                        title: "Aviso"
                    }
                },
                ocultar: "Ocultar",
                restart_passwords: "Resetar todas as senhas",
                tabla: {
                    acciones: "Acções",
                    n_ingresos: "Nº de ingressos nos últimos 30 dias",
                    nombre: "Nome",
                    restart_password: "Redefinir a senha",
                    ultima_fecha: "Última data de entrada"
                },
                ver: "Ver"
            },
            list: "Lista de",
            not_found1: "Não possui",
            not_found2: "parceiros para o período seleccionado",
            select_establecimiento: "Selecione um estabelecimento",
            cursos: "turmas",
            titulo: "Meus níveis"
        }
    },
    historial: {
        eval_comp: {
            acciones: "Acções",
            avance: "Avançar",
            cierre: "Fechar",
            creacion: "Criação",
            creador: "Criador",
            edit_eval: "Editar avaliação",
            est: "Estabelecimento",
            eval: "Avaliações",
            eval_id: "Avaliação ID",
            eval_not_found: "Sem resultados",
            eval_not_found2: "Não tem grupos associados",
            inicio: "Início",
            inst_id: "Instrumento ID",
            pers_rev: "Personalizar a revisão",
            propia: "Prova própria",
            recompartir: "Voltar a compartilhar",
            sel_curso: "Selecione uma turma",
            sel_doc: "Selcione professor",
            sel_est: "Seleccione um estabelecimento",
            sel_eval: "*Pesquisa por ID de avaliação",
            sel_inst: "*Pesquisa por ID do teste",
            titulo_impresas: "Provas impressas",
            titulo_online: "Teste online",
            filtros: "Filtros de pesquisa",
            usada_plan: "Utilizado no planejamento",
            ver_est: "Ver estatísticas",
            ver_eval: "Ver avaliação"
        },
        eval_no_comp: {
            acciones: "Acções",
            comp_inst: "Compartilhar instrumento",
            creacion: "Criação",
            creador: "Criador",
            inst_id: "Instrumento ID",
            not_found: "Nenhuma instrumento encontrado nesta categoria",
            sel_curso: "Selecione uma turma",
            sel_doc: "Selecione professor",
            sel_est: "Selecione um estabelecimento",
            sel_inst: "Pesquisa por ID do instrumento",
            sub_title: "Filtros de pesquisa",
            title: "Avaliação  não configurados (não compartilhados)",
            ver_inst: "Ver instrumento"
        },
        eval_pres: {
            eval_not_found: "Sem resultados",
            eval_not_found2: "presença",
            sel_curso: "Seleccione uma turma",
            sel_est: "Seleccione um estabelecimento",
            tabla: {
                acciones: "Acções",
                colegio: "Colégio",
                creador: "Criador",
                instrumento: "Instrumento",
                nombre: "Nome",
                tipo: "Tipo"
            },
            title: "presença"
        }
    },
    home: {
        itemsmenu: {
            como_usar: "Como usar Aprendo Livre?",
            generar_ensayo: "Faça ensaio",
            plan_estudios: "Planos de estudo",
            streaming: "Aulas ao vivo",
            logros: "Conquistas"
        },
        resumen: {
            dudas: "dúvidas respondidas",
            ensayos: "Ensaios",
            preguntas: "Perguntas respondidas",
            semana: "Esta semana você fez ..."
        },
        showcasebox: {
            carreras_interes: {
                sin_carreras: "Você ainda não selecionou seus cursos de interesse.",
                titulo: "Meus cursos de interesse",
                ver_todas: "Ver tudo"
            },
            dudas_recientes: {
                contestada: "Respondido %{resp} vezes",
                solucionada: "Solucionada",
                creado_por: "Criado por",
                no_dudas: "Sem dúvidas recentes encontradas.",
                pendiente: "Pendente",
                titulo: "Dúvidas recentes",
                ver_mas: "Ver mais"
            },
            grupo_usuarios: {
                actualizaciones: "Ultimas atualizações",
                ir_cursos: "Ir para minhas turmas",
                sin_cursos1: "Não tem",
                sin_cursos2: "parceiros",
                soy_docente: "Turma",
                todos: "Ver tudo ",
                titulo: "Meu curso"
            },
            noticias: {
                cerrar: "Fechar",
                none: "Nenhuma notícia neste momento. ",
                titulo: "Notícias",
                ver_mas: "Ver mais"
            },
            notificaciones: {
                titulo: "Notificações",
                ver_mas: "Ver mais"
            }
        },
        welcome: "Bem-vind@"
    },
    instruncciones_fijas: {
        instruccion: "Instrução"
    },
    instrumento: {
        titulo: "Teste",
        vista_previa: "Visualização"
    },
    landing: {
        inicio: {
            seccion1: {
                subtitulo: `Completamente <em>GRÁTIS</em> , para sempre`,
                titulo: `Prepare-se para o <em>ENEM 2023</em>`
            },
            seccion2: {
                subtitulo: `Online, Fácil e efetivo`,
                titulo: `Prepare-se gratuitamente para as
                                                                <em>4 provas</em>
                                                                do ENEM`
            },
            seccion3: {
                btn: `Comece hoje mesmo!`,
                parrafo: `O acesso ao
                                                                <em>Aprendo Livre</em>
                                                                é totalmente gratuito para todos os alunos que buscam se preparar para o ENEM de forma
                                                                online e efetiva.`,
                titulo: `Suporte
                                                                <em>gratuito</em>
                                                                para alunos`
            },
            seccion4: {
                contenido1: {
                    parrafo: `Você encontrará aulas e videos com material para ENEM, tirar dúvidas com a
                                                                                Comunidade, participar dos testes mensais e participar do ranking.`,
                    titulo: `Material multimídia`
                },
                contenido2: {
                    parrafo: `Atraves dos nossos Planos Adapatados, recomendamos sessões de estudo personalizadas
                                                                                para aprimorar seus conhecimentos e habilidades.`,
                    titulo: `Planos adaptados`
                },
                contenido3: {
                    parrafo: `Pratique para a prova através de testes com questões de múltipla escolha e acesse o
                                                                                feedback.`,
                    titulo: `Feedback imediato`
                },
                subtitulo: `Estas são apenas algumas das funcionalidades que temos para apoiar na sua preparação.`,
                titulo: `Nós ajudamos você a se preparar para dar <em>o seu melhor</em>`
            },
            seccion5: {
                btn: `Assistir`,
                parrafo: `Confira a programação das nossas aulas e nosso calendario de aulas ao vivo 2022 para as 5 provas.`,
                titulo: `Assista nossas aulas gratuitas para o ENEM`
            },
            topfooter: {
                btn: `Inscreva-se`,
                titulo: `Cadastre-se no Aprendo Livre hoje.
                                                                <b>É gratuito e sempre será</b>`
            }
        },
        login: {
            btn_email: "Entrar",
            btn_fb: "",
            btn_google: "Entrar com <em>Google</em>",
            btn_home: "Home",
            btn_logout: "Sair da sessão",
            btn_recover: "Esqueci minha senha?",
            btn_register: "Cadastre-se",
            error_auth1: "<strong>A combinação de e-mail/senha ou CPF/senha é incorreta.</strong>  As senha de ",
            error_auth2:
                ' são sensíveis a maiúsculas e minúsculas. Por favor, verifique a tecla "Bloq Mayús". Também pode tentar limpar o cache e os cookies do seu navegador.',
            error_auth3:
                "Para aumentar a segurança dos seus dados, aumentamos os requisitos de segurança das senhas, pelo que deve introduzir uma nova senha. Para o fazer, vá por favor para Esqueci minha senha? ou envie um e-mail para contacto@ogr.cl",
            error_pswd: "Você deve incluir uma senha.",
            error_user: "Você deve incluir um e-mail ou CPF",
            pswd: "Senha",
            titulo: "Entrar",
            titulo_alumno: "Entrar",
            user: "Email ou CPF"
        },
        menu: {
            aulas_vivo: "Aulas ao Vivo",
            comenzar: "Cadastre-se!",
            como_funciona: "Como funciona?",
            contacto: "Contato",
            pagina_inicial: "Página Inicial",
            provas_enem: "Provas ENEM",
            blog: "Blog"
        },
        registro: {
            datos_personales: {
                apellido_materno: "Sobrenome",
                apellido_paterno: "Sobrenome",
                confirmar_password: "Confirmar senha",
                cpf: "CPF",
                email: "E-mail",
                municipio: "Município",
                nombres: "Nomes",
                password: "Senha",
                telefono: "Telefone",
                terminos_y_condiciones:
                    "Li e aceito as <a href='/condicionesUso' target='_blank'> condições gerais de uso de %{companyName} </a>",
                year_egreso: "Em que ano você vai sair ou saiu da escola?"
            },
            completar_social: {
                titulo: "Complete seus dados"
            },
            msgSaved: `Enviamos um e-mail para o seu e-mail com instruções para validar sua conta. Se não chegar em 5 minutos ou mais, verifique sua pasta de spam.`,
            titulo: `Dados pessoais`,
            tituloDeslogueado: "Faça uma conta"
        },
        recuperar_password: {
            instruccion_email:
                "Digite seu e-mail o %{identificador} para receber instruções sobre como redefinir sua senha.",
            instruccion_email_no_identificador:
                "Digite seu e-mail para receber instruções sobre como redefinir sua senha.",
            boton_volver: "Voltar",
            boton_continuar: "Avançar",
            boton_reenviar: "Reenviar",
            mensaje_recuperacion: "",
            mensaje_recuperacion_id:
                "Si el identificador ingresado existe, un mensaje se te enviará a la dirección %{email} con las instrucciones."
        },
        reiniciar_password: {
            ingresa_nuevo_password: "Digite sua nova senha",
            fortaleza_password: "Força da senha:",
            muy_debil: "Muito fraca",
            debil: "Fraca",
            moderada: "Moderada",
            segura: "Forte",
            muy_segura: "Muito forte",
            nuevo_password_placeholder: "Nova senha",
            confirmar_password_placeholder: "Confirme a nova senha",
            password_largo_minimo: "A senha deve ter pelo menos 8 caracteres.",
            guardar_password: "Salvar senha nova",
            guardando: "Salvando ...",
            reinicio_exitoso:
                "Suas informações foram atualizadas com sucesso. Você pode acessar sua conta com sua nova senha.\nVocê será redirecionado para a página inicial em 5 segundos...",
            token_invalido: "Token inválido.",
            campos_no_coinciden: "Os campos não correspondem.",
            password_minimo_largo: "A senha deve ter pelo menos 8 caracteres.",
            password_vulnerable: "Esta senha é vulnerável. Tente colocar outro.",
            error: "Erro."
        }
    },
    libro_notas: {
        alumno_aun_no_realizo: "o aluno ainda não faz reforço",
        alumno_realizo: "aluno fez reforço",
        buscar_pagina: "pesquisar a página",
        csv: "Baixar CSV",
        de: "de",
        estudiantes: "Estudantes",
        evaluacion: "Avaliação",
        fin: "Fim",
        formativa: "Formativo",
        inicio: "Início",
        not_found: "Sem resultados",
        not_found_cursos: "Nenhuma turma para o período seleccionado",
        not_found2: "Nenhuma informação a mostrar",
        periodo: "Período",
        promedio: "Média",
        promedio_tooltip_escalas_globales:
            "A média da nota ou avaliação do aluno é a média calculada a partir das notas reais..",
        promedio_tooltip_formativas:
            "Se você aplicou Avaliações Formativas aos seus alunos, seus resultados não serão considerados para a média",
        promedios: "Médias",
        sumativa: "Summativo",
        tareas_pagina: "tarefas. Página"
    },
    libs: {
        api_services: {
            view_params: {
                usuario: {
                    email: "E-mail",
                    fecha_nacimiento: "Data de nascimento",
                    establecimiento: "Estabelecimento"
                }
            }
        },
        modules: {
            estadisticas: {
                timeframe: {
                    desde: "A partir de",
                    hasta: "Até",
                    ultimo_ano: "Último ano",
                    ultimo_mes: "Último mês",
                    ultimos_meses: "Últimos 6 meses"
                }
            }
        }
    },
    materiales_favoritos: {
        abrir: "Abrir",
        confirmacion: "Confirmação",
        confirmacion_mensaje: "Tem a certeza de que quer apagar este material da sua lista de favoritos?",
        not_found: "Ainda não salvou nenhum material favorito",
        title: "Materiais favoritos",
        tutorial: "Navegue e administre a lista de materiais que marcou como favoritos."
    },
    modules: {
        estadisticas: {
            timeframe: {
                desde: "A partir de",
                hasta: "Até",
                ultimo_ano: "Último ano",
                ultimo_mes: "Último mês",
                ultimos_meses: "Últimos 6 meses"
            }
        }
    },
    navbar: {
        aria: "Navegação do utilizador",
        avatar: {
            aria: "Utilizador Avatar",
            default_aria: "Avatar do utilizador por defeito"
        },
        ayuda: "Ajuda",
        edit: "Editar conta",
        logout: "Fechar Sessão",
        faq: "Perguntas frequentes",
        menu: {
            aria: "Menú de usuario"
        }
    },
    noticias: {
        profe: {
            actualizado: "Atualizado em",
            alumnos: "Alunos",
            alumnos_desc: "todos os estudantes na plataforma",
            alumnos_libres: "Estudantes livres",
            alumnos_libres_desc:
                "todos os alunos que tenham se registado independentemente ou que já não sejam alunos cobrados pela sumula",
            alumnos_nomina: "Alunos nomina",
            alumnos_nomina_desc: "alunos que fazem parte de um estabelecimento e grupo actualmente activo",
            confirmacion: "Confirmação",
            confirmacion_message: "Tem a certeza de que quer apagar esta notícia?",
            cursos: "Turmas",
            cursos_compartir: "Turmas para os quais compartilhará as notícias",
            cursos_warn: "Deve seleccionar pelo menos uma turma",
            editar: "Editar notícias",
            editar_boton: "Editar",
            eliminar_boton: "Fechar",
            establecimient_aplicar: "Estabelecimento a aplicar",
            establecimiento: "Estabelecimento",
            establecimiento_todos: "Todos os estabelecimentos",
            grupo_usuarios_todos: "Tudos",
            lugar_aplicar: "Lugar para aplicar",
            not_found: "Ainda não publicou nenhuma notícia",
            noticia_perfil: "Perfil da notícia",
            periodo: "Período",
            publicar: "Publicar notícias",
            publicar_boton: "Publicar notícia",
            ver_mas_boton: "Ver mais"
        }
    },
    notificaciones: {
        a_tu_r: "ao seu resultado em",
        agrego_comentario: "acrescentou um comentário",
        aqui: "aqui",
        de: "de",
        de_la_asginatura: "do assunto",
        del: "del",
        del_curso: "da turma",
        del_grupo: "do grupo",
        del_plan_de: "do plano de aulas",
        detalles: "Detalhes",
        ha_contestado: "respondeu",
        ha_creado: "criou",
        ha_marcado_c: "marcou como correta a sua resposta a",
        ha_subido_m_c: "carregou material suplementar para o módulo",
        la_duda: "a dúvida",
        material_complementario: "carregou material complementar",
        n_preguntas: "Nº de perguntas",
        no_pendientes: "Você não tem notificações pendentes.",
        plazo_hasta: "Prazo de resposta até",
        pro_entrenador: "Configure o seu treinador virtual",
        pro_imprimir: "Imprimir ensaios",
        pro_planes: "Desfrute dos seus planos de aula, versão Pro,",
        pro_tutor: "Adicione o seu tutor",
        pro1: "Caro",
        pro2: "informamo-lo de que a sua conta Pro de",
        pro3: "foi activada. Pode agora",
        profesor: "Professor",
        realizar_ahora: "Realizar agora!",
        revisalo: "Veja",
        te_ha_compartido: "Compartilhou consigo o plano de aula",
        te_ha_compartido_cla: "Compartilhou consigo a aula",
        te_ha_compartido_r: "Compartilhou consigo o reforço",
        te_ha_compartido_ta: "Compartilhou a tarefa consigo",
        te_ha_enviado: "enviou-lhe um pedido para executar",
        te_ha_enviado_un: "enviou-lhe um lembrete para realizar a sessão",
        tiempo: "Tempo",
        titulo: "Notificações",
        ver_todas: "Ver tudo",
        pendientes: "Encostas",
        historial: "Histórico de notificações"
    },
    numero_preguntas_tiempo: {
        advertencia_numeros_personalizados_dos: "Não podem ter menos de 5 perguntas.",
        advertencia_numeros_personalizados_tres: "Não são considerados para o cálculo das estatísticas.",
        advertencia_numeros_personalizados_uno: "Os Testes personalizados",
        advertencia_numeros_personalizados_cuatro:
            "Considere que a folha de respostas impressa possui até 90 itens para responder.",
        ej_num_minutos: "ex. 20",
        ej_num_preguntas: "ex. 5",
        error_limite_numero_preguntas: "O número de perguntas não pode ser maior que",
        mensaje_error: " Por favor, insira um número válido de perguntas e minutos.",
        minutos: "minutos",
        preguntas: "perguntas"
    },
    orientacion: {
        blog_showcasebox: {
            not_found: "Sem notícias no momento.",
            titulo: " O último "
        },
        carreras: {
            buscador_carreras: {
                buscador_placeholder: "Curso...",
                buscador_placeholder_sede: "Sede...",
                buscador_placeholder_universidad: "Universidade...",
                buscar: "Pesquisar",
                carrera: "Curso",
                mas_opciones_busqueda: "Mais opções de pesquisa",
                menos_opciones_busqueda: "Menos opções de pesquisa",
                not_found: "Nenhuma carreira foi encontrada com esses critérios de pesquisa.",
                opciones_adicionales: "Opções de pesquisa adicionais:",
                opciones_adicionales_placeholder: "Selecione uma opção",
                sede: "Sede",
                seguir: "Seguir",
                siguiendo: "Seguindo",
                titulo: "Pesquisar cursos",
                universidad: "Universidade",
                ver: "Ver"
            },
            carrera: {
                area: "Área",
                detalles: "Detalhes",
                guardar: "Salvar",
                informacion_adicional: "Informação adicional",
                informacion_solicitada: "Informação solicitada",
                malla_curricular: "Currículo",
                modalidad: "Modalidade",
                ponderaciones: "Ponderações",
                preferencia: "Preferência:",
                solicitar_informacion: "Solicite informações da universidade",
                ubicacion: "Localização",
                video: "Vídeo"
            },
            terminos_modal: {
                terminos_y_condiciones: "Termos da seção de cursos"
            }
        },
        universidades: {
            /* buscador_universidades: {
                buscador_placeholder: "Universidade...",
                buscar: "Pesquisar",
                not_found: "",
                titulo: "Pesquisar universidade",
                universidad: "Universidade"
            }, */
            /* universidad: {
                carrera: "Carreira",
                carreras: "Carreiras",
                carreras_not_found: "Nenhuma carreira foi encontrada com esses critérios de pesquisa.",
                les_gusta: " usuários gostam",
                presentacion: "Apresentação:",
                sede: "Campus",
                sedes: "Campus",
                seguir: "Seguir",
                siguiendo: "Seguindo",
                universidad: "Universidade",
                universidades: "Universidades",
                ver: "Ver"
            },
            universidad_recomendada: {
                titulo: "Universidade do mês"
            } */
        }
    },
    otros: {
        fecha_atras_pipe: {
            ayer: "1 dia atrás",
            hace_ano: "mais do que%{smart_count} ano |||| mais do que %{smart_count} ano",
            hace_dia: "faz %{smart_count} dia |||| faz %{smart_count} dias",
            hace_mes: "mais do que %{smart_count} mes |||| mais do que %{smart_count} meses",
            hace_semana: "mais do que %{smart_count} semana |||| mais do que %{smart_count} semanas",
            hoy: "hoje"
        },
        paginador_html_1: "Se encontraram",
        paginador_html_2: "Mostrando resultados de <b>%{first}</b> ao <b>%{last}</b>.",
        reportar: "queixa"
    },
    participa: {
        dudas: {
            btn_lista: "Vá para lista de dúvidas",
            categorias: {
                sin_actividad: "Sem atividade.",
                total: "Total de dúvidas:",
                ultima: "Última dúvidas:"
            },
            listado: {
                btn_nueva: " Nova pergunta ",
                buscar: " Pesquisar ",
                filtro: {
                    categoria_select: " Qualquer categoria ",
                    curso: "Turma:",
                    curso_select: "turma",
                    mias: " Minhas dúvidas",
                    mostrando: "Mostrando por:",
                    todas: " Todas as dúvidas "
                },
                not_found: " Nenhuma conversa encontrada dentro dos critérios de pesquisa.",
                orden: {
                    fecha: "Data"
                },
                titulo: " Comunidade "
            },
            nueva: {
                btn_anterior: " Anterior",
                btn_cancelar: "Cancelar",
                btn_publicar: " para postar",
                btn_siguiente: " Próximo",
                chkbox_publicar: " pronto para postar",
                paso_categ: {
                    nombre: " Categorização ",
                    opcion_default: "-- Seleccionar Categoria --",
                    pregunta: " Como você classificaria sua dúvida?"
                },
                paso_cont: {
                    detalle: " Escreva a sua pergunta abaixo ",
                    nombre: "Contente",
                    titulo: "Título"
                },
                paso_curs: {
                    curso: "Turma",
                    nombre: "Turmas",
                    opcion_default: "-- Selecione o curso--"
                },
                paso_prev: {
                    nombre: " Pré-visualização",
                    predeterminado: " O título da sua pergunta continua a ser o default ",
                    sin_detalle: " Ainda não acrescentou a sua pergunta.",
                    sin_titulo: " Você ainda não adicionou um título "
                },
                titulo: " Criar tópico"
            },
            titulo: "Comunidade",
            todas: "Todas as dúvidas"
        },
        itemsmenu: {
            nueva_duda: "Pergunte à comunidade ",
            responder_duda: "Responder a perguntas "
        },
        rrss_showcase: {
            titulo: "Encontre-nos nas redes sociais"
        }
    },
    perfil: {
        editar_cuenta: "Editar conta",
        curso_indefinido: "Não definido",
        fecha_indefinida: "Número de utilizador",
        preferencia_carrera: "Preferência de curso",
        user_id: "Número de utilizador"
    },
    plan_clase: {
        ver_clase: "Ver Aulas",
        vamos: "¡Vamos aquí!",
        estara_disponible: "Esta sessão será visível a partir de:",
        no_disponible: " Esta sessão não está disponível"
    },
    plan_clase_alumno: {
        title: "Meus planos de aula",
        not_found: "Nenhum plano encontrado."
    },
    popup_cookies: {
        btn_ok: "Entiendo",
        texto: ` cookies próprios e de terceiros para melhorar nossos serviços. Se você continuar navegando, consideraremos que você aceita este us.`,
        titulo: "Privacidade y cookies",
        label_cerrar: "Fechar mensagem de privacidade e cookies"
    },
    practicar: {
        itemsmenu: {
            hacer_ensayo: "Faça ensaios",
            hacer_ensayo_recomendados: "Faça o teste recomendado "
        },
        recomendados: {
            no_encontrados: "Nenhum comentário recomendado encontrado.",
            titulo: "%{tipoInstrumento} recomendado"
        },
        showcasebox: {
            entrenador: {
                btn_config: "Configuração",
                link_config: "aqui",
                no_config: "Você não tem seu treinador virtual configurado. <br /> Configure-o ",
                preguntas_contestadas: "Perguntas respondidas<br />a semana passada: ",
                texto_animo: {
                    buen_trabajo: "Bom trabalho!",
                    comenzar: "O que você está esperando para começar?",
                    sigue_asi: "Continue assim!",
                    ya_casi: "Você está quase pronto. Você pode!"
                },
                titulo: "Treinador virtual"
            },
            evaluaciones_mensuales: {
                comenzar: "começar",
                este_mes: "%{evaluacionTipo} este mês",
                no_disponible: "Não disponível",
                por_definir: "a definir",
                titulo: "Desafios %{evaluacionTipo} mensal",
                ultimos_resultados: "resultados mais recentes        <br />"
            },
            ranking_comunidad: {
                nivel: "Nível",
                puntos: "Pontos",
                titulo: "Ranking da comunidade"
            },
            ranking_evaluaciones_mensuales: {
                calificacion: "Pontuação",
                not_found: "Você ainda não participou deste ranking.",
                posicion: "Sua posição",
                sin_datos: "Sem dados disponíveis. ",
                titulo: "Ranking %{evaluacionTipo} mensal",
                top_50: "Top 50",
                total: "Total"
            },
            resumen: {
                ensayos: "Testes",
                preguntas_contestadas: "Perguntas respondidas",
                preguntas_correctas: "Perguntas corretas ",
                semana: "Esta semana você fez ..."
            }
        },
        titulo: "Prática"
    },
    pregunta_ensayo: {
        correcto: "Correto",
        formulas: "Fórmulas",
        incorrecto: "Incorreto",
        instruccion: "Instrução"
    },
    preguntas_ensayo: {
        completar: "Completar",
        completar_evaluacion: `Completar o teste`,
        descargar_archivo: "Baixar Arquivo",
        entregando_evaluacion: "Entregando prova",
        entregar_evaluacion: "Entregar prova",
        ir_a_home: "Voltar ao início.",
        modal_error: {
            contenido:
                "Ocorreu um problema ao enviar a avaliação. Quer baixar um arquivo com as suposições, caso tenha pouco tempo? Desta forma, você pode lembrar o que respondeu.",
            titulo: "Aviso"
        },
        warning_init: "A partir de agora, recomendamos que você também deva marcar quando você pula uma pergunta.",
        warning_limit_time: "A avaliação ultrapassou o prazo e portanto não é possível entregá-la.",
        warning_time: `Você está tentando responder a uma avaliação fora do prazo estipulado. Não será considerado
                                 válido para o cálculo de suas estatísticas, e não será considerado como uma entrega válida para
                                 seu estabelecimento.`,
        warning_unpermitted: "Este teste não foi compartilhado com você.",
        not_yet: "Todavía no puedes ver esta evaluación.",
        previsualizar: "Previsualizar",
        advertencia: "Advertencia",
        not_yet_profesor_tiempo: "No se puede entregar la evaluación porque tu profesor ha reiniciado el tiempo",
        inicio: "Inicio",
        termino: "Término",
        actual: "Actual",
        siguiente: "Siguiente",
        alerta_evaluacion_tiempo: {
            msj: `Te quedan %{time} para finalizar tu evaluación. ¡Suerte!`
        }
    },
    progreso: {
        estadisticas: {
            graficos_asignatura: {
                correctas: "Corretas",
                descripcion:
                    "Número de respostas dos alunos classificadas por tipo de resultado: correto, incorreto e omitido.",
                distr_tramos: {
                    descripcion:
                        "Número de avaliações realizadas, ordenadas por faixas de %{escala} de acordo com os resultados obtidos em cada uma delas.",
                    eje_x: "Intervalos de %{escala}",
                    eje_y: "Número de avaliações",
                    leyenda: "Número de avaliações por segmento",
                    para: "para",
                    titulo: "Distribuição dos resultados por faixa de "
                },
                incorrectas: "Incorretas",
                nombre: "Desempenho médio %{asignatura}",
                omitidas: "Omitido",
                titulo: "Desempenho médio por tipo de resposta para %{asignatura}"
            },
            subtitulo1: " Resumo Geral",
            subtitulo2: "Gráficos por Assinatura",
            tabla_resumen: {
                correctas: "Corretas",
                incorrectas: "Incorretas",
                maxima: "Classificação máxima",
                n_ensayos: "Núm. avaliações",
                omitidas: "Omitidas",
                promedio: "Classificação média",
                ultimo_ptje: "Última nota"
            },
            titulo: " Estatísticas"
        },
        historial: {
            estadisticas: {
                maximo: "Máximo",
                promedio: "Média",
                reciente: "Mais recente",
                resumen: "Resumo estatístico",
                tendencia: "Tendência"
            },
            pendientes: {
                acciones: "Ações",
                descargar: "Baixar",
                escanear: "Digitalização",
                fecha_fin: "Data de Fim",
                fecha_inicio: "Data de início",
                forma: "Forma",
                imprimir_1: "Estamos gerando o arquivo para impressão.",
                imprimir_2: "Esta operação pode demorar alguns segundos, por favor aguarde.",
                n_preguntas: "N° questões",
                nombre: "Nome",
                not_found: "Não há testes compartilhados.",
                realizar: "Fazer",
                titulo: "pendente",
                tooltip: "Você pode usar uma folha de respostas para obter seus resultados online."
            },
            recientes: {
                none: "Sem testes recentes",
                titulo: "Testes recentes",
                ver: "Assistir",
                ver_todos: "ver tudo"
            },
            respuesta: {
                indicaste_dudas: "Você indicou ter dúvidas nesta pergunta",
                sin_texto_asociado: "Pergunta sem texto associado",
                preguntas_piloto: "Pergunta de pilotagem",
                preguntas_piloto_mensaje: "As perguntas piloto não são consideradas ao avaliar a avaliação.",
                omitida: "resposta omitida",
                correcta: "resposta correta",
                incorrecta: "resposta incorreta",
                respuesta_estudiante: "resposta do aluno",
                minuto: "minuto",
                segundo: "segundo",
                solucion_pregunta: "Solução para a pergunta ",
                respuesta_pregunta: "Resposta da pergunta",
                cerrar: "Fechar",
                ver: "Ver"
            },
            respuestas: {
                correctas: "Respostas corretas",
                desempeno: "desempenho",
                incorrectas: "Respostas erradas",
                omitidas: "Respostas puladas ",
                respuesta_correcta: "Resposta <br/> correta ",
                resultados: "Meus resultados",
                solucion: {
                    ocultar: "Esconder",
                    solucion: "solução",
                    ver: "Assistir"
                },
                tiempo: "Tempo de resposta aproximado: ",
                titulo: "Respostas",
                desempeno_por: "Desempeño por",
                detalle_por_subeje: "Detalle por subeje",
                resultados_disponibles_en: "Os resultados da avaliação estarão disponíveis em",
                preguntas_piloto:
                    "As questões assinaladas com (P) correspondem a questões piloto e não são consideradas no cálculo da nota.",
                no_tuviste_preguntas: "Você não tinha questãos",
                updating: "Atualizando resposta ..."
            },
            resultados: {
                compartir: "Compartilhar resultados por e-maill",
                correctas: "Correto ",
                debilidades: "Seus pontos fracos eram",
                incorrectas: "Incorreto ",
                omitidas: "Omitido ",
                opciones: "Escolhas",
                pilotaje: "Pilotagem",
                realizar: "Fazer",
                recomendaciones: "recomendações",
                tarea: "Resultado da tarefa",
                titulo: "Resultado",
                ver: "Ver",
                ver_solucion:
                    'Para saber como encontrar a alternativa correta para cada questão, clique em "Ver solução".',
                volver: "Refazer"
            },
            terminadas: {
                acciones: "Ações",
                calificacion: "Qualificação",
                compartido: "Compartilhado por ",
                estadisticas: "Estatísticas",
                fecha: "Data",
                fecha_disponible: "Resultados disponíveis a partir de",
                forma: "Forma",
                nombre: "Nome",
                not_found: "Nenhuma %{evaluacionTipo} foi encontrada nesta categoria ",
                porcentaje: "Percentagem",
                reforzamiento: "Reforço",
                resultados: "Publicação de resultados",
                titulo: "finalizado ",
                ver: "Assistir",
                ver_reforzamiento: "Veja o reforço",
                realizar_reforzamiento: "Faça o reforço"
            },
            titulo: "Testes realizados"
        },
        itemsmenu: {
            estadisticas: "Estatísticas ",
            evolucion: "Evolução do seu progresso ",
            historial: "Histórico de teste "
        },
        mi_progreso: {
            evolucion: {
                titulo: "Evolução dos seus resultados",
                tutorial:
                    "Aqui você pode acompanhar o andamento de seus resultados. Selecione as disciplinas e o intervalo de tempo que lhe interessam e observe seu progresso."
            },
            desempeno_asignaturas: {
                titulo: "Suas respostas por assunto",
                tutorial: `Aqui você pode monitorar o número de questões que você perdeu ou respondeu correta ou incorretamente em cada assunto. Você também pode clicar em “Ver %{clasificacionTipo}” para resultados mais precisos, e assim conhecer seus pontos fortes e fracos em cada assunto.`
            },
            titulo: "Meu progresso"
        },
        profe: {
            alumno: "Alumno",
            curso: "Turma",
            desempeno_evolutivo: {
                fecha: "data",
                resultado: "Resultado"
            },
            establecimiento: "Estabelecimento",
            instrucciones: "Instruções",
            instrucciones_desc:
                'Abaixo está o número de perguntas respondidas corretamente, incorretamente ou omitidas pelos estudantes, agrupadas por assunto. Seleccione uma escola, um curso e um período de tempo que deseje observar. Pode também seleccionar um determinado estudante e ver os seus resultados. Clique em "Ver eixos" para uma visão mais detalhada por eixos e sub-eixos em cada assunto.',
            menu_asignatura: {
                sel: "Selecção do assunto"
            },
            seleccione: "Selecione",
            titulo: "Progresso do estudante"
        },
        showcasebox: {
            grafico: {
                descripcion: "Aqui você pode ver a evolução de suas",
                desenpenio_para: "Desempenho para",
                progreso_por: "Progresso por",
                titulo: "evolutiva"
            },
            titulo: "Resumo do seu progresso"
        },
        titulo: "Seu progresso "
    },
    reportes_modal: {
        cancelar: "Cancelar",
        categoria: "Categoria",
        cerrar: "Fechar",
        detalle: "Detalhe",
        detalle_descripcion: "O detalhe é obrigatório e deve ter no mínimo 2 caracteres.",
        detalle_placeholder: "Insira informações adicionais aqui",
        mensaje_reporte: "Obrigado pelo seu relatório! Nossos professores estão trabalhando para resolver o problema.",
        razon: "Razão",
        reportar: "Relatório",
        seleccione_categoria: "-- Selecione a Categoria --",
        seleccione_razon: "-- Selecione a Razão --",
        titulo: "Reportar %{tabla}"
    },
    respuestas_ensayo: {
        previsualizar: "Previsualizar"
    },
    simple_modal: {
        cancelar: "Cancelar",
        ok: "Ok"
    },
    tabla_resumen_evaluacion_instancia: {
        correctas: "Correto",
        estudiante: "Aluno",
        fecha: "Data",
        incorrectas: "Incorreta",
        intento: "Tentou",
        intentos: "Tentativas",
        omitidas: "Omitido",
        puntaje: "Pontuação"
    },
    typeform: {
        button: "Responder à pesquisa"
    },
    topmenu: {
        ayudas: "Centro de ajuda",
        resumen_de_uso: "Resumo de uso",
        biblioteca: "Biblioteca",
        comunidad: "Comunidade",
        corregir: "Corrigir",
        estudiar: "Estudar",
        evaluar: "Avaliar",
        home: "Início",
        materiales: "Materiales",
        mis_noticias: "Minhas noticias",
        orientacion: "Orientação",
        participa: "Participa",
        planes_de_clase: "Planos de aula",
        planes_de_clase_predefinidos: "Planos de aula predefinidos",
        planes_estudio: "Planos de estudo",
        practicar: "Prática",
        resultados: "Resultados",
        tu_progreso: "Seu progresso",
        videos: "Vídeos e aulas"
    },
    tour: {
        btn: {
            next: "",
            ok: "Esperto!",
            prev: ""
        },
        home: {
            actividades: "Atividades recomendadas"
        },
        topmenu: {
            estudiar: "Material de estudo e aulas ao vivo",
            orientacion: "Informações sobre carreiras e faculdades",
            participa: "Faça ou responda a perguntas da comunidade ",
            practicar: "Testes para praticar",
            tu_progreso: "História e estatísticas"
        }
    },
    reportes: {
        filtrar_datos: "Filtrar dados",
        resumen_uso: {
            titulo: "Resumo de Uso",
            evaluacion: "Avaliação:",
            asignatura: "Matéria",
            tipo: "Tipo:",
            asociaciones: "Asociaciones:",
            establecimiento: "Estabelecimento:",
            curso: "Turma:",
            seleccionar_curso: "-- Selecione a turma --",
            desde: "De:",
            hasta: "Até:",
            info: "*Se escolhe um tipo, os dados da avaliação será filtrado por este.",
            buscar: "Buscar",
            resultados: {
                resultados: "Resultados",
                resultados_acumulados: "Resultados acumulados de los establecimientos seleccionados",
                resultados_establecimiento: "Resultados por establecimiento",
                docentes: "Docentes",
                ingresos_sistema: "Entrou no sistema",
                instrumentos_generados: "Provas geradas",
                evaluaciones_compartidas: "Avaliações compartilhadas",
                evaluaciones_presenciales: "Avaliações Presenciais",
                evaluaciones_contestadas: "Avaliações Respondidas",
                estudiantes: "Estudantes",
                cuentas_administradoras: "Contas Administradoras",
                not_found: "No se encontraron resultados",
                administrador_instrumentos_descripcion:
                    "N° de instrumentos (únicos) gerados por administradores na faixa de datas definida",
                actividad_docente: {
                    titulo: "Atividade docente",
                    nombre: "Nome",
                    ultimo_ingreso: "Ultimo acesso",
                    ingresos_30_dias: "Acessos últimos 30 dias",
                    total_instrumentos: "Total Provas",
                    total_evaluaciones: "Total Avaliações"
                }
            }
        },
        informe_uso: {
            titulo: "Relatório de uso"
        },
        resultados_evaluaciones: {
            titulo: "Resultados das Avaliações"
        },
        informe_anual: {
            titulo: "Informações Anual"
        }
    },
    titles: {
        core: {
            achievements: {
                actividades: {
                    edit: "Editar atividade",
                    list: "Atividades"
                },
                logros: {
                    edit: "Editar conquista",
                    list: "Conquistas"
                }
            },
            artezonas: {
                artezona_paginas: {
                    edit: "Editar página de Artezona",
                    list: "Páginas de Artezona",
                    new: "Criar página de Artezona",
                    show: "Página de Artezona"
                },
                artezonas: {
                    activas: "Artezonas ativas",
                    edit: "Editar artezona",
                    list: "Artezonas",
                    new: "Criar artezona",
                    show: "Artezona"
                },
                artezona_instancias: {
                    list: "Instâncias de Artezona"
                },
                artezona_zonas: {
                    edit: "Editar zona de artezona",
                    show: "Zona de artezona"
                }
            },
            asignatura_evaluacion_tipos: {
                edit: "Editar tipo de avaliação de assunto",
                list: "Tipos de avaliação de assunto",
                new: "Criar tipo de avaliação de assunto"
            },
            asociaciones: {
                asociacion_contactos: {
                    edit: "Editar contato da associação",
                    list: "Contatos da associação",
                    new: "Criar contato da associação",
                    show: "Contato da associação"
                },
                asociaciones: {
                    edit: "Editar associações",
                    list: "Associações",
                    new: "Criar associações",
                    show: "Associação"
                }
            },
            ayudas: {
                home_ayudas_profesor: "Ajuda",
                list: "Ajuda",
                show: "Ajuda"
            },
            ayudas_admin: {
                categoria_ayudas: {
                    admin: "Categorias de ajuda",
                    edit: "Editar categoria de ajuda",
                    new: "Criar categoria de ajuda"
                },
                list: "Ajuda"
            },
            blog: {
                posts: {
                    edit: "Editar post",
                    list: "Posts",
                    new: "Criar post",
                    show: "Post"
                },
                posts_admin: {
                    list: "Posts",
                    show: "Post"
                }
            },
            carga_nominas: {
                nomina_alumnos: "Nómina de alunos",
                nomina_profesores: "Nómina de professores"
            },
            carreras: {
                buscador_carreras: "Buscador de carreiras",
                carrera_sede: "Carreira sede",
                mis_carreras: "Minhas carreiras"
            },
            cuentas: {
                deshabilitadas: "Contas desativadas",
                edit: "Editar conta",
                list: "Contas",
                new: "Criar conta"
            },
            desafios: {
                show: "Desafio",
                desafio_saber11: "Desafio saber 11"
            },
            dudas: {
                dudas: {
                    edit: "Editar dúvida",
                    list: "Dúvidas",
                    new: "Criar dúvida",
                    show: "Dúvida"
                },
                duda_categorias: "Categorias de dúvida"
            },
            ensayos: {
                encuesta_ensayo_adaptive: "Pesquisa",
                ensayo_instancias: "Avaliações realizadas",
                ensayos_mensuales: "Avaliações mensais",
                ensayos_recomendados: "Avaliações recomendadas",
                evaluacion: "Avaliação",
                evaluaciones_compartidas: "Avaliações compartilhadas",
                evaluaciones_multiples: {
                    compartidas: "Avaliações compartilhadas",
                    generar: "Gerar avaliação",
                    realizar: "Realizar avaliação",
                    recomendadas: "Avaliações recomendadas",
                    resultados: "Resultados",
                    revisar_simulacros: "Revisar simulacros"
                },
                evaluaciones_corregir: "Corrigir avaliação",
                generar_ensayo: "Gerar avaliação",
                generar_ensayo_profesor: "Gerar avaliação",
                generar_instrumento_validado: "Gerar instrumento validado",
                imprimir_ensayo: "Imprimir avaliação",
                libro_notas: "Livro de notas",
                realizar_ensayo: "Realizar avaliação",
                realizar_ensayo_adaptive: "Realizar avaliação",
                realizar_ensayo_secuencial: "Realizar avaliação",
                realizar_evaluacion_interactiva: "Realizar avaliação",
                resultados_ensayo_adaptive: "Resultados",
                resultados_ensayo_alumno: "Resultados",
                resultados_ensayo: "Resultados",
                revisar_ensayos: "Revisar avaliações"
            },
            entrenador: {
                entrenador_usuarios: {
                    edit: "Editar usuário treinador"
                }
            },
            escala_globales: {
                edit: "Editar escala global",
                list: "Escalas globais",
                new: "Criar escala global",
                show: "Escala global"
            },
            establecimientos: {
                establecimiento_contactos: {
                    edit: "Editar contato do estabelecimento",
                    list: "Contatos do estabelecimento",
                    new: "Criar contato do estabelecimento",
                    show: "Contato do estabelecimento"
                },
                establecimiento_reportes: {
                    edit: "Editar relatório do estabelecimento",
                    list: "Relatórios do estabelecimento",
                    new: "Criar relatório do estabelecimento",
                    show: "Relatório do estabelecimento"
                },
                establecimientos: {
                    edit: "Editar estabelecimento",
                    list: "Estabelecimentos",
                    new: "Criar estabelecimento",
                    show: "Estabelecimento"
                },
                administrar: "Administrar estabelecimentos"
            },
            estadisticas: {
                estadisticas: "Estatísticas",
                estadisticas_alumno: "Estatísticas",
                estadisticas_declarados: "Estatísticas",
                estadisticas_evaluacion: "Estatísticas",
                estadisticas_evaluacion_multiple: "Estatísticas",
                estadisticas_evoluticas_plan: "Estatísticas evolutivas",
                estadisticas_multiples: "Estatisticas",
                estadisticas_progreso: "Estatísticas",
                evaluacion_instancia_estadisticas: "Estatísticas",
                informe_anual: "Relatório anual",
                informes: "Relatórios",
                progreso: "Progresso",
                progreso_alumno: "Progresso aluno",
                resumen_general: "Resumo geral",
                resumen_uso: "Resumo de uso",
                resumen_uso_asociaciones: "Resumo de uso",
                tutor_estadisticas: "Estatísticas"
            },
            evaluaciones: {
                admin_resultados: {
                    historial_evaluaciones: "Histórico",
                    recalificar_evaluacion: "Recalificar avaliação",
                    admin_resultados_menu: "Menu resultados",
                    eliminar_evaluacion: "Eliminar avaliação",
                    eliminar_resultados_alumno: "Eliminar resultados alunos"
                },
                estadisticas_evaluacion_recomendada: "Estatísticas",
                evaluaciones_recomendadas: {
                    edit: "Editar avaliação recomendada",
                    list: "Avaliações recomendadas",
                    new: "Criar avaliação recomendada"
                },
                crear_evaluacion_multiple: "Criar avaliação",
                evaluacion_admin: "Avaliação",
                evaluaciones_masivas: "Avaliações em massa"
            },
            grupo_usuarios: {
                list: "Cursos"
            },
            grupo_recursos: {
                edit: "Editar grupo recurso",
                list: "Grupos recursos",
                new: "Criar grupo recurso",
                show: "Grupo recurso"
            },
            horario: {
                edit: "Editar horário",
                list: "Horários",
                new: "Criar horários",
                show: "Horário"
            },
            instrumentos: {
                pruebas_propias: "Provas próprias",
                subir_prueba_propia: "Enviar prova própria",
                buscador_instrumento: "Buscador de instrumento",
                crear_instrumento_multiple: "Criar instrumento",
                ensayos_mensuales_admin: "Avaliações mensais",
                ensayos_mes: "Avaliações do mês",
                instrumento_desde_lista_preguntas: "Criar instrumento a partir de lista de perguntas",
                instrumento_desde_lote: "Criar instrumento a partir de lote",
                instrumento_multiple: "Instrumento múltiplo",
                instrumento: "Instrumento",
                instrumentos_predefinidos: {
                    edit: "Editar instrumento predefinido",
                    list: "Instrumentos predefinidos",
                    new: "Criar instrumento predefinido",
                    show: "Instrumento predefinido"
                }
            },
            landing: {
                demo: "Demo",
                home: "Início",
                instrucciones_eliminacion_datos: "Instruções para eliminar dados",
                landing: "Página inicial",
                terminos_y_condiciones: "Termos e condições"
            },
            materiales: {
                biblioteca: "Biblioteca",
                list: "Materiais",
                material_apps: "Material",
                material_udd: "Material",
                material: "Material",
                materiales_saber: "Materiais",
                materiales_simce: "Materiais",
                materiales_thumbnails: "Miniaturas de materiais",
                materiales_v1: "Materiais",
                recursos_interactivos: "Recursos interativos"
            },
            niveles: {
                edit: "Editar nível",
                list: "Níveis",
                new: "Criar nível",
                show: "Nível"
            },
            noticias: {
                noticias: {
                    edit: "Editar notícia",
                    list: "Notícias",
                    new: "Criar notícia",
                    show: "Notícia"
                },
                noticia_admin: "Notícia",
                noticias_profesor: "Notícias"
            },
            notificaciones: {
                list: "Notificações"
            },
            plan_estudios: {
                plan_estudio_sesion: "Sessão de plano de estudo",
                plan_estudio_sesiones: "Sessões de plano de estudo",
                show: "Plano de estudo",
                list: "Planos de estudo"
            },
            plan_personales: {
                avance_tarea: "Progresso da tarefa",
                detalle_plan_alumno: "Detalhe do plano do aluno",
                diagnostico: "Diagnóstico",
                estadisticas_planes_personales: "Estatísticas",
                generar_plan_personal: "Gerar plano",
                historial_planes_compartidos: "Histórico de planos compartilhados",
                historial_planes_personales: "Histórico de planos",
                show: "Plano",
                plan_personal_profesor: "Plano",
                plan_personal_sesion: "Sessão",
                list: "Planos",
                planes_personales_asignaturas: "Planos",
                planes_personales_reforzamientos: "Reforços",
                realizar_plan_compartido: "Realizar plano"
            },
            plan_clases: {
                clase_plan_clase: "Classe",
                mis_plan_clases: "Meus planos de aula",
                plan_clase_alumno: "Plano",
                plan_clases: {
                    edit: "Editar plano de aula",
                    list: "Planos de aula",
                    new: "Criar plano de aula",
                    show: "Plano de aula"
                },
                plan_clases_alumnos: "Planos",
                plan_clases_de_alumnos: "Planos"
            },
            planificaciones: {
                estadisticas_clase: "Estatísticas",
                estadisticas_planificaciones: "Estatísticas",
                lista_planificaciones: "Planificações",
                plan_personal_contenidos: {
                    edit: "Editar conteúdo",
                    new: "Criar conteúdo"
                },
                planificaciones: {
                    compartida: "Planificação compartilhada",
                    edit: "Editar planificação",
                    estadisticas: "Estatísticas",
                    new: "Criar planificação",
                    predefinida: "Planificação predefinida",
                    show: "Planificação"
                },
                profesores_planificaciones_predefinidas: "Planificações predefinidas",
                profesores_revisar_planificaciones: "Revisar planificações",
                sesiones: {
                    edit: "Editar sessão",
                    new: "Criar sessão"
                }
            },
            streaming: {
                material_streaming: "Material de streaming",
                streaming_admin: "Streaming",
                streaming_alumnos: "Streaming de alunos",
                streaming: "Streaming"
            },
            typeform_widgets: {
                edit: "Editar widget do Typeform",
                list: "Widgets do Typeform",
                new: "Criar widget do Typeform",
                show: "Widget do Typeform",
                view: "Widget do Typeform"
            },
            universidad: {
                buscador_universidad: "Buscador de universidade",
                show: "Universidade"
            },
            usuarios: {
                passwords: {
                    forgot: "Recuperar senha",
                    reset: "Inserir nova senha"
                },
                password: "Esqueceu a sua senha?",
                recuperar_cuenta: "Recuperar conta",
                registro: {
                    completar_registro_social: "Completar registro",
                    completar_registro: "Completar registro",
                    incomplete_register: "Completar registro",
                    opciones_registro_nacimiento: "Registro: nascimento",
                    register: "Registro",
                    registro: "Registro"
                },
                request_confirmation: "Confirmação",
                usuario_registrado: "Usuário registrado",
                register_confirmation: "Confirmação",
                tutor_confirmar: "Confirmação",
                usuario_perfil_admin: "Perfil",
                usuario_perfil_alumno: "Perfil",
                usuario_perfil: "Perfil",
                usuarios: {
                    edit: "Editar usuário",
                    show: "Usuário"
                },
                completar_datos: "Completar dados",
                actualizar_datos: "Atualizar dados"
            }
        }
    },
    /* terminos & condiciones */
    tyc: {
        seccion0: {
            parrafo0: `
                                                                Os Termos e Condições gerais estabelecidos a seguir regulam o uso da comunidade de
                                                                <span class="entity-name">%{companyName}</span>
                                                                , criado através do site
                                                                <span class="entity-name">%{companySite}</span>
                                                                (en adelante
                                                                <span class="entity-name">"%{companyName}"</span>
                                                                ), que é disponibilizado para usuários da Internet.
                                                                <br />
                                                                <br />O uso exclusivo do portal dá-lhe o status de usuário (doravante, o "usuário") da comunidade
                                                                 do
                                                                <span class="entity-name">%{companyName}</span>
                                                         e expressa a aceitação total e sem reservas de todos e cada um de seus Termos e Condições que se encontram
                                                                 publicado ao acessar o portal
                                                                <span class="entity-name">%{companyName}</span>
                                                                . Consequentemente, o usuário deve ler atentamente os Termos e Condições em cada uma das ocasiões em
                                                                 quem pretende usar ou acessar o portal
                                                                <span class="entity-name">%{companyName}</span>
                                                                .
                                                                <br />
                                                                <br />
                                                         Os Termos e Condições podem ser modificados, total ou parcialmente, por
                                                                <span class="entity-name">%{companyName}</span>
                                                                ,e as referidas alterações entrarão em vigor a partir do momento em que forem publicadas ou inseridas no site.
                                                                 da comunidade ou desde que sejam notificados ao usuário por qualquer meio. Com base no acima, é sugerido
                                                                que você os visite periodicamente.
                                                                <br />
                                                                <br />
                                                                Violações dos Termos e Condições dão
                                                                <span class="entity-name">%{companyName}</span>
                                                                o direito de suspender ou rescindir a prestação do serviço ao usuário que o executou, por ação ou
                                                                 omissão.
                                                `
        },
        seccion1: {
            parrafo11: `
                                                                Os conteúdos que são publicados periodicamente na comunidade de
                                                                <span class="entity-name">%{companyName}</span>
                                                                Destinam-se a fornecer ao usuário todo tipo de informação relacionada ao desenvolvimento de sua educação
                                                                escolar e, fundamentalmente, o ensino médio, de acordo com o regulamento do
                                                                <strong>Ministério da Educação</strong>
                                                                . Por consequência,
                                                                <span class="entity-name">%{companyName}</span>
                                                                contribui para a realização satisfatória do Teste do Ensino Médio ENEM no mesmo país, por meio
                                                                da geração permanente de testes, relatórios estatísticos, material educacional, artigos, notícias,
                                                                 planos de estudo, publicações de interesse e outros conteúdos relacionados à comunidade escolar em
                                                                 todos os seus níveis.
                                                `,
            parrafo12: `
                                                         Em nenhum caso as informações contidas na comunidade
                                                                <span class="entity-name">%{companyName}</span>
                                                         tem a obrigação de satisfazer todas as necessidades formativas ou educacionais do usuário. Em
                                                                 algumas oportunidades, informações contidas na comunidade
                                                                <span class="entity-name">%{companyName}</span>
                                                         destina-se simplesmente a complementar outros materiais que o usuário pode acessar por conta própria,
                                                                 sobre um assunto ou disciplina de seu interesse.
                                                `,
            parrafo13: `
                                                                A prestação do serviço do site pela comunidade
                                                                <span class="entity-name">%{companyName}</span>
                                                                É livre e gratuito para os usuários. No entanto, seu uso só pode ser feito como um usuário
                                                                 registrado.
                                                `,
            subtitulo11: "1.1.",
            subtitulo12: "1.2.",
            subtitulo13: "1.3.",
            titulo1: "1 ° O serviço e informação"
        },
        seccion2: {
            parrafo21: `Para ser um usuário da comunidade, é obrigatório o preenchimento do formulário de inscrição ( "Registro de Usuário")
                                                com suas informações pessoais de forma precisa e verdadeira.`,
            parrafo22: `
                                                         Salvo nos campos em que o oposto é indicado, as respostas para as perguntas do "Registro de Usuário"
                                                                 são voluntárias, sem que a falta de resposta implique em diminuição da qualidade ou quantidade dos serviços
                                                                 exceto quando indicado. O usuário registrado concorda em notificar á
                                                                 comunidade de
                                                                <span class="entity-name">%{companyName}</span>
                                                                qualquer uso não autorizado de sua senha.
                                                `,
            parrafo23: `
                                                                <span class="entity-name">%{companyName}</span>
                                                                reserva-se o direito de rejeitar qualquer pedido de registro ou cancelar um registro anteriormente
                                                                 aceito.
                                                `,
            parrafo24: `
                                                                <span class="entity-name">%{companyName}</span>
                                                         reserva-se o direito de enviar para o e-mail indicado pelo utilizador no formulário de registo,
                                                                 informações que considera relevantes para seus usuários. Não obstante o anterior,


                                                                <span class="entity-name">%{companyName}</span>
                                                         adere a iniciativas contra spam
                                                                <i>(spamming)</i>
                                                                ,com o entendimento de que as informações enviadas para seus usuários não contém publicidade comercial e incorpora
                                                                 mecanismos simples e explícitos para deixar de receber tais e-mails.
                                                `,
            subtitulo21: "2.1.",
            subtitulo22: "2.2.",
            subtitulo23: "2.3.",
            subtitulo24: "2.4.",
            titulo2: "2° Registro"
        },
        seccion3: {
            parrafo31: `
                                                                Devido ao fato de que, neste momento os meios técnicos disponíveis não permitem a ser totalmente prevenida contra a ação
                                                                 de terceiros nos sites publicados na Internet,
                                                                <span class="entity-name">%{companyName}</span>
                                                de forma alguma garante a precisão e / ou veracidade de todas ou parte das informações contidas
                                                                 no site, nem sua atualização, nem que ditas informações tenham sido alteradas ou modificadas em qualquer grau,
                                                                 após a inclusão no site, ou qualquer outro aspecto ou característica das informações prestadas
                                                                 no site ou através dos links eventualmente incluídos nele.
                                                `,
            parrafo32: `
                                                                Em relação aos programas, materiais ou informações que foram disponibilizados ao usuário para que
                                                        ele possa baixá-los no seu computador, sistema ou qualquer meio de memória ou meio de sua propriedade ou de quem cuja
                                                                 posse ele possui ("materiais de


                                                                <i>download</i>
                                                                "),
                                                                <span class="entity-name">%{companyName}</span>
                                                         informa que inicialmente os referidos programas foram razoavelmente revisados de forma a considerá-los isentos de
                                                                 vírus ou outros meios tecnológicos destrutivos.
                                                `,
            parrafo33: `
                                                         Pelo mesmo,
                                                                <span class="entity-name">%{companyName}</span>
                                                                não será responsável por qualquer dano decorrente de qualquer alteração que tenha sido feita nos materiais
                                                                downloads fornecidos diretamente pela comunidade
                                                                <span class="entity-name">%{companyName}</span>
                                                `,
            subtitulo31: "3.1.",
            subtitulo32: "3.2.",
            subtitulo33: "3.3.",
            titulo3: "3°Responsabilidade pelas informações contidas"
        },
        seccion4: {
            parrafo41: `
                                                                A coleta e o processamento automatizado de dados pessoais têm como objetivo manter um relacionamento fluido
                                                         entre o usuário e a comunidade
                                                                <span class="entity-name">%{companyName}</span>
                                                         , favorecendo a gestão, administração, prestação, ampliação e melhoria dos serviços em que o
                                                                 o utilizador decide subscrever, bem como a utilização dos referidos serviços pelos utilizadores, a concepção de
                                                                 novas ofertas relacionadas com estes serviços, o envio de atualizações aos serviços, o envio, por
                                                                 meios tradicionais e eletrônicos de informação técnica, operacional e comercial sobre produtos e
                                                                 serviços oferecidos por
                                                                <span class="entity-name">%{companyName}</span>
                                                 através de seu portal.
                                                `,
            parrafo42: `
                                                         O serviço de
                                                                <span class="entity-name">%{companyName}</span>
                                                                coleta automaticamente certas informações gerais, como, por exemplo, o número e a frequência de visitantes
                                                                 na comunidade
                                                                <span class="entity-name">%{companyName}</span>
                                                                e suas diferentes áreas. Essas informações são usadas apenas para fins estatísticos. Estes dados
                                                                 ajuda a determinar quantos usuários da comunidade
                                                                <span class="entity-name">%{companyName}</span>
                                                         acessar uma determinada área e quais partes da comunidade eles usam, a fim de melhorá-la e garantir que seja
                                                                 tão atraente quanto possível.
                                                `,
            parrafo43: `
                                                         Se você decidir participar nas pesquisas realizadas no site,
                                                                <span class="entity-name">%{companyName}</span>
                                                         Você pode utilizar o seu CPF para acessar esses dados, que serão utilizados de forma anônima, de forma agregada,
                                                                 confidencial e apenas para fins de pesquisa de
                                                                <span class="entity-name">%{companyName}</span>
                                                         ou de qualquer entidade para a qual consideramos apropriada a transferência dessas informações. Da mesma forma, as respostas do
                                                                 pesquisa pode estar ligada a dados coletados pelo
                                                                <strong>Ministerio de Educación</strong>
                                                                .A decisão de participar das pesquisas é totalmente voluntária e não há riscos associados.
                                                                 Se você decidir não responder à pesquisa, não haverá consequências negativas.
                                                `,
            parrafo44: `Campanhas geradas por terceiros, você pode autorizar a utilização dos dados pessoais de quem participa delas.`,
            subtitulo41: "4.1.",
            subtitulo42: "4.2.",
            subtitulo43: "4.3.",
            subtitulo44: "4.4.",
            titulo4: "4° Privacidade de Informação"
        },
        seccion5: {
            parrafo51: `
                                                         O usuário autoriza
                                                                <span class="entity-name">%{companyName}</span>
                                                                utilizar todas as informações oficiais do ENEM (Notas nas diferentes provas, dados pessoais, etc.) com o
                                                                 objeto de pesquisa. Essas informações não serão tornadas públicas, mas os relatórios resultantes dessas
                                                                 pesquisas podem ser divulgadas e publicadas `,
            subtitulo51: "5.1.",
            titulo5: "5° Dados oficiais do ENEM"
        },
        seccion6: {
            parrafo61: `
                                                                <span class="entity-name">%{companyName}</span>
                                                                não transmitirá, comercializará ou publicará informações do usuário obtidas por meio
                                                                <i>Facebook</i>
                                                                .Essas informações são usadas internamente para autenticar o site por meio da conta de
                                                                <i>Facebook</i>
                                                                .Para o acima, apenas o usuário
                                                                <i>ID</i>
                                                                será salvo
                                                                <i>Facebook</i>
                                                                ,de forma de associá-lo à conta
                                                                <span class="entity-name">%{companyName}</span>
                                                                permitindo a autenticação do site.
                                                                <br />
                                                                <br />
                                                         Além disso, será utilizado, caso o usuário solicite, o
                                                                <i>ID</i>
                                                         publicar o resultado de um teste realizado na parede do usuário.
                                                `,
            subtitulo61: "6.1.",
            titulo6: "6° Privacidade no Facebook"
        },
        seccion7: {
            lista71: {
                item1: `Utilizar os conteúdos de forma diligente, correcta e legalmente.`,
                item2: `
                                                                                Não reproduza, copie, distribua, transforme ou modifique, disponibilize, alugue ou comunique o conteúdo ao
                                                                                público, a menos que tenha a autorização expressa do proprietário do correspondente
                                                                                 Direitos.


                                                                `,
                item3: `
                                                                         Não suprimir, evadir ou manipular os direitos autorais e outros dados de identificação dos direitos do
                                                                                <span class="entity-name">%{companyName}</span>
                                                                        ou de seus titulares incorporados aos conteúdos, bem como aos dispositivos técnicos de proteção, os
                                                                                 identificadores digitais ou quaisquer mecanismos de informação que possam conter o
                                                                                 gerenciamento de conteúdo ou direitos.


                                                                `,
                item4: `
                                                                         Não utilizar os conteúdos e, em particular, as informações de qualquer outra natureza obtidas através da
                                                                                 comunidade
                                                                                <span class="entity-name">%{companyName}</span>
                                                                                ou serviços, de veiculação de publicidade de qualquer espécie, em qualquer formato, no Brasil ou no
                                                                                 exterior.
                                                                `
            },
            parrafo71: `
                                                                "Conteúdo" será entendido como todas as informações, mensagens, gráficos, desenhos, designs, logotipos, nomes,
                                                                marcas, arquivos de som e / ou imagem, fotografias, gravações, softwares e, em geral, qualquer tipo de
                                                                 material acessível através da comunidade
                                                                <span class="entity-name">%{companyName}</span>
                                                         (Doravante referido como o "conteúdo").
                                                                <br />
                                                                <br />
                                                                O usuário concorda com:
                                                `,
            parrafo72: `
                                                                <span class="entity-name">%{companyName}</span>
                                                                não será responsável pelo uso indevido que os usuários façam do conteúdo da comunidade
                                                                <span class="entity-name">%{companyName}.</span>
                                                `,
            parrafo73: `
                                                         O usuário aceita que a publicidade de terceiros que aparece na tela seja uma parte inseparável da comunidade
                                                                <span class="entity-name">%{companyName}.</span>
                                                `,
            parrafo74: `
                                                                <span class="entity-name">%{companyName}</span>
                                                         não garante a veracidade da referida publicidade e não se responsabiliza por correspondências ou contratos
                                                                 que o usuário celebra com terceiros.
                                                `,
            subtitulo71: "7.1.",
            subtitulo72: "7.2.",
            subtitulo73: "7.3.",
            subtitulo74: "7.4.",
            titulo7: "7° Propriedade intelectual"
        },
        test: "Este é um texto de teste para %{companyName}",
        title: "Termos e Condições"
    },
    usuario: {
        actividades: {
            title: "Pontuação da Atividade",
            not_found: "Não há atividades para ganhar experiência no momento"
        },
        logros: {
            lista: {
                titulo: "Lista de conquistas"
            },
            resumen: {
                guia_niveles: "Guía de nives",
                prox_nivel: "Próximo nivel:",
                puntos: "Pontos",
                titulo: "Resumo de conquistas "
            },
            ultimos: {
                titulo: "Últimas conquistas"
            },
            logro: {
                experiencia: "Puntos de Experiencia"
            }
        },
        tabs: {
            favoritos: "Favoritos",
            logros: "Conquistas",
            perfil: "Perfil"
        }
    },
    usuario_edit_password: {
        cambiar_contrasena: "Alterar senha",
        confirmar_contrasena: "Confirme a nova senha",
        contrasena_actual: "Senha atual",
        minimo_caracteres: "A senha deve ter pelo menos {passwordMinLength} caracteres",
        no_coinciden: "A confirmação da senha não corresponde à senha",
        no_vacia: "A senha atual não pode estar vazia",
        no_valida: "A senha atual é inválida",
        nueva_contrasena: "Nova senha",
        vulnerable: "Esta senha é vulnerável. Experimente colocar outro."
    },
    usuarios_perfil_edit: {
        titulo: "Editar meus dados",
        aceptar: "Aceitar",
        apellido_materno: "Sobrenome",
        apellido_paterno: "Sobrenome",
        cambiar_contrasena: "Alterar senha",
        cambiar_imagen: "Alterar imagem",
        cancelar: "Cancelar",
        correo_electronico: "E-mail",
        deshacer: "Desfazer",
        editar: "Editar",
        fecha_actualizacion: "Data de atualização",
        fecha_creacion: "Data de Criação",
        foto_perfil: "Imagem de perfil",
        cambiar_foto_perfil: "Alterar imagem de perfil",
        guardar: "Salvar",
        informacion_personal: "Dados pessoais",
        nombre: "Nome",
        opciones: "Opções",
        no_definido: "Não definido",
        fecha_nacimiento: "Data de nascimento",
        establecimiento: "Estabelecimento",
        curso: "Turma",
        asignatura: "Disciplina",
        asignatura_definida: "Disciplina definida",
        eliminar_cuenta: "Excluir conta",
        tutorias: "Tutorias",
        seleccione: "Selecione",
        ayuda_asignatura: "Os menus serão abertos por padrão no assunto selecionado"
    },
    validations: {
        messages: {
            breachedPassword: "Esta senha é vulnerável. Tente outra.",
            breachedPasswordOnLogin:
                "Para aumentar a segurança dos seus dados, aumentámos os requisitos de segurança das palavras-passe, pelo que deve introduzir uma nova senha. Para o fazer, vá por favor para Esqueci minha senha? ou envie um email para contacto@ogr.cl",
            duplicateCC: "O CC já foi tomado. Se estiver correto, por favor contacte-nos.",
            duplicateCI: "O CPF já está registado. Se a sua identificação estiver correta, por favor contacte-nos.",
            duplicateEmail: "O endereço de e-mail já está registado.",
            duplicateNumeroUnico:
                "O número único já está registado. Se o seu código estiver correcto, por favor contacte-nos.",
            duplicateRut: "O CPF já está registado. Se o seu CPF estiver correcto, por favor contacte-nos.",
            duplicateTi: "A T.I. já foi tomada. Se estiver correcto, por favor contacte-nos.",
            equalEmails: "Os e-mails não correspondem",
            equalPasswords: "As senhas não correspondem",
            extensionNotSuported: "O arquivo só pode ser um  .pdf ou .pptx.",
            extensionNotSuported2: "O arquivo só pode ser um .xls ou .xlsx.",
            extensionNotSuported3: "O arquivo só pode ser um  .csv.",
            fileNotPresent: "Deve anexar um arquivo.",
            imageExtensionNotSuported: "O arquivo só pode ser .png ou .jpg.",
            invalidCC: "O número de CPF não é válido.",
            invalidPreguntaId: "Não há pergunta com esta identificação.",
            invalidString: "você deve inserir caracteres válidos sem números",
            invalidTelephoneCL: "O número de telefone está incorreto. Por favor, introduza pelo menos 9 dígitos.",
            invalidTelephoneCO: "O número de telefone está incorreto. Deve incluir o código da cidade.",
            invalidTelephoneEC: "O número de telefone está incorreto. Por favor introduza pelo menos 8 dígitos.",
            invalidTelephoneTec: "O número de telefone está incorreto, deve incluir o código da cidade.",
            invalidTelephoneUss: "O número de telefone está incorreto. Por favor introduza pelo menos 8 dígitos.",
            invalidTI: "O número do CPF não é válido.",
            max: "Precisa de ser inferior ou igual a %{max}",
            maxlength: "Precisa de ter, no máximo, %{requiredLength} caracteres",
            min: "Precisa de ser maior ou igual a {min}",
            minlength: "você deve inserir pelo menos %{requiredLength} caracteres",
            multipleOrRequired: "É necessário que pelo menos o campo %{fields} esteja presente.",
            notExistingEmail: "O endereço de e-mail não existe",
            notValidURI: "O URI é inválido.",
            required: "O campo %{required} é obrigatório",
            validateCI: "O CPF é inválido.",
            validateCPF: "Você deve inserir o CPF Sem pontos nem traço",
            validateEmail: "O endereço de e-mail é inválido",
            validateNem: "O NEM não é válido",
            validateRut: "O CPF é inválido. O CPF deve ser introduzido com um traço e sem pontos."
        }
    },
    ver: "Ver",
    widget_menu_reguntas: {
        preguntas: "perguntas"
    },
    widget_prueba_propias: {
        cerrar: "fechar",
        constestar: "Responder",
        editar: "Editar",
        respuest_a_pregunta: "Resposta á pergunta",
        respuestas: "Respostas"
    },
    widget_tiempo_ensayo: {
        tiempo_terminado: "Acabou o tempo!"
    }
}
