// Angular
import { CommonModule } from "@angular/common"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"
import { HttpClientModule } from "@angular/common/http"
import { RecaptchaModule } from "ng-recaptcha"
import { RecaptchaFormsModule } from "ng-recaptcha"
import { PopoverModule } from "ngx-bootstrap/popover"
import { TooltipModule } from "ngx-bootstrap/tooltip"

// libs
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

// ogr
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { UtilModule, FormModule, ModelViewModule, FlashMessageModule, PaginatorModule } from "@puntaje/shared/core"
import { CUIInputsModule, CUITextsModule, CUIContainersModule } from "@puntaje/shared/cui"
// confirmations
import { RegisterConfirmationComponent } from "./confirmations/register_confirmation.component"

// login
import { LoginComponent, LoginComponentTags } from "./login/login.component"
import { LoginBaseComponent } from "./login/login_base/login_base.component"
import { FacebookLinkComponent } from "./login/facebook_link.component"
import { SmallLoginComponent } from "./login/small_login.component"
import { LoginUSSComponent } from "./login/login_uss.component"
import { LoginPuntajeComponent } from "./login/login_puntaje.component"
import { LoginCBComponent } from "./login/login_cb.component"
import { LoginTuRetoComponent } from "./login/login_tureto.component"
import { LoginUtemComponent } from "./login/login_utem.component"
import { LoginMoralejaComponent } from "./login/login-moraleja/login_moraleja.component"
import { LoginPapyroComponent } from "./login/login-papyro/login_papyro.component"
import { LoginSantillanaComponent } from "./login/login-santillana/login-santillana.component"
import { LoginUAComponent } from "./login/login-ua/login-ua.component"
import { LoginGraduatePilotoComponent } from "./login/login-graduatepiloto/login-graduatepiloto.component"
import { LoginStandardComponent } from "./login/login-standard/login-standard.component"
import { LoginUSTComponent } from "./login/login_ust.component"

// passwords
import { EditPasswordComponent } from "./passwords/edit_password.component"
import { ForgotPasswordComponent } from "./passwords/forgot_password.component"
import { ResetPasswordComponent } from "./passwords/reset_password.component"
import { RequestResetPasswordComponent } from "./passwords/request-reset-password/request-reset-password.component"

// preferencia_carreras
import { EmbedPreferenciaCarrerasComponent } from "./preferencia_carreras/embed_preferencia_carreras.component"
import { EmbedPreferenciaCarrerasBySedeComponent } from "./preferencia_carreras/embed_preferencia_carreras_by_sede.component"
import { PreferenciaCarrerasShowComponent } from "./preferencia_carreras/preferencia_carreras_show.component"
import { EmbedPreferenciaCarreraSedesBySedeComponent } from "./preferencia_carrera_sedes/embed_preferencia_carrera_sedes_by_sede.component"

// preferencia_universidades
import { EmbedPreferenciaUniversidadesComponent } from "./preferencia_universidades/embed_preferencia_universidades.component"

// registrations
import { RegisterComponent } from "./registrations/register.component"
import { RegisterLiteComponent } from "./registrations/register_lite.component"
import { RegisterColombiaComponent } from "./registrations/register_colombia.component"
import { RegisterGraduateComponent } from "./registrations/register_graduate.component"
import { RegisterGuanajuatoComponent } from "./registrations/register-guanajuato/register_guanajuato.component"
import { RegisterIncompletoGraduateComponent } from "./registrations/register_incompleto_graduate.component"
import { RegisterIncompletoPuntajeColombiaComponent } from "./registrations/register_incompleto_puntaje_colombia.component"
import { RegisterIncompletoPuntajeComponent } from "./registrations/register_incompleto_puntaje.component"
import { RegisterCompletarPuntajeColombiaComponent } from "./registrations/register_completar_puntaje_colombia.component"
import { RegisterCompletarPuntajeComponent } from "./registrations/register_completar_puntaje.component"
import { RegisterCompletarPlaneaComponent } from "./registrations/register_completar_planea.component"
import { RegisterCompletarSocialPuntajeComponent } from "./registrations/register_completar_social_puntaje.component"
import { RegisterCompletarMexicoComponent } from "./registrations/register_completar_mexico.component"
import { RegisterCompletarGraduatePilotoComponent } from "./registrations/register_completar_graduatepiloto.component"
import { RegisterUMayorComponent } from "./registrations/register_umayor.component"
import { RegisterUssComponent } from "./registrations/register_uss.component"
import { RegisterUstComponent } from "./registrations/register_ust.component"
import { RegisterTecComponent } from "./registrations/register_tec.component"
import { RegisterCBComponent } from "./registrations/register_cb.component"
import { RegisterStepsComponent } from "./registrations/register-steps/register-steps.component"
import { RegisterStepsService } from "./registrations/register-steps/register-steps.service"
import { RegisterUtemComponent } from "./registrations/register_utem.component"
import { RegisterPnclComponent } from "./registrations/register_pncl.component"
import { RegisterUAComponent } from "./registrations/register_ua.component"
import { RegisterNivelateComponent } from "./registrations/register-nivelate/register-nivelate.component"
import { RegisterAprendolibreClComponent } from "./registrations/register-aprendolibre-cl/register-aprendolibre-cl.component"
import { RegisterCompletarGuanajuatoComponent } from "./registrations/register_completar_guanajuato.component"
import { RegisterCompletarAprendolibreMxComponent } from "./registrations/register_completar_aprendolibre_mx.component"
import { RegisterCompletarAprendolibreComponent } from "./registrations/register_completar_aprendolibre.component"
import { RegisterPREUUSSComponent } from "./registrations/register-preuuss/register-preuuss.component"

// crud
import { UsuarioComponent } from "./usuario.component"
import { UsuariosComponent } from "./usuarios.component"
import { UsuariosEditComponent } from "./usuarios.edit.component"
import { UsuariosPuntajeEditComponent } from "./usuarios_puntaje.edit.component"
import { UsuariosEditColombiaComponent } from "./usuarios.edit_colombia.component"
import { UsuariosEditMexicoComponent } from "./usuarios.edit_mexico.component"
import { UsuariosDeleteComponent } from "./usuarios.delete.component"
import { UsuariosNewComponent } from "./usuarios.new.component"
import { UsuariosUssEditComponent } from "./usuarios_uss.edit.component"
import { UsuariosCBEditComponent } from "./usuarios_cb.edit.component"

// extra
import { UsuarioAmigosComponent } from "./usuario_amigos.component"
import { UsuarioGruposComponent } from "./usuario_grupos.component"
import { UsuariosToolNavbarComponent } from "./usuarios.toolnavbar.component"
import { UsuarioShowcaseBoxComponent } from "./usuario_showcasebox.component"

// admin
import { CuentasAdminComponent } from "./cuentas_admin.component"
import { CuentasEditComponent } from "./cuentas_admin.edit.component"
import { CuentasNewComponent } from "./cuentas_admin.new.component"
import { CuentasDeshabComponent } from "./cuentas_deshabilitadas.component"
import { CuentasAdminToolNavbarComponent } from "./cuentas_admin.toolnavbar.component"
import { UsuariosBuscadorComponent } from "./usuarios_buscador.component"
import { UsuariosBuscadorService } from "./usuarios_buscador.service"
import { UsuarioAdminComponent } from "./usuario_admin.component"

import { TutoresModule } from "@puntaje/puntaje/new-modules/tutores"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { UsuarioRegistradoComponent } from "./registrations/usuario-registrado/usuario-registrado.component"
import { ModalSelectTipoEnvioComponent } from "./registrations/modales/modal-select-tipo-envio/modal-select-tipo-envio.component"
import { UsuarioBuscadorComponent } from "./usuario-buscador/usuario-buscador.component"
import { OpcionesUsuarioAdminComponent } from "./opciones-usuario-admin/opciones-usuario-admin.component"
import { RequestConfirmationComponent } from "./confirmations/request-confirmation/request-confirmation.component"
import { RegisterCompletarCbComponent } from "./registrations/register_completar_cb.component"
import { RegisterCompletarSocialPuntajeColombiaComponent } from "./registrations/register_completar_social_puntaje_colombia.component"
import { RecuperarCuentaComponent } from "./recuperar-cuenta/recuperar-cuenta.component"
import { AlumnoRecuperarCuentaComponent } from "./alumno-recuperar-cuenta/alumno-recuperar-cuenta.component"

import { ZXingScannerModule } from "@zxing/ngx-scanner"
import { CodigoRegistroComponent } from "./codigo-registro/codigo-registro.component"
import { UsuarioRegistroMoralejaComponent } from "./registrations/usuario-registro-moraleja/usuario-registro-moraleja.component"
import { BaseRegisterComponent } from "./registrations/base-register/base-register.component"
import { BaseRegisterStepsComponent } from "./registrations/base-register-steps/base-register-steps.component"
import { OpcionesRegistroNacimientoComponent } from "./registrations/opciones-registro-nacimiento/opciones-registro-nacimiento.component"
import { GoogleButtonComponent } from "./social_buttons/google-button/google-button.component"
import { FacebookButtonComponent } from "./social_buttons/facebook-button/facebook-button.component"
import { UsuarioCompletarRegistroSocialCbComponent } from "./registrations/usuario-completar-registro-social-cb/usuario-completar-registro-social-cb.component"
import { UsuarioPaaComponent } from "./registrations/usuario-paa/usuario-paa.component"
import { UsuarioPaaShowComponent } from "./usuario-paa-show/usuario-paa-show.component"
import { RegisterUahComponent } from "./registrations/register-uah/register-uah.component"
import { RegisterAprendolibreMxComponent } from "./registrations/register-aprendolibre-mx/register-aprendolibre-mx.component"
import { RegisterCompletarSocialAprendolibreMxComponent } from "./registrations/register-completar-social-aprendolibre-mx/register-completar-social-aprendolibre-mx.component"
import { RegisterCompletarSocialAprendolibreColombiaComponent } from "./registrations/register-completar-social-aprendolibre-colombia/register-completar-social-aprendolibre-colombia.component"
import { RegistroUsuarioBaseComponent } from "./registrations/registro-usuario-base/registro-usuario-base.component"
import { MisPreferenciaCarrerasComponent } from "./preferencia_carreras/mis-preferencia-carreras/mis-preferencia-carreras.component"
import { UsuariosCloneEditComponent } from "./usuarios-clone-edit/usuarios-clone-edit.component"
import { RegisterUandesComponent } from "./registrations/register-uandes/register-uandes.component"
import { RegisterUsachComponent } from "./registrations/register-usach/register-usach.component"
import { RegisterMexicoComponent } from "./registrations/register_mexico.component"
import { RegisterIncompletoMexicoComponent } from "./registrations/register_incompleto_mexico.component"
import { RegisterCompletarTuretocoComponent } from "./registrations/register_completar_turetoco.component"
import { RegisterUdlaComponent } from "./registrations/register-udla/register-udla.component"
import { RegisterCompletarPreuEstacionCentralComponent } from "./registrations/register_completar_preu_estacioncentral.component"
import { UsuariosEditPreuECComponent } from "./usuarios.edit_preuec.component"
import { RegisterAprendolivreBrComponent } from "./registrations/register-aprendolivre-br/register-aprendolivre-br.component"
import { LoginAprendoLivreComponent } from "./login/login-aprendo-livre/login-aprendo-livre.component"
import { RegisterCompletarClonesComponent, RegisterCompletarSocialAprendolibreBrasilComponent } from ".."
import { UsuarioEmailModalComponent } from "./usuario-email-modal/usuario-email-modal.component"
import { RegisterCompletarAprendolibreBrasilComponent } from "./registrations/register-completar-aprendolibre-brasil/register-completar-aprendolibre-brasil.component"
import { RegisterCompletarFundacoesComponent } from "./registrations/register-completar-fundacoes/register-completar-fundacoes.component"
import { RegisterCompletarSocialUahComponent } from "./registrations/register-completar-social-uah/register-completar-social-uah.component"
import { CompletarDatosComponent } from "./registrations/completar-datos.component"
import { CompletarDatosFormComponent } from "./registrations/completar-datos-form.component"
import { ActualizarDatosComponent } from "./registrations/actualizar-datos.component"
import { ContactoRegistroComponent } from "./registrations/contacto-registro/contacto-registro.component"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilModule,
        PaginatorModule,
        FormModule,
        ModelViewModule,
        CommonsLayoutsModule,
        Angular2FontawesomeModule,
        FlashMessageModule,
        RouterModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        HttpClientModule,
        TutoresModule,
        SharedModule,
        TooltipModule,
        PopoverModule,
        ZXingScannerModule,
        CUIInputsModule,
        CUITextsModule,
        CUIContainersModule
    ],
    declarations: [
        EmbedPreferenciaCarrerasComponent,
        EmbedPreferenciaCarrerasBySedeComponent,
        EmbedPreferenciaCarreraSedesBySedeComponent,
        EmbedPreferenciaUniversidadesComponent,
        LoginComponent,
        LoginComponentTags,
        FacebookLinkComponent,
        UsuariosComponent,
        UsuariosNewComponent,
        UsuariosEditComponent,
        UsuariosPuntajeEditComponent,
        UsuariosEditColombiaComponent,
        UsuariosEditMexicoComponent,
        UsuariosDeleteComponent,
        UsuariosToolNavbarComponent,
        RegisterComponent,
        RegisterLiteComponent,
        RegisterColombiaComponent,
        RegisterMexicoComponent,
        RegisterConfirmationComponent,
        UsuarioComponent,
        UsuarioAmigosComponent,
        UsuarioGruposComponent,
        EditPasswordComponent,
        UsuarioShowcaseBoxComponent,
        SmallLoginComponent,
        RegisterUMayorComponent,
        RegisterGraduateComponent,
        RegisterGuanajuatoComponent,
        RegisterIncompletoGraduateComponent,
        RegisterIncompletoPuntajeColombiaComponent,
        RegisterIncompletoMexicoComponent,
        RegisterIncompletoPuntajeComponent,
        RegisterCompletarPuntajeColombiaComponent,
        RegisterCompletarPuntajeComponent,
        RegisterCompletarCbComponent,
        RegisterCompletarSocialPuntajeComponent,
        RegisterCompletarSocialPuntajeColombiaComponent,
        RegisterCompletarGuanajuatoComponent,
        RegisterCompletarMexicoComponent,
        RegisterCompletarGraduatePilotoComponent,
        ResetPasswordComponent,
        ForgotPasswordComponent,
        RequestResetPasswordComponent,
        CuentasAdminComponent,
        CuentasEditComponent,
        CuentasNewComponent,
        CuentasDeshabComponent,
        CuentasAdminToolNavbarComponent,
        UsuariosBuscadorComponent,
        RegisterUssComponent,
        RegisterUstComponent,
        UsuariosUssEditComponent,
        LoginUSSComponent,
        LoginPuntajeComponent,
        RegisterTecComponent,
        RegisterCBComponent,
        PreferenciaCarrerasShowComponent,
        UsuarioAdminComponent,
        LoginCBComponent,
        PreferenciaCarrerasShowComponent,
        UsuarioRegistradoComponent,
        RegisterStepsComponent,
        ModalSelectTipoEnvioComponent,
        UsuarioBuscadorComponent,
        OpcionesUsuarioAdminComponent,
        LoginTuRetoComponent,
        RequestConfirmationComponent,
        RegisterUtemComponent,
        RegisterPnclComponent,
        RecuperarCuentaComponent,
        AlumnoRecuperarCuentaComponent,
        LoginUtemComponent,
        LoginMoralejaComponent,
        LoginPapyroComponent,
        UsuariosCBEditComponent,
        CodigoRegistroComponent,
        UsuarioRegistroMoralejaComponent,
        LoginSantillanaComponent,
        BaseRegisterComponent,
        OpcionesRegistroNacimientoComponent,
        GoogleButtonComponent,
        FacebookButtonComponent,
        UsuarioCompletarRegistroSocialCbComponent,
        UsuarioPaaComponent,
        UsuarioPaaShowComponent,
        RegisterCompletarPlaneaComponent,
        RegisterUahComponent,
        RegisterAprendolibreClComponent,
        BaseRegisterStepsComponent,
        RegisterAprendolibreMxComponent,
        RegisterCompletarSocialAprendolibreMxComponent,
        RegisterCompletarSocialAprendolibreColombiaComponent,
        LoginUAComponent,
        LoginGraduatePilotoComponent,
        RegistroUsuarioBaseComponent,
        RegisterUAComponent,
        LoginStandardComponent,
        LoginBaseComponent,
        MisPreferenciaCarrerasComponent,
        UsuariosCloneEditComponent,
        LoginUSTComponent,
        RegisterUandesComponent,
        RegisterUsachComponent,
        RegisterUdlaComponent,
        LoginUSTComponent,
        LoginBaseComponent,
        RegisterCompletarAprendolibreMxComponent,
        RegisterCompletarTuretocoComponent,
        RegisterCompletarAprendolibreComponent,
        RegisterCompletarPreuEstacionCentralComponent,
        UsuariosEditPreuECComponent,
        RegisterAprendolivreBrComponent,
        LoginAprendoLivreComponent,
        RegisterCompletarClonesComponent,
        RegisterCompletarAprendolibreBrasilComponent,
        RegisterCompletarFundacoesComponent,
        RegisterCompletarSocialAprendolibreBrasilComponent,
        RegisterCompletarSocialUahComponent,
        RegisterNivelateComponent,
        RegisterPREUUSSComponent,
        UsuarioEmailModalComponent,
        CompletarDatosComponent,
        CompletarDatosFormComponent,
        ActualizarDatosComponent,
        ContactoRegistroComponent
    ],
    exports: [
        EmbedPreferenciaCarrerasComponent,
        EmbedPreferenciaCarrerasBySedeComponent,
        EmbedPreferenciaCarreraSedesBySedeComponent,
        EmbedPreferenciaUniversidadesComponent,
        LoginComponent,
        LoginComponentTags,
        RegisterComponent,
        RegisterLiteComponent,
        RegisterColombiaComponent,
        RegisterMexicoComponent,
        RegisterConfirmationComponent,
        UsuariosEditComponent,
        UsuariosPuntajeEditComponent,
        UsuariosEditColombiaComponent,
        UsuariosEditMexicoComponent,
        UsuariosToolNavbarComponent,
        UsuarioComponent,
        UsuarioAmigosComponent,
        UsuarioGruposComponent,
        EditPasswordComponent,
        UsuarioShowcaseBoxComponent,
        SmallLoginComponent,
        RegisterUMayorComponent,
        RegisterGraduateComponent,
        RegisterGuanajuatoComponent,
        RegisterIncompletoGraduateComponent,
        RegisterIncompletoPuntajeColombiaComponent,
        RegisterIncompletoMexicoComponent,
        RegisterIncompletoPuntajeComponent,
        RegisterCompletarPuntajeColombiaComponent,
        RegisterCompletarPuntajeComponent,
        RegisterCompletarCbComponent,
        RegisterCompletarSocialPuntajeComponent,
        RegisterCompletarSocialPuntajeColombiaComponent,
        RegisterCompletarGuanajuatoComponent,
        RegisterCompletarPreuEstacionCentralComponent,
        RegisterCompletarMexicoComponent,
        RegisterCompletarGraduatePilotoComponent,
        ResetPasswordComponent,
        ForgotPasswordComponent,
        RequestResetPasswordComponent,
        CuentasAdminComponent,
        CuentasEditComponent,
        CuentasNewComponent,
        CuentasDeshabComponent,
        CuentasAdminToolNavbarComponent,
        UsuariosBuscadorComponent,
        RegisterUssComponent,
        RegisterUstComponent,
        UsuariosUssEditComponent,
        LoginUSSComponent,
        LoginPuntajeComponent,
        RegisterTecComponent,
        RegisterCBComponent,
        PreferenciaCarrerasShowComponent,
        UsuarioAdminComponent,
        LoginCBComponent,
        PreferenciaCarrerasShowComponent,
        UsuarioRegistradoComponent,
        RegisterStepsComponent,
        UsuarioBuscadorComponent,
        LoginTuRetoComponent,
        RequestConfirmationComponent,
        RegisterUtemComponent,
        RegisterPnclComponent,
        RecuperarCuentaComponent,
        AlumnoRecuperarCuentaComponent,
        LoginUtemComponent,
        LoginMoralejaComponent,
        LoginPapyroComponent,
        UsuariosCBEditComponent,
        CodigoRegistroComponent,
        UsuarioRegistroMoralejaComponent,
        LoginSantillanaComponent,
        OpcionesRegistroNacimientoComponent,
        UsuarioCompletarRegistroSocialCbComponent,
        UsuarioPaaComponent,
        UsuarioPaaShowComponent,
        RegisterCompletarPlaneaComponent,
        RegisterUahComponent,
        RegisterAprendolibreClComponent,
        BaseRegisterStepsComponent,
        RegisterAprendolibreMxComponent,
        RegisterCompletarSocialAprendolibreMxComponent,
        RegisterCompletarSocialAprendolibreColombiaComponent,
        LoginUAComponent,
        LoginGraduatePilotoComponent,
        RegistroUsuarioBaseComponent,
        RegisterUAComponent,
        LoginStandardComponent,
        LoginBaseComponent,
        UsuariosCloneEditComponent,
        LoginUSTComponent,
        RegisterUandesComponent,
        RegisterUsachComponent,
        RegisterUdlaComponent,
        LoginUSTComponent,
        LoginBaseComponent,
        RegisterCompletarAprendolibreMxComponent,
        RegisterCompletarTuretocoComponent,
        RegisterCompletarAprendolibreComponent,
        UsuariosEditPreuECComponent,
        RegisterAprendolivreBrComponent,
        LoginAprendoLivreComponent,
        RegisterCompletarClonesComponent,
        RegisterCompletarAprendolibreBrasilComponent,
        RegisterCompletarFundacoesComponent,
        RegisterCompletarSocialAprendolibreBrasilComponent,
        RegisterCompletarSocialUahComponent,
        RegisterNivelateComponent,
        RegisterPREUUSSComponent,
        CompletarDatosComponent,
        ActualizarDatosComponent,
        ContactoRegistroComponent
    ]
})
export class UsuariosModule {
    static forRoot(): ModuleWithProviders<UsuariosModule> {
        return {
            ngModule: UsuariosModule,
            providers: [UsuariosBuscadorService, RegisterStepsService]
        }
    }
}
