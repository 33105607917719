import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription, combineLatest } from "rxjs"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Store, select } from "@ngrx/store"
import { State, selectSelectedAsignatura, selectSelectedEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "materiales.component.html",
    styleUrls: ["materiales.component.scss"]
})
export class MaterialesComponent implements OnInit, OnDestroy {
    asignatura: Asignatura
    asignaturaId: number
    tipoInstrumento: string
    cursoId: number
    curso: any
    cursos: any[]
    private sub: Subscription
    enableResponsive: boolean = false
    @ViewChild("innerContent", { static: true }) loadingLayout: LoadingLayoutComponent
    titulo: string = "Material de apoyo"
    subData: Subscription
    rutaMaterial: string = "/materiales"
    openOnInit: boolean = true

    asignatura$ = this.store.pipe(
        select(selectSelectedAsignatura),
        filter(x => !!x)
    )

    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected store: Store<State>,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.materiales.list")
        this.titulo = config.app.name == "profesores" ? "Material de apoyo para Profesores" : "Material de apoyo"
        this.sub = combineLatest(this.asignatura$, this.evaluacionTipo$).subscribe(([asignatura, evaluacionTipo]) => {
            this.loadingLayout.standby()
            this.cursos = config.evaluaciones[evaluacionTipo].cursosMaterial

            if (
                asignatura.materialNiveles &&
                asignatura.materialNiveles[evaluacionTipo] &&
                asignatura.materialNiveles[evaluacionTipo].length > 0
            ) {
                this.cursos = this.cursos.filter(c =>
                    asignatura.materialNiveles[evaluacionTipo].find(n => c.clasificacion == n)
                )
            }

            if (this.cursos.length > 0) {
                this.curso = this.cursos[0].id
            }

            this.asignaturaId = null

            setTimeout(() => {
                this.asignatura = asignatura
                this.asignaturaId = asignatura.id
                this.tipoInstrumento = evaluacionTipo

                this.loadingLayout.ready()
            }, 50)
        })
        this.subData = this.route.data.subscribe(data => {
            this.rutaMaterial = data.rutaMaterial ?? this.rutaMaterial
            this.openOnInit = data.openOnInit ?? this.openOnInit
        })

        this.enableResponsive = window.innerWidth <= 900
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subData.unsubscribe()
    }

    onReady(asignatura) {
        this.asignatura = asignatura
    }

    onResize(event) {
        this.enableResponsive = event.target.innerWidth <= 900
    }

    onChangeAsignatura(asignaturaId) {
        this.loadingLayout.standby()
        this.asignaturaId = asignaturaId
        this.loadingLayout.ready()
    }

    onChangeCurso(curso) {
        this.loadingLayout.standby()
        // this.curso = curso
        this.cursoId = curso
        this.loadingLayout.ready()
    }
}
