import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignaturas } from "@puntaje/nebulosa/api-services"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"

@Component({
    templateUrl: "home_ensayos.component.html"
})
export class HomeEnsayosComponent extends MenuComponent {
    menuItems: MenuItemBoxData[] = []
    disableMenuItem: boolean = false

    constructor(protected store: Store<State>, protected loggedLayoutService: LoggedLayoutService) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    async setMenuItems() {
        const asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()

        this.menuItems = [
            {
                route: "/ensayos/generar_ensayo/" + asignaturasByEvaluacionTipo["paes"][0].id,
                params: { tipo_instrumento: "paes" },
                label: "Realizar Ensayos",
                text: "Practica realizando ensayos similares a las pruebas DEMRE.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },
            {
                route: "/ensayos/resultados/",
                params: { tipo_instrumento: "paes", asignatura_id: 1 },
                label: "Historial",
                text: "Encuentra aquí la lista de todos los ensayos que has realizado.",
                /* text: "Encuentra aquí la lista de todas las pruebas que has realizado.", */
                linkText: "Ver historial",
                icon: "guia"
            } /*,
  		{
			route: '/evaluaciones/compartidas/',
			params: {tipo_instrumento: 'ensayo', asignatura_id: 1},
			label: "Historial de ensayos compartidos",
			text: "Encuentra aquí la lista de todos los ensayos que te han compartido.",
			linkText: "Ver historial",
			icon: "guia",
			locked: this.disableMenuItem,
			lockedText: "Si tu colegio tiene contratado los servicios de "+this.config.companyName+", en esta sección encontrarás la lista de todos los ensayos que te han compartido tus profesores."
		},
		{
			route: '/ensayos/mi_progreso/',
			params: {tipo_instrumento: 'ensayo'},
			label: "Mi Progreso",
			text: "Observa la evolución en el tiempo de tus resultados obtenidos.",
			linkText: "Ver mi progreso",
			icon: "estadistica-o"
		},
		{
			route: '/ensayos/estadisticas/',
			params: {tipo_instrumento: 'ensayo'},
			label: "Estadísticas PSU",
			text: "Encuentra aquí estadísticas generadas a partir de tus resultados en ensayos PSU.",
			linkText: "Ver estadísticas",
			icon: "estadistica-o"
		} */
        ]
    }
}
