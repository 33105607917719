<!-- <nav class="navbar navbar-fixed clearfix">
	<div class="navbar-header">
		<div class="navbar-left navbar-logo-container">
			<div class="navbar-logo-container-inner">
				<a href="#section1" easyScrollTo [easyScrollToPadding]="0" [easyScrollToDuration]="800" [easyScrollToEasing]="'easeInOutExpo'">
					<img class="navbar-logo" src="{{config.app.assets.logoMediumLight}}" alt="{{config.plataforma.info.companyName}}">
				</a>
			</div>
		</div>
		<button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
			<span class="sr-only">Toggle navigation</span>
			<span class="icon-bar"></span>
			<span class="icon-bar"></span>
			<span class="icon-bar"></span>
		</button>
	</div>
	<div class="menu-right navbar-collapse collapse">
		<ul class="nav navbar-nav">
			<li>
            	<a href="#section1"  easyScrollTo [easyScrollToPadding]="0" [easyScrollToDuration]="800" [easyScrollToEasing]="'easeInOutExpo'">Inicia Sesión</a>
            </li>
            <li>
            	<a href="#section2"  easyScrollTo [easyScrollToPadding]="0" [easyScrollToDuration]="800" [easyScrollToEasing]="'easeInOutExpo'">¿Quiéres saber más?</a>
            </li>
            <li>
            	<a href="#section3"  easyScrollTo [easyScrollToPadding]="0" [easyScrollToDuration]="800" [easyScrollToEasing]="'easeInOutExpo'">Información para Docentes</a>
            </li>
            <li>
            	<a href="#section4"  easyScrollTo [easyScrollToPadding]="0" [easyScrollToDuration]="800" [easyScrollToEasing]="'easeInOutExpo'">¡Contáctanos!</a>
            </li>
		</ul>
	</div>
</nav> -->
<div class="contenedor" data-spy="scroll" data-target=".navbar-puntaje" (window:resize)="resizeElements()">
    <div class="mascara">
        <!-- Login -->
        <div [lazyLoad]="landingImage" [offset]="200" id="section1">
            <div class="caja caja-inicio">
                <div class="caja-inner-margin">
                    <!-- <div class="country-container">
						<a><span class="pais chile"></span>Chile</a>
						<a class="outside" href="http://www.puntajenacional.co/"><span class="pais colombia"></span>Colombia</a>
					</div> -->
                    <div class="row">
                        <div class="col-md-4 col-sm-offset-4">
                            <img alt="prelog/logo" src="{{ config.app.assets.logoLargeLight }}" class="landing-logo" />
                            <br />
                        </div>
                        <div class="col-md-12 col-sm-12">
                            <login-puntaje
                                [enableContactUs]="false"
                                [enableRegister]="true"
                                [enableFacebookLogin]="false"
                                [enablePasswordRecovery]="true"
                                [enableGoogleLogin]="false"
                            ></login-puntaje>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Alumnos
		<div class="caja seccion_alumnos" id="section2">
			<div class="caja-inner-margin">
				<h1 class="titulo-seccion">
					<ogr-icon name="libro2-o" class="icono"></ogr-icon>
					<span class="texto">¿Cómo funciona {{config.plataforma.info.companyName}} para <em>Estudiantes</em>?</span>
				</h1>
				<div class="info-item-col-wrap">
					<div class="info-item">
						<div class="image-container">
							<img src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/puntaje/landing/alumn_01.png" alt="Regístrate">
						</div>
						<div class="text-container">
							<p><em>Regístrate en {{config.plataforma.info.companyName}}</em><br>¡Es totalmente gratis!</p>
						</div>
					</div>
					<div class="info-item">
						<div class="image-container">
							<img src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/puntaje/landing/alumn_02.png" alt="Realiza ensayos">
						</div>
						<div class="text-container">
							<p><em>Realiza online todos los ensayos de prueba que quieras</em> de Matemáticas, Lenguaje, Historia, Ciencias, Biología, Física o Química.</p>
						</div>
					</div>
					<div class="info-item">
						<div class="image-container">
							<img src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/puntaje/landing/alumn_03.png" alt="Revisa tus resultados">
						</div>
						<div class="text-container">
							<p><em>Recibe inmediatamente tus resultados</em> y revisa tus errores y aciertos.</p>
						</div>
					</div>
					<div class="info-item">
						<div class="image-container">
							<img src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/puntaje/landing/alumn_04.png" alt="Estadísticas">
						</div>
						<div class="text-container">
							<p><em>Descubre tus fortalezas y debilidades</em> con estadísticas generadas a partir de tus resultados y optimiza tus estudios.</p>
						</div>
					</div>
					<div class="info-item">
						<div class="image-container">
							<img src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/puntaje/landing/alumn_05.png" alt="Materiales">
						</div>
						<div class="text-container">
							<p><em>Estudia con nuestro material y refuerza tus conocimientos.</em> Contamos con guías y videos, además de clases en vivo vía streaming.</p>
						</div>
					</div>
					<div class="info-item">
						<div class="image-container">
							<img src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/puntaje/landing/alumn_06.png" alt="Planes de estudio">
						</div>
						<div class="text-container">
							<p>¿No sabes por donde empezar? <em>Inscríbete a uno de nuestros planes de estudios.</em></p>
						</div>
					</div>
					<div class="info-item">
						<div class="image-container">
							<img src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/puntaje/landing/alumn_07.png" alt="Participa en el ranking">
						</div>
						<div class="text-container">
							<p><em>Participa en los ensayos mensuales</em> y súmate al ranking.</p>
						</div>
					</div>
					<div class="info-item">
						<div class="image-container">
							<img src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/puntaje/landing/alumn_08.png" alt="Sube tu Puntaje">
						</div>
						<div class="text-container">
							<p>¡Estudia, ensaya, practica y <em>sube tu Puntaje</em>!</p>
						</div>
					</div>
				</div>
			</div>
		</div>-->

        <!-- Profesores
		<div class="caja seccion_profesores" id="section3">
			<div class="caja-inner-margin extra-bottom-margin">
				<h1 class="titulo-seccion">
					<ogr-icon name="establecimiento-o" class="icono"></ogr-icon>
					<span class="texto">¿Cómo funciona {{config.plataforma.info.companyName}} para <em>Docentes</em>?</span>
				</h1>
				<div class="info-item-col-wrap">
					<div class="info-item">
						<span class="info-number">1</span>
						<div class="info-img">
							<img src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/puntaje/landing/docen_01.png" alt="Contratar los servicios">
						</div>
						<p>Los colegios que contratan los servicios de <em>{{config.plataforma.info.companyName}}</em> podrán trabajar a través de nuestra plataforma de manera ilimitada, con la base de datos de sus estudiantes de 1<sup>ro</sup> a 4<sup>to</sup> medio y los profesores de Lenguaje, Matemática, Historia y Ciencias (Biologia, Química, Física).</p>
						<p>Si desea conocer los productos y tarifas, puede contactarnos mandando un correo a <a href="mailto:ventas@ogr.cl">ventas@ogr.cl</a> o a través de nuestra <em>mensajería en la sección de "Contáctanos!".</em></p>
					</div>
					<div class="info-item">
						<span class="info-number">2</span>
						<div class="info-img">
							<img src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/puntaje/landing/docen_02.png" alt="Comparte evaluaciones personalizadas">
						</div>
						<p>Los docentes podrán <em>compartir con sus estudiantes Ensayos PSU o Pruebas Curriculares</em> personalizadas a través de nuestra base de datos de preguntas, permitiéndoles editar los contenidos a evaluar, los tiempos en responder, y la cantidad de preguntas.</p>
					</div>
					<div class="info-item">
						<span class="info-number">3</span>
						<div class="info-img">
							<img src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/puntaje/landing/docen_03.png" alt="Evaluaciones presenciales">
						</div>
						<p>Nuestras evaluaciones también pueden ser realizadas de manera presencial: <em>todas las evaluaciones pueden ser impresas por el colegio</em>.</p>
						<p>Además, a través de nuestras hojas de respuesta, podrán cargar los resultados de sus estudiantes directamente a la plataforma utilizando una fotografía y siguiendo simples pasos.</p>
					</div>
					<div class="info-item">
						<span class="info-number">4</span>
						<div class="info-img">
							<img src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/puntaje/landing/docen_04.png" alt="Estadísticas e informes semestrales">
						</div>
						<p>Monitorea los resultados de tus estudiantes a través de diferentes estadísticas, que te ayuden a identificar fortalezas y debilidades. Usa la información y genera estrategias de mejora.</p>
						<p>Los colegios también reciben <em>informes semestrales</em> con las estadísticas de sus estudiantes, y contarán con un <em>monitor especialista en {{config.plataforma.info.companyName}}</em> que prestará apoyo y soporte en cuanto al uso de la plataforma.</p>
					</div>
				</div>
			</div>
		</div>-->

        <!-- Contacto -->
        <div class="caja seccion_contacto" id="section4">
            <div class="row">
                <div class="col-md-4 col-xs-12 company-logo clearfix">
                    <a href="http://ogr.cl/">
                        <img
                            class="footer-logo"
                            src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/logo_ogr_light.png"
                            alt="{{ config.plataforma.info.companyName }}"
                        />
                    </a>
                    <p>Todos los Derechos Reservados a "Open Green Road S.A." ®</p>
                </div>
                <div class="col-md-4 col-xs-12"></div>
                <div class="col-md-4 col-xs-12 contacto">
                    <p>Si no puedes acceder, escríbenos a contactouah@ogr.cl</p>
                </div>
            </div>
            <!-- <div class="caja-inner-margin">
				 <h1 class="titulo-seccion">
					<fa name="envelope-o" class="icono"></fa>
					<span class="texto">¡Contáctanos!</span>
				</h1>
				<div class="contact-wrapper">
					<div class="row">
						<div class="col-md-8">
							<div class="big-contact-container">
								<h3>Envía tu mensaje</h3>
								<contact-form></contact-form>
							</div>
						</div> -->
            <!-- <div class="col-md-4">
							<div class="contact-container">
								<div class="speech-pos-1">
									<div class="speech-bubble-1">
										<p>Encuéntranos también en</p>
										<div class="socialMedia">
											<div class="t_row">
												<div class="t_cell">
													<a href="{{config.plataforma.info.social.facebook}}" target="_blank" title="Facebook"><fa name="facebook"></fa></a>
												</div>
												<div class="t_cell">
													<a href="{{config.plataforma.info.social.twitter}}" target="_blank" title="Twitter"><fa name="twitter"></fa></a>
												</div>
												<div class="t_cell">
													<a href="{{config.plataforma.info.social.youtube}}" target="_blank" title="Youtube"><fa name="youtube"></fa></a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="speech-pos-2">
									<div class="speech-bubble-2">
										<p>Para conocer productos y tarifas:</p>
										<p class="email"><em>ventas@ogr.cl</em></p>
										<fa name="envelope-o" class="icono"></fa>
									</div>
								</div>
								<div class="speech-pos-2">
									<div class="speech-bubble-2 docente">
										<p>E-mail de Monitores para ayuda a Profesores:</p>
										<p class="email"><em>{{config.plataforma.info.soporte.mail}}</em></p>
										<ogr-icon name="docente" class="icono"></ogr-icon>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> -->
        </div>
    </div>
</div>
<simple-modal></simple-modal>
