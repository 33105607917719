import { EntrenadorUsuariosEditComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

import { ProGuard } from "@puntaje/puntaje/services"

export const routes: Routes = [
    //{path: "dudas/new", component: DudaNewComponent, canActivate: [TokenGuard, AuthGuard]},
    {
        path: "entrenador/edit",
        component: LoggedLayoutComponent,
        children: [
            { path: "", component: EntrenadorUsuariosEditComponent, canActivate: [TokenGuard, AuthGuard, ProGuard] }
        ]
    }
]

export const routing = RouterModule.forChild(routes)
