<cui-logged-layout [freeContentStyle]="freeContentStyle" [noTitleBar]="noTitlebar">
    <cui-layout-header>
        <navbar></navbar>
        <topmenu></topmenu>
    </cui-layout-header>
    <cui-layout-footer>
        <footer-component></footer-component>
        <popup-cookies></popup-cookies>
        <typeform-widgets-view></typeform-widgets-view>
    </cui-layout-footer>
</cui-logged-layout>
