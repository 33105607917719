<ng-container *ngIf="!saved">
    <form novalidate [formGroup]="form">
        <div class="row">
            <div class="col-md-6">
                <h3>Datos Personales</h3>
                <form-input
                    [form]="form"
                    [params]="params['nombre']"
                    [(value)]="usuarioRegistro['nombre']"
                    [key]="'nombre'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['apellido_paterno']"
                    [(value)]="usuarioRegistro['apellido_paterno']"
                    [key]="'apellido_paterno'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['apellido_materno']"
                    [(value)]="usuarioRegistro['apellido_materno']"
                    [key]="'apellido_materno'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['rut']"
                    [(value)]="usuarioRegistro['rut']"
                    [key]="'rut'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['email']"
                    [(value)]="usuarioRegistro['email']"
                    [key]="'email'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['password']"
                    [enablePasswordBar]="true"
                    [(value)]="usuarioRegistro['password']"
                    [key]="'password'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['password_confirmation']"
                    [(value)]="usuarioRegistro['password_confirmation']"
                    [key]="'password_confirmation'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['telefono']"
                    [(value)]="usuarioRegistro['telefono']"
                    [key]="'telefono'"
                ></form-input>
            </div>
            <div class="col-md-6">
                <h3>Datos Académicos</h3>

                <form-input
                    [form]="form"
                    [params]="params['nivel_id']"
                    [(value)]="usuarioRegistro['nivel_id']"
                    [key]="'nivel_id'"
                ></form-input>

                <!-- Descomentar si se necesita usar la api de carreras
                <embed-preferencia-carrera-sedes-by-sede
                    #preferencias
                    [universidadesNombres]="universidades"
                    [form]="form"
                    [amount]="1"
                    (emitPreferencias)="updatePreferencias($event)"
                ></embed-preferencia-carrera-sedes-by-sede> -->
                <embed-preferencia-carrera
                    [amount]="1"
                    [form]="form"
                    (emit_preferencias)="updatePreferencias($event)"
                ></embed-preferencia-carrera>

                <form-input
                    [form]="form"
                    [params]="params['terminos_y_condiciones']"
                    [(value)]="usuarioRegistro['terminos_y_condiciones']"
                    [key]="'terminos_y_condiciones'"
                ></form-input>

                <button (click)="save()" type="button" class="btn btn-default btn-block submit-btn">Regístrate</button>
                <button (click)="back()" type="button" class="btn btn-default btn-block cancel-btn">
                    Volver a la página de Inicio
                </button>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="saved">
    Hemos enviado un e-mail a tu correo electrónico con instrucciones para validar tu cuenta. Si no te llega en 5
    minutos más, por favor revisa tu carpeta de spam.
</ng-container>
