import { PuntajePlanClasesModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"

import { PlanEstudiosModule } from "@puntaje/puntaje/new-modules/plan-estudios"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"

//components

import { routing } from "./alumnos_plan_estudios.routing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AlumnosLayoutsModule,
        PlanEstudiosModule,
        routing,
        BannersModule,
        PuntajePlanClasesModule
    ],
    exports: [],
    declarations: [],
    providers: []
})
export class AlumnosPlanEstudiosModule {}
