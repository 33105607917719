import { AppEnvironment } from "@puntaje/shared/core"
import { urls } from "../config/urls.prod"

export const environment: AppEnvironment = {
    production: true,
    endpoints: {
        base: urls.baseUrl,
        nebulosa: urls.nebuUrl,
        achievements: urls.achievementsUrl
    },
    facebook: {
        appId: "1104415359622082",
        apiVersion: "v2.6"
    },

    domain: {
        profesores: "docentes.uah.ogr.cl",
        alumnos: "ensayopsu.uahurtado.cl",
        admin: "admin.uah.ogr.cl"
    },
    googleTagManagerPropertyId: {
        profesores: "GTM-538288R",
        alumnos: "GTM-538288R"
    },
    globalSiteTag: {
        alumnos: { propertyId: "G-FJQDS9V7L5", sendTo: "" }
    }
}
