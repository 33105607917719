<div class="contenido">
    <loading-layout #loadingLayout>
        <div
            *ngIf="
                evaluacionInstancia &&
                evaluacionInstancia.evaluacion &&
                evaluacionInstancia.evaluacion.instrumento?.calificacion_manual &&
                perfil == 'profesor'
            "
            class="contentPrincipalCalificacion"
        >
            <escala-global-aliases-instancia
                [evaluacionInstancia]="evaluacionInstancia"
            ></escala-global-aliases-instancia>
            <calificacion class="calificacion" [evaluacionInstancia]="evaluacionInstancia"></calificacion>
        </div>
        <div *ngIf="evaluacionInstancia">
            <retroalimentacion-individual
                [retroalimentable]="{ id: evaluacionInstancia.id, type: 'EvaluacionInstancia' }"
                [receptor]="{ id: evaluacionInstancia.usuario_id, type: 'Usuario' }"
                [perfil]="perfil"
            ></retroalimentacion-individual>
            <br />
        </div>
        <div
            class="ensayo"
            *ngIf="
                evaluacionInstancia &&
                evaluacionInstancia.evaluacion &&
                !evaluacionInstancia.evaluacion.instrumento.propio &&
                evaluacionInstancia.respuestas
            "
        >
            <div>
                <cui-section-headline>{{ "progreso.historial.respuestas.titulo" | t }}</cui-section-headline>
            </div>

            <cui-tabs justifyContent="flex-start" [usingDisplayNone]="false" [tabMaxWidth]="140">
                <cui-tab
                    title="{{ 'progreso.historial.respuestas.resultados' | t }}"
                    ariaLabelledBy="{{ 'progreso.historial.respuestas.resultados' | t }}"
                    (select)="selectTab('todas')"
                >
                    <ng-container *ngTemplateOutlet="results"></ng-container>
                </cui-tab>
                <cui-tab
                    title="{{ 'progreso.historial.respuestas.incorrectas' | t }}"
                    ariaLabelledBy="{{ 'progreso.historial.respuestas.incorrectas' | t }}"
                    (select)="selectTab('incorrectas')"
                >
                    <ng-container *ngTemplateOutlet="results"></ng-container>
                </cui-tab>
                <cui-tab
                    title="{{ 'progreso.historial.respuestas.omitidas' | t }}"
                    ariaLabelledBy="{{ 'progreso.historial.respuestas.omitidas' | t }}"
                    (select)="selectTab('omitidas')"
                >
                    <ng-container *ngTemplateOutlet="results"></ng-container>
                </cui-tab>
                <cui-tab
                    title="{{ 'progreso.historial.respuestas.correctas' | t }}"
                    ariaLabelledBy="{{ 'progreso.historial.respuestas.correctas' | t }}"
                    (select)="selectTab('correctas')"
                >
                    <ng-container *ngTemplateOutlet="results"></ng-container>
                </cui-tab>
                <cui-tab
                    *ngIf="
                        enableDesempeno &&
                        config.evaluaciones[evaluacionInstancia.evaluacion.evaluacion_tipo.evaluacion_tipo] &&
                        config.evaluaciones[evaluacionInstancia.evaluacion.evaluacion_tipo.evaluacion_tipo]
                            ?.clasificaciones
                    "
                    title="{{ 'progreso.historial.respuestas.desempeno' | t }}"
                >
                    <cui-section-headline>
                        {{ "progreso.historial.respuestas.desempeno_por" | t }} {{ desempenoLabel }}
                    </cui-section-headline>
                    <div class="desempeno-container">
                        <ng-container *ngFor="let desempenoClasificacion of desempenoClasificaciones">
                            <div class="desempeno">
                                <div class="container-bar">
                                    <div class="nombre-clasificacion">{{ desempenoClasificacion.clasificacion }}</div>
                                    <div class="progress-bar-clasificacion">
                                        <progressbar
                                            class="p-bar"
                                            [animate]="true"
                                            [value]="desempenoClasificacion.desempeno"
                                        ></progressbar>
                                    </div>
                                    <div *ngIf="desempenoClasificacion.clasificacionesHijas.length > 0" class="ver-mas">
                                        <a
                                            href="javascript:void(0)"
                                            toggleExpandable="hijos-clasificacion-{{
                                                desempenoClasificacion.clasificacion
                                            }}"
                                        >
                                            {{ "progreso.historial.respuestas.detalle_por_subeje" | t }}&nbsp;
                                            <fa *ngIf="hijosClasificacion.expanded" name="minus"></fa>
                                            <fa *ngIf="!hijosClasificacion.expanded" name="plus"></fa>
                                        </a>
                                    </div>
                                </div>

                                <div class="correctas-total">
                                    {{ desempenoClasificacion.correctas }} / {{ desempenoClasificacion.total }}
                                </div>
                            </div>

                            <div
                                [hidden]="desempenoClasificacion.clasificacionesHijas.length == 0"
                                id="hijos-clasificacion-{{ desempenoClasificacion.clasificacion }}"
                                class="desempeno-sub-container"
                                #hijosClasificacion="expandableContent"
                                [expandableContent]
                            >
                                <div
                                    class="desempeno-sub"
                                    *ngFor="
                                        let desempenoSubClasificacion of desempenoClasificacion.clasificacionesHijas
                                    "
                                >
                                    <div class="container-bar-sub">
                                        <div class="nombre-clasificacion-sub">
                                            {{ desempenoSubClasificacion.clasificacion }}
                                        </div>
                                        <div class="progress-bar-sub">
                                            <progressbar
                                                class="p-bar"
                                                [animate]="true"
                                                [value]="desempenoSubClasificacion.desempeno"
                                            ></progressbar>
                                        </div>
                                    </div>
                                    <div class="correctas-total-sub">
                                        {{ desempenoSubClasificacion.correctas }} /
                                        {{ desempenoSubClasificacion.total }}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </cui-tab>
            </cui-tabs>
        </div>
        <div
            class="results-not-yet"
            *ngIf="evaluacionInstancia && evaluacionInstancia.evaluacion && !evaluacionInstancia.respuestas"
        >
            <div class="results-not-yet-inner">
                <div class="results-not-yet-icon">
                    <span class="glyphicon glyphicon-time" aria-hidden="true"></span>
                </div>
                <div class="results-not-yet-text">
                    {{ "progreso.historial.respuestas.resultados_disponibles_en" | t }}
                    {{ evaluacionInstancia.evaluacion.fecha_mostrar_respuestas | date: "dd/MM/yyyy HH:mm" }}
                </div>
            </div>
        </div>

        <div
            class="container-padre"
            *ngIf="
                evaluacionInstancia &&
                evaluacionInstancia.respuestas &&
                evaluacionInstancia.evaluacion &&
                evaluacionInstancia.evaluacion.instrumento.propio &&
                evaluacionInstancia.respuestas &&
                material
            "
        >
            <div class="container-respuestas">
                <cui-section-headline>{{ "progreso.historial.respuestas.titulo" | t }}</cui-section-headline>
                <p class="piloto-text-descripcion" *ngIf="showDescripcion">
                    {{ "progreso.historial.respuestas.preguntas_piloto" | t }}
                </p>
                <span *ngIf="updatingRespuesta" class="updating-respuesta">
                    <loader [loaderType]="'default-xsmall'"></loader>
                    {{ "progreso.historial.respuestas.updating" | t }}
                </span>
                <div class="respuestas-letras">
                    <div class="respuestas-letras-container">
                        <div class="respuestas-letras-table">
                            <div class="letras">
                                <div class="empty"></div>
                                <div class="letra" *ngFor="let letra of letrasAlternativas">{{ letra }}</div>
                                <div class="empty"></div>
                            </div>

                            <ng-container *ngFor="let pregunta of preguntas; let i = index">
                                <respuesta-ensayo
                                    class="alternativas"
                                    [pregunta]="pregunta"
                                    [tiempo]="evaluacionInstancia.respuestas[i].tiempo"
                                    [respuestas]="respuestasByPreguntaId[pregunta.id]"
                                    [respuestaId]="evaluacionInstancia.respuestas[i].alternativa_id"
                                    [puntuacion]="
                                        evaluacionInstancia.evaluacion.instrumento.instrumento_preguntas[i].puntuacion
                                    "
                                    [enableDuda]="evaluacionInstancia.respuestas[i].duda_marcada"
                                    [index]="i"
                                    [showClasificaciones]="showClasificaciones"
                                    [enableCrearDuda]="enableDudas"
                                    [isPiloto]="instrumentoPreguntas[i].piloto"
                                    [evaluacionTipo]="evaluacionInstancia.evaluacion.evaluacion_tipo.evaluacion_tipo"
                                    [generadorInstrumento]="
                                        evaluacionInstancia.evaluacion.instrumento.generador_instrumento
                                    "
                                    [onlyLetras]="true"
                                    [numeroAlternativas]="letrasAlternativas.length"
                                    [disableEdit]="updatingRespuesta"
                                    (respuestaCorrectaUpdating)="onRespuestaCorrectaUpdating()"
                                    (respuestaCorrectaChanged)="onRespuestaCorrectaChanged()"
                                ></respuesta-ensayo>
                            </ng-container>
                        </div>
                    </div>
                    <cui-section-headline style="margin-top: 15px">
                        Puntaje total: {{ puntuacion }} pts.
                    </cui-section-headline>
                </div>
            </div>
            <div class="container-contenido">
                <model-plain-value [params]="fileParams" [value]="material" key="url"></model-plain-value>
            </div>
        </div>
    </loading-layout>
</div>

<ng-template #results>
    <loading-layout #secondLoadingLayout *ngIf="!showPreguntas"></loading-layout>
    <div class="preguntas" [class.show-preguntas]="showPreguntas">
        <div *ngIf="selectedTab && selectedTab != 'todas' && evaluacionInstancia[selectedTab] == 0">
            {{ "progreso.historial.respuestas.no_tuviste_preguntas" | t }} {{ selectedTab }}
        </div>
        <div
            role="region"
            [attr.aria-label]="pregunta?.grupo_pregunta ? 'pregunta ' + (i + 1) : null"
            class="prueba-pregunta pregunta_font"
            *ngFor="let pregunta of preguntas; let i = index"
        >
            <respuesta-ensayo
                [showOnly]="selectedTab"
                [pregunta]="pregunta"
                [evaluacionId]="evaluacionInstancia.evaluacion.id"
                [respuestas]="respuestasByPreguntaId[pregunta.id]"
                [index]="i"
                [showClasificaciones]="showClasificaciones"
                [enableCrearDuda]="enableDudas"
                [enableReporte]="enableReporte"
                [isPiloto]="instrumentoPreguntas[i].piloto"
                [grupoPreguntaPrev]="preguntas[i - 1]?.grupo_pregunta"
                [evaluacionTipo]="evaluacionInstancia.evaluacion.evaluacion_tipo.evaluacion_tipo"
                [numeroAlternativas]="letrasAlternativas.length"
                [generadorInstrumento]="evaluacionInstancia.evaluacion.instrumento.generador_instrumento"
            ></respuesta-ensayo>
        </div>
    </div>
</ng-template>
