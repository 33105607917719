import { Component, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, OnChanges } from "@angular/core"
import { GeneradorInstrumento } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "numero-preguntas-tiempo",
    templateUrl: "numero_preguntas_tiempo.component.html",
    styleUrls: ["numero_preguntas_tiempo.component.scss"]
})
export class NumeroPreguntasTiempoComponent implements OnChanges {
    @Input() numeroPreguntas
    @Output() numeroPreguntasChange: EventEmitter<any> = new EventEmitter<any>()

    @Input() tiempo
    @Output() tiempoChange: EventEmitter<any> = new EventEmitter<any>()

    @Input() disabled: boolean = false

    omitOnChanges: boolean = false
    tabIndex: any = "-1"

    tiempoInput: number
    numeroPreguntasInput: number
    currentOption: number
    show_warning: boolean = false
    show_error_preguntas: boolean = false
    show_error_tiempo: boolean = false
    config = config

    valoresOpciones = [
        { preguntas: 80, tiempo: 160 },
        { preguntas: 40, tiempo: 80 },
        { preguntas: 20, tiempo: 40 }
    ]

    @Input() tipoEvaluacion: string

    private _generadorInstrumento: GeneradorInstrumento

    @Input()
    get generadorInstrumento(): GeneradorInstrumento {
        return this._generadorInstrumento
    }

    set generadorInstrumento(gi: GeneradorInstrumento) {
        this._generadorInstrumento = gi

        if (gi) {
            this.valoresOpciones = this.valoresOpciones.map((o, i) => {
                let pow = Math.pow(2, i)
                let preguntas = ~~(this.generadorInstrumento.numero_preguntas / pow)
                let tiempo = ~~(this.generadorInstrumento.tiempo / pow)

                return { preguntas: preguntas, tiempo: tiempo }
            })

            setTimeout(() => {
                this.numeroPreguntas = this.tiempo = null
                this.inputChanges()
            }, 2)
        }
    }

    constructor(protected cdr: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes["tipoEvaluacion"] && changes["tipoEvaluacion"].isFirstChange()) {
            this.setSelectValues()
            this.inputChanges()
        }
        if (!this.omitOnChanges) {
            this.inputChanges()
        }

        this.omitOnChanges = false
    }

    inputChanges() {
        let oldOption = this.currentOption
        if (this.numeroPreguntas || this.tiempo) {
            this.valoresOpciones.forEach((opt, i) => {
                if (opt.preguntas == this.numeroPreguntas && opt.tiempo == this.tiempo) {
                    this.currentOption = i
                }
            })

            if (!this.isInteger(this.currentOption)) {
                this.currentOption = this.valoresOpciones.length
                this.tiempoInput = this.tiempo
                this.numeroPreguntasInput = this.numeroPreguntas
            }
        } else {
            setTimeout(() => {
                this.currentOption = oldOption || 0
                if (!this.cdr["destroyed"]) this.cdr.detectChanges()
                this.numeroPreguntasChange.emit(this.valoresOpciones[this.currentOption].preguntas)
                this.tiempoChange.emit(this.valoresOpciones[this.currentOption].tiempo)
            }, 1)
        }
    }

    setSelectValues() {
        switch (this.tipoEvaluacion) {
            case "simce":
                this.valoresOpciones = [
                    { preguntas: 35, tiempo: 90 },
                    { preguntas: 25, tiempo: 65 },
                    { preguntas: 15, tiempo: 40 }
                ]
                break
            case "curricular":
                if (this.config.plataforma.name == "Aprendolibre") {
                    this.valoresOpciones = [
                        { preguntas: 25, tiempo: 50 },
                        { preguntas: 12, tiempo: 25 },
                        { preguntas: 6, tiempo: 12 }
                    ]
                } else {
                    this.valoresOpciones = [
                        { preguntas: 35, tiempo: 90 },
                        { preguntas: 25, tiempo: 65 },
                        { preguntas: 15, tiempo: 40 }
                    ]
                }
                break
            case "curricular colombia":
                this.valoresOpciones = [
                    { preguntas: 30, tiempo: 60 },
                    { preguntas: 15, tiempo: 30 },
                    { preguntas: 7, tiempo: 15 }
                ]
                break
            case "curricular mexico":
                this.valoresOpciones = [
                    { preguntas: 20, tiempo: 40 },
                    { preguntas: 10, tiempo: 20 },
                    { preguntas: 5, tiempo: 10 }
                ]
                break
            default:
                this.valoresOpciones = [
                    { preguntas: 80, tiempo: 160 },
                    { preguntas: 40, tiempo: 80 },
                    { preguntas: 20, tiempo: 40 }
                ]
                break
        }
    }

    tiempoInputChange() {
        this.tiempoChange.emit(parseInt(this.tiempoInput as any))
        this.omitOnChanges = true
    }

    nPreguntasInputChange() {
        this.numeroPreguntasChange.emit(parseInt(this.numeroPreguntasInput as any))
        this.omitOnChanges = true
    }

    optionsChange() {
        if (this.currentOption == this.valoresOpciones.length) {
            this.tabIndex = "0"
            this.show_warning = true
            this.tiempoChange.emit(0)
            this.numeroPreguntasChange.emit(0)
        } else {
            this.tabIndex = "-1"
            this.tiempoChange.emit(this.valoresOpciones[this.currentOption].tiempo)
            this.numeroPreguntasChange.emit(this.valoresOpciones[this.currentOption].preguntas)
            this.show_warning = false
        }

        this.omitOnChanges = true
    }

    selectLastOption() {
        this.currentOption = this.valoresOpciones.length
        this.show_warning = true
    }

    validateInputPreguntas() {
        this.show_error_preguntas = !this.isInteger(this.numeroPreguntasInput)
    }

    validateInputTiempo() {
        this.show_error_tiempo = !this.isInteger(this.tiempoInput)
    }

    showNumeroPreguntasError() {
        return (
            this.currentOption == this.valoresOpciones.length &&
            this.isInteger(this.numeroPreguntasInput) &&
            this.numeroPreguntasInput > this.generadorInstrumento.numero_preguntas * 2
        )
    }

    showErrorMessage() {
        return (
            this.currentOption == this.valoresOpciones.length && (this.show_error_preguntas || this.show_error_tiempo)
        )
    }

    isInteger(value) {
        return !isNaN(value) && !isNaN(parseInt(value))
    }
}
