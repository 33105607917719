<cui-showcasebox>
    <cui-showcasebox-title-icon>
        <ogr-icon name="editar-prueba" class="icon"></ogr-icon>
    </cui-showcasebox-title-icon>
    <cui-showcasebox-title-text>{{ "estudiar.showcasebox.plan_estudios.titulo" | t }}</cui-showcasebox-title-text>
    <cui-showcasebox-content>
        <loading-layout #loadingLayout>
            <div>
                <ng-container *ngIf="!enablePlanesPersonales">
                    <ng-container *ngTemplateOutlet="planes"></ng-container>
                </ng-container>
                <cui-tabs *ngIf="enablePlanesPersonales" justifyContent="center" [headerLess]="tabsHeaderLess">
                    <cui-tab [title]="titlePlanesDeEstudio" ariaLabelledBy="planes de estudio">
                        <ng-container *ngTemplateOutlet="planes"></ng-container>
                    </cui-tab>
                    <cui-tab
                        *ngIf="!hidePlanesAdaptive"
                        [title]="titlePlanesAdaptativos"
                        ariaLabelledBy="planes personales"
                    >
                        <ul class="plan-personal-list">
                            <li *ngFor="let a of lista_asignaturas" class="{{ a.clase }}">
                                <div class="icon_container">
                                    <ogr-icon class="icono" name="libro2-o"></ogr-icon>
                                </div>
                                <a [routerLink]="['/planes_personales']" [queryParams]="{ asignatura_id: a.id }">
                                    {{ a.asignatura }}
                                </a>

                                <cui-button-link
                                    [routerLinkValue]="['/planes_personales']"
                                    [queryParamsValue]="{ asignatura_id: a.id }"
                                    size="small"
                                >
                                    Estudiar&nbsp;
                                    <fa name="arrow-right"></fa>
                                </cui-button-link>
                            </li>
                        </ul>
                    </cui-tab>
                </cui-tabs>
            </div>
        </loading-layout>
    </cui-showcasebox-content>
</cui-showcasebox>
<ng-template #planes>
    <div class="planes_showcase">
        <div>
            <ng-container *ngFor="let plan of planesPersonales">
                <plan-clase-box
                    [planificacion]="plan"
                    [hideProgress]="false"
                    [verSinCompartir]="verSinCompartir"
                    [plan_clase_route]="custom_path"
                ></plan-clase-box>
            </ng-container>
        </div>
    </div>
</ng-template>
