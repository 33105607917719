import { Component, OnInit, Input, SimpleChanges, ViewChild } from "@angular/core"
import {
    PlanPersonales,
    PlanPersonal,
    PlanClaseService,
    GrupoUsuarios,
    GrupoUsuario
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Store, select } from "@ngrx/store"
import { Subscription, BehaviorSubject, combineLatest } from "rxjs"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { GeneradorInstrumentos, Clasificacion } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "plan-clase-alumno-new",
    templateUrl: "./plan-clase-alumno-new.component.html",
    styleUrls: ["./plan-clase-alumno-new.component.scss"]
})
export class PlanClaseAlumnoNewComponent implements OnInit {
    @Input() planPersonalId: number
    grupoUsuario: GrupoUsuario
    @Input() vistaPrevia = false
    @Input() baseRoute = "/plan_clases"
    @ViewChild(LoadingLayoutComponent, { static: true }) loadingLayoutComponent: LoadingLayoutComponent
    disableNivelCalugas: boolean = config.plataforma?.disableNivelCalugas || false
    clasificacionContextoByMaterialId: any
    clasificacionActividadByMaterialId: any
    planPersonalSesiones = []

    sub: Subscription
    asignatura: any
    nivel: Clasificacion
    _planPersonal: PlanPersonal
    planPersonalSubject = new BehaviorSubject<PlanPersonal>(null)
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    @Input()
    set planPersonal(planificacion: any) {
        this._planPersonal = planificacion
        if (planificacion) {
            this.planPersonalSesiones = planificacion.plan_personal_ciclos[0]?.plan_personal_sesiones.sort(
                (a, b) => a.orden - b.orden
            )
        }

        this.planPersonalSubject.next(planificacion)
    }

    get planPersonal() {
        return this._planPersonal
    }

    constructor(
        protected planPersonalesService: PlanPersonales,
        protected grupoUsuariosService: GrupoUsuarios,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected planClaseService: PlanClaseService,
        private store: Store<State>
    ) {}

    ngOnInit() {
        this.sub = combineLatest(
            this.planPersonalSubject.pipe(filter(x => !!x)),
            this.asignaturasByEvaluacionTipo$
        ).subscribe(([planificacion, asignaturasByEvaluacionTipo]) => {
            const lista_asignaturas = asignaturasByEvaluacionTipo[config.plataforma.evaluacionDefault]
            this.asignatura = lista_asignaturas.find(
                l => l.asignatura == this.planPersonal.generador_instrumento.asignatura.asignatura
            )
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (((changes["planPersonalId"] || changes["grupoUsuario"]) && this.planPersonalId) || this.grupoUsuario) {
            this.setGrupoUsuarios()
        }
    }

    onSelectGrupoUsuario(grupoUsuario: GrupoUsuario) {
        this.grupoUsuario = grupoUsuario
        this.getData()
    }

    indexImpar(number) {
        return number !== 0 && number % 2 !== 0
    }

    async setGrupoUsuarios() {
        const planPersonal = await this.planPersonalesService.find(this.planPersonalId)
        const generadorInstrumento = await this.generadorInstrumentosService.find(
            planPersonal.generador_instrumento_id,
            { include: "generador_instrumento_categorias:categoria:clasificacion_tipo" }
        )

        let clasificacionTipoNivel: string
        const configEval = config.evaluaciones[generadorInstrumento.tipo_instrumento.tipo_instrumento]
        if (configEval && configEval.cursoTipo) {
            clasificacionTipoNivel =
                config.evaluaciones[generadorInstrumento.tipo_instrumento.tipo_instrumento].cursoTipo
        } else {
            clasificacionTipoNivel = config.plataforma.clasificacionTipoNivel
        }

        const guParams = { propios: 1 }

        if (clasificacionTipoNivel) {
            this.nivel = generadorInstrumento.generador_instrumento_categorias
                .map(gic => gic.categoria)
                .find(c => c.clasificacion_tipo.clasificacion_tipo == clasificacionTipoNivel)

            if (this.nivel) {
                guParams["grupo_usuario"] = { curso_id: this.nivel.id }
            }
        }

        const grupoUsuarios = await this.grupoUsuariosService.where(guParams)

        if (grupoUsuarios.length > 0) {
            this.onSelectGrupoUsuario(grupoUsuarios[0])
        } else {
            this.getData()
        }
    }

    async getData() {
        this.loadingLayoutComponent.standby()
        await this.planClaseService.getData(this.planPersonalId, this.grupoUsuario ? this.grupoUsuario.id : null)
        this.planPersonal = this.planClaseService.planPersonal
        this.clasificacionContextoByMaterialId = this.planClaseService.clasificacionContextoByMaterialId
        this.clasificacionActividadByMaterialId = this.planClaseService.clasificacionActividadByMaterialId
        this.loadingLayoutComponent.ready()
    }
}
