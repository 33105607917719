import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnInit, ChangeDetectorRef } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { trigger, state, style, animate, transition, keyframes } from "@angular/animations"
import { PlanPersonales, PlanPersonal } from "@puntaje/puntaje/api-services"
import {
    PlanEstudio,
    PlanEstudios,
    GeneradorInstrumento,
    AsignaturaWithConfig,
    GeneradorInstrumentos
} from "@puntaje/nebulosa/api-services"
import { Router } from "@angular/router"
import { AuthService, I18nService } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

import { select, Store } from "@ngrx/store"
import {
    selectAsignaturasByEvaluacionTipo,
    State,
    selectSelectedAsignatura,
    selectSelectedEvaluacionTipo
} from "@puntaje/puntaje/store"
import { filter, first } from "rxjs/operators"

@Component({
    selector: "planes-estudios-showcasebox",
    templateUrl: "planes_estudios_showcasebox.component.html",
    styleUrls: ["planes_estudios_showcasebox.component.scss"],

    animations: [
        trigger("fadeIn", [
            state("initial", style({ opacity: 0 })),
            state("in", style({ opacity: 1 })),
            transition("initial => in", [
                animate(
                    "0.5s 500ms ease-in",
                    keyframes([style({ opacity: 0, offset: 0 }), style({ opacity: 1, offset: 1.0 })])
                )
            ])
        ])
    ]
})
export class PlanesEstudiosShowcaseBoxComponent implements OnInit {
    planesPersonales: PlanPersonal[]
    addBtnState: string = "initial"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() maxColumns: number = 2
    columnClasses: string = "col-md-6"
    hideProgress: boolean = false
    @Input() maximoPlanes: number = 3
    @Input() enablePlanesPersonales: boolean = false
    @Input() tabsHeaderLess: boolean = true
    @Input() lista_asignaturas: AsignaturaWithConfig[]
    @Input() tipoEvaluacionPlanesPersonales: string
    @Input() custom_path: string = config.plataforma.planClasesRoute ?? "/plan_estudios"
    evaluacionTipoDefault: string = config.plataforma.evaluacionDefault
    titlePlanesAdaptativos = this.translatorService.translate(
        "estudiar.showcasebox.plan_estudios.planesAdactativosTitulo",
        {}
    )
    titlePlanesDeEstudio = this.translatorService.translate(
        "estudiar.showcasebox.plan_estudios.planesDeEstudioTitulo",
        {}
    )
    verSinCompartir: boolean
    hidePlanesAdaptive: boolean = config.planesEstudio?.hidePlanesAdaptive || false

    constructor(
        private authService: AuthService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        protected planPersonalesService: PlanPersonales,
        protected store: Store<State>,
        protected translatorService: I18nService,
        protected generadorInstrumentoService: GeneradorInstrumentos
    ) {}

    async ngOnInit() {
        this.verSinCompartir = false

        const generador_ids = await this.getGeneradores()

        this.load(1, this.maximoPlanes, generador_ids)
    }

    async getGeneradores() {
        const params = {
            tipo_instrumento: {
                tipo_instrumento: this.evaluacionTipoDefault
            }
        }
        const generadores = await this.generadorInstrumentoService.where(params)

        return generadores.map(g => g.id) || []
    }

    irPlanes() {
        this.router.navigate(["/planes_estudio"])
    }

    load(page: number, per: number, generador_ids?: number[]) {
        this.loadingLayout.standby()
        this.planesPersonales = null
        const params = {
            page: page,
            per: per,
            sort_by: "created_at",
            order: "desc",
            with_estrellas: 1,
            predefinidos: 1,
            plan_personal_tipo: { tipo: ["plan de clase", "plan de clase alumno"] },
            render_options: {
                include: {
                    usuario: null,
                    generador_instrumento: null
                },
                methods: ["total_sesiones"]
            }
        }

        if (generador_ids.length > 0) params["generador_instrumento_ids"] = generador_ids

        if (this.enablePlanesPersonales) this.setPlanesPersonales()
        this.cdr.detectChanges()
        this.planPersonalesService.wherePost(params).then((planes: PlanPersonal[]) => {
            this.planesPersonales = planes
            this.addBtnState = "in"
            this.setColumns()
            this.loadingLayout.ready()
        })
    }

    setColumns() {
        let indexToSplit = 0
        switch (this.maxColumns) {
            case 1:
                this.columnClasses = "col-md-12"
                break

            case 2:
                this.columnClasses = "col-md-6"
                break

            default:
                this.columnClasses = "col-md-6"
                break
        }
    }

    handleDisabled(value: boolean) {
        this.load(1, this.maximoPlanes)
    }

    async setPlanesPersonales() {
        const asignaturasByEvaluacionTipo = await this.store
            .pipe(
                select(selectAsignaturasByEvaluacionTipo),
                filter(x => !!x),
                first()
            )
            .toPromise()

        this.lista_asignaturas =
            asignaturasByEvaluacionTipo[this.tipoEvaluacionPlanesPersonales || this.evaluacionTipoDefault] || []
    }
}
