import { StreamingAlumnosComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

import { HomeStreamingComponent } from "./home_streaming.component"

export const routes: Routes = [
    {
        path: "streaming",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeStreamingComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "streaming/saber11",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: StreamingAlumnosComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
    //{ path: 'streaming/curricular', component: StreamingAlumnosComponent, canActivate: [TokenGuard, AuthGuard] }
]

export const routing = RouterModule.forChild(routes)
