<loading-layout #loadingLayout>
    <div class="duda-box clearfix">
        <div class="user-col">
            <duda-avatar *ngIf="duda" [usuario]="duda.usuario" [usuarioValores]="duda.usuario_valores"></duda-avatar>
        </div>
        <div class="content-col">
            <div class="pull-right">
                <div *ngIf="duda">
                    <single-value-sentiment [object]="duda"></single-value-sentiment>
                </div>
            </div>
            <p class="thread-info">
                <b>{{ "dudas.duda.por" | t }}:</b>
                &nbsp;
                <a class="thread-author" routerLink="/usuarios/{{ duda?.usuario_id }}">
                    {{ duda?.usuario.nombreCompleto() }}
                </a>
                &nbsp;
                <span class="thread-time">{{ duda?.created_at | fechaAtras }}</span>
                &nbsp;
                <span *ngIf="isEdited && !duda?.ban" class="thread-time">
                    (editado {{ duda?.updated_at | fechaAtras }})
                </span>
            </p>
            <duda-item #item [class.has-item]="duda?.item_type"></duda-item>
            <div
                *ngIf="!duda?.ban"
                [innerHTML]="duda?.detalle | easyplaceholder: '' | katex | trustedhtml"
                class="texto-plano"
            ></div>
            <div *ngIf="duda?.ban">
                <span class="ban">{{ "dudas.duda.eliminada" | t }}</span>
            </div>
        </div>
        <div class="bottom-options-push"></div>
        <div class="clearfix bottom-options">
            <ul class="bottom-options-list">
                <li *ngIf="isAdmin && !duda?.ban">
                    <cui-button (click)="banHammer()" buttonType="danger">
                        <span>{{ "dudas.duda.ban" | t }}&nbsp;</span>
                        <fa name="gavel"></fa>
                    </cui-button>
                </li>
                <li class="separator" *ngIf="isAdmin && !duda?.ban"></li>
                <li *ngIf="canEdit">
                    <a class="edit-link" routerLink="/dudas/{{ dudaId }}/edit">
                        {{ "dudas.duda.editar" | t }}
                        <fa name="pencil"></fa>
                    </a>
                </li>
                <li class="separator" *ngIf="canEdit"></li>
                <li>
                    <cui-button (click)="compartirFacebook()" size="small" social="facebook">
                        {{ "dudas.duda.compartir" | t }}
                        <fa name="facebook-f" class="right-icon"></fa>
                    </cui-button>
                </li>
                <li class="last-item">
                    <cui-button (click)="respuestaEnabled = true" size="small">
                        {{ "dudas.duda.responder" | t }}
                        <fa name="reply" class="right-icon"></fa>
                    </cui-button>
                </li>
            </ul>
        </div>
    </div>

    <duda-respuesta-new
        *ngIf="respuestaEnabled"
        (save)="onSaveDudaRespuesta()"
        [dudaId]="duda?.id"
        (cancelReply)="cancelarResponder()"
    ></duda-respuesta-new>

    <duda-respuestas
        *ngIf="duda"
        [duda]="duda"
        [enableAcceptAnswer]="enableAcceptAnswer"
        (updateDuda)="aceptarRespuesta()"
    ></duda-respuestas>
</loading-layout>
