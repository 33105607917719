import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute } from "@angular/router"
import { Duda } from "@puntaje/puntaje/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "duda.component.html"
})
export class DudaComponent implements OnInit, OnDestroy {
    id: number
    private sub: Subscription
    duda: Duda

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected loggedLayoutService: LoggedLayoutService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.dudas.dudas.list")
        this.sub = this.route.params.subscribe(params => {
            this.id = +params["id"]
        })

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    onReady(duda: Duda) {
        this.duda = duda
    }
}
