import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import {
    EvaluacionInstancias,
    EvaluacionInstancia,
    InstrumentoPregunta,
    Estadisticas,
    Respuesta,
    InstrumentoPreguntas
} from "@puntaje/puntaje/api-services"
import {
    Preguntas,
    Pregunta,
    Materiales,
    Material,
    Clasificaciones,
    Alternativas
} from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { PdfView, DocsView } from "@puntaje/shared/core"
import { AppConfig, I18nService } from "@puntaje/shared/core"
declare const config: AppConfig
import { Store } from "@ngrx/store"
import { State, GetItems } from "@puntaje/puntaje/store"
import { EvaluacionInstanciaEscalasService } from "./evaluacion_instancia_escalas.service"

@Component({
    selector: "respuestas-ensayo",
    templateUrl: "respuestas_ensayo.component.html",
    styleUrls: ["respuestas_ensayo.component.scss"]
})
export class RespuestasEnsayoComponent implements OnInit {
    @Input() evaluacionInstanciaId: number
    @Input() enableDesempeno: boolean = true
    @Input() disableTabs: boolean = false
    @Input() perfil: string = "alumno"
    preguntas: Pregunta[]
    evaluacionInstancia: EvaluacionInstancia
    instrumentoPreguntas: InstrumentoPregunta[]
    mostrarSolucion = false
    loading = true
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("secondLoadingLayout") secondLoadingLayout: LoadingLayoutComponent
    showPreguntas: boolean = false

    @Output() onEvaluacionInstanciaReady: EventEmitter<any> = new EventEmitter<any>()
    @Input() showClasificaciones: boolean = false
    @Input() withCurso: boolean = false
    @Input() enableDudas: boolean = true
    @Input() enableReporte: boolean = true
    @Input() resultadosAlumno: boolean = false
    @Output() puntuacion = 0
    letrasAlternativas: string[]
    desempenoClasificaciones: any[]
    config: typeof config = config
    showDescripcion: boolean = false
    updatingRespuesta: boolean = false

    fileParams: any = { label: "Previsualizar", type: PdfView }
    material: Material

    tabs: any = {
        todas: true,
        correctas: false,
        incorrectas: false,
        omitidas: false,
        desempeno: false
    }

    selectedTab: string = "todas"

    respuestasByPreguntaId: any

    desempenoLabel: string = "eje temático"

    constructor(
        protected route: ActivatedRoute,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected estadisticasService: Estadisticas,
        protected preguntasService: Preguntas,
        protected clasificacionesService: Clasificaciones,
        protected materialesService: Materiales,
        protected store: Store<State>,
        protected evaluacionInstanciaEscalasService: EvaluacionInstanciaEscalasService,
        protected i18nService: I18nService,
        protected alternativasService: Alternativas
    ) {}

    ngOnInit() {
        let eiParams = {}

        if (this.withCurso) eiParams["with_grupo_usuario"] = 1
        if (this.resultadosAlumno) eiParams["resultadosAlumno"] = this.resultadosAlumno

        this.evaluacionInstanciasService.find(this.evaluacionInstanciaId, eiParams).then((ei: EvaluacionInstancia) => {
            this.evaluacionInstancia = ei

            this.onEvaluacionInstanciaReady.emit(ei)
            // this.evaluacionInstanciaEscalasService.evaluacionInstancia.next(this.evaluacionInstancia)

            if (!ei.respuestas) {
                this.loadingLayout.ready()

                return
            }

            this.respuestasByPreguntaId = this.evaluacionInstancia.respuestas.reduce((acc, respuesta) => {
                acc[respuesta.pregunta_id] = acc[respuesta.pregunta_id] || []
                acc[respuesta.pregunta_id].push(respuesta)

                return acc
            }, {})

            this.store.dispatch(new GetItems({ evaluacion: this.evaluacionInstancia.evaluacion }))

            const letras = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
            let numeroAlternativas = 4
            if (this.evaluacionInstancia.evaluacion.instrumento.numero_alternativas) {
                numeroAlternativas = this.evaluacionInstancia.evaluacion.instrumento.numero_alternativas
            } else if (this.evaluacionInstancia.evaluacion.instrumento.generador_instrumento.maximo_alternativas) {
                numeroAlternativas =
                    this.evaluacionInstancia.evaluacion.instrumento.generador_instrumento.maximo_alternativas
            }

            this.letrasAlternativas = new Array(numeroAlternativas).fill("").map((_, i) => letras[i])

            this.instrumentoPreguntas = this.evaluacionInstancia.evaluacion.instrumento.instrumento_preguntas
            this.instrumentoPreguntas.forEach(ip => {
                if (ip.piloto == true) {
                    this.showDescripcion = true
                }
            })

            for (let i = 0; i < this.instrumentoPreguntas.length; i++) {
                this.puntuacion =
                    this.puntuacion +
                    this.evaluacionInstancia.evaluacion.instrumento.instrumento_preguntas[i].puntuacion
            }
            const preguntaIds = this.instrumentoPreguntas.map(ip => ip.pregunta_id)
            const query_params = this.showClasificaciones
                ? {
                      per: preguntaIds.length,
                      pregunta: { id: preguntaIds },
                      with_clasificaciones: 1,
                      with_clasificaciones_with_clasificacion_tipo: 1,
                      with_grupo_pregunta: 1
                  }
                : {
                      per: preguntaIds.length,
                      pregunta: { id: preguntaIds },
                      with_grupo_pregunta: 1
                  }
            this.preguntasService
                .whereWithAlternativasLibres(query_params, this.evaluacionInstancia.respuestas)
                .then(async (preguntas: Pregunta[]) => {
                    this.preguntas = preguntas

                    if (ei.evaluacion.instrumento.propio) {
                        this.materialesService
                            .find(ei.evaluacion.instrumento.instrumento_material.material_id)
                            .then(material => {
                                if (material.getExtension() == "docx" || material.getExtension() == "doc")
                                    this.fileParams = {
                                        label: this.i18nService.translate("respuestas_ensayo.previsualizar"),
                                        type: DocsView
                                    }
                                this.material = material

                                this.loadingLayout.ready()
                                this.loading = false

                                if (this.secondLoadingLayout) {
                                    this.secondLoadingLayout.ready()
                                }

                                this.showPreguntas = true
                            })
                    } else {
                        this.loadingLayout.ready()
                        this.loading = false

                        if (this.secondLoadingLayout) {
                            this.secondLoadingLayout.ready()
                        }

                        this.showPreguntas = true
                    }
                })

            const estParams = {
                collection: "EstadisticaInstanciaClasificacion",
                estadistica: {
                    evaluacion_instancia_id: ei.id
                }
            }

            this.estadisticasService.where(estParams).then(async estadisticas => {
                const estadistica = estadisticas[0]

                if (
                    config.evaluaciones[ei.evaluacion.evaluacion_tipo.evaluacion_tipo] &&
                    config.evaluaciones[ei.evaluacion.evaluacion_tipo.evaluacion_tipo].clasificaciones
                        .desempenoEvaluacionLabel
                ) {
                    this.desempenoLabel =
                        config.evaluaciones[
                            ei.evaluacion.evaluacion_tipo.evaluacion_tipo
                        ].clasificaciones.desempenoEvaluacionLabel
                }

                estadistica.estadistica_clasificaciones = estadistica.estadistica_clasificaciones || []

                const clasificacionesParams = {
                    generador_instrumento_filtro_clasificacion: {
                        generador_instrumento_id:
                            this.evaluacionInstancia.evaluacion.instrumento.generador_instrumento_id
                    },
                    group_by: [
                        config.evaluaciones[ei.evaluacion.evaluacion_tipo.evaluacion_tipo].clasificaciones
                            .desempenoEvaluacion
                    ]
                }

                const clasificaciones = await this.clasificacionesService.where(clasificacionesParams)

                this.desempenoClasificaciones = clasificaciones
                    .filter(clasificacion =>
                        estadistica.estadistica_clasificaciones.find(ec => ec.clasificacion_id == clasificacion.id)
                    )
                    .map(clasificacion => {
                        clasificacion.clasificaciones_hijas = clasificacion.clasificaciones_hijas || []

                        return {
                            ...this.getEstadisticasClasificacion(clasificacion, estadistica),
                            clasificacionesHijas: clasificacion.clasificaciones_hijas
                                .filter(c =>
                                    estadistica.estadistica_clasificaciones.find(ec => ec.clasificacion_id == c.id)
                                )
                                .map(c => this.getEstadisticasClasificacion(c, estadistica))
                        }
                    })
            })
        })
    }

    getEstadisticasClasificacion(clasificacion, estadistica) {
        const estadisticaClasificacion = estadistica.estadistica_clasificaciones.find(
            ec => ec.clasificacion_id == clasificacion.id
        )

        if (!estadisticaClasificacion) return null

        const { correctas, incorrectas, omitidas } = estadisticaClasificacion

        return {
            clasificacion: clasificacion.clasificacion,
            desempeno: (100 * correctas) / (correctas + incorrectas + omitidas),
            correctas,
            total: correctas + incorrectas + omitidas
        }
    }

    public selectTab(tab) {
        Object.keys(this.tabs).forEach(tabName => (this.tabs[tabName] = false))
        this.tabs[tab] = true

        this.selectedTab = tab

        if (this.tabs.todas || this.tabs.correctas || this.tabs.omitidas || this.tabs.incorrectas) {
            if (this.secondLoadingLayout) {
                this.secondLoadingLayout.ready()
            }

            this.showPreguntas = true
        }
    }

    onRespuestaCorrectaUpdating() {
        this.updatingRespuesta = true
    }

    onRespuestaCorrectaChanged() {
        const eiParams = {}

        if (this.withCurso) eiParams["with_grupo_usuario"] = 1
        if (this.resultadosAlumno) eiParams["resultadosAlumno"] = this.resultadosAlumno

        this.evaluacionInstanciasService.find(this.evaluacionInstanciaId, eiParams).then(evaluacionInstancia => {
            this.evaluacionInstancia = evaluacionInstancia
            this.evaluacionInstanciaEscalasService.evaluacionInstancia.next(this.evaluacionInstancia)
            this.updatingRespuesta = false
        })
    }
}
