<!-- <logged-layout [freeContentStyle]="true">
    <logged-layout-over-title>
        <tiempo-pro></tiempo-pro>
        <arte-home-showcasebox-barra [pagina]="pagina"></arte-home-showcasebox-barra>
    </logged-layout-over-title>
    <logged-layout-titulo>Bienvenid@</logged-layout-titulo>
    <logged-layout-contenido>
        <div class="row"></div>
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <planes-estudios-showcasebox [maxColumns]="1"></planes-estudios-showcasebox>
            </div>
            <div class="col-md-4 col-sm-6">
                <ensayos-showcase></ensayos-showcase>
            </div>
            <div class="col-md-4 col-sm-6">
                <notificaciones-showcasebox
                    [maximoNotificaciones]="3"
                    [enableContentStyle]="false"
                ></notificaciones-showcasebox>
                <arte-home-showcasebox [pagina]="pagina"></arte-home-showcasebox>
                <br />
            </div>
        </div>
        <arte-zona-modal [pagina]="pagina"></arte-zona-modal>
    </logged-layout-contenido>
</logged-layout> -->
<ll-over-title>
    <arte-home-showcasebox-barra [pagina]="pagina"></arte-home-showcasebox-barra>
</ll-over-title>

<div *ngIf="mailSinConfirmar" class="alert alert-warning alert-dismissible" role="alert">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <strong>¡Advertencia!</strong>
    No ha confirmado su correo electrónico, por favor verificarlo.
</div>
<div class="row">
    <div class="col-md-4 col-sm-6">
        <ng-container *ngFor="let showcase of showcasesColumna[0]">
            <generic-showcasebox [showcase]="showcase"></generic-showcasebox>
        </ng-container>
    </div>
    <div class="col-md-4 col-sm-6">
        <ng-container *ngFor="let showcase of showcasesColumna[1]">
            <generic-showcasebox [showcase]="showcase"></generic-showcasebox>
        </ng-container>
        <ensayos-showcase></ensayos-showcase>
        <!-- No lo pongo dentro de los generic-showcasebox porque es un componente con ids hardcodeados -->
    </div>
    <div class="col-md-4 col-sm-6">
        <ng-container *ngFor="let showcase of showcasesColumna[2]">
            <generic-showcasebox [showcase]="showcase"></generic-showcasebox>
        </ng-container>
    </div>
</div>
<arte-zona-modal [pagina]="pagina"></arte-zona-modal>
