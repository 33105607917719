import { Component, OnInit, ViewChild, Input, SimpleChanges } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { BaseChart, BarChart, ChartColorType, I18nService, AppConfig } from "@puntaje/shared/core"
import { Store, select } from "@ngrx/store"
import { State, selectSelectedAsignatura } from "@puntaje/puntaje/store"
import { Subscription, combineLatest } from "rxjs"
declare const config: AppConfig

/*********************************
Gráfico de barras de desempeño por clasificación.
Recibe clasificaciones: string[], data: number[]
ej: [5, 3, 4]
**********************************/

@Component({
    selector: "grafico-desempeno-clasificacion",
    template: `
        <loading-layout #loadingLayout>
            <estadisticas-grafico
                [grafico]="grafico"
                *ngIf="!loadingLayout.loading && dataTotal.length > 0"
            ></estadisticas-grafico>
        </loading-layout>
    `
})
export class GraficoDesempenoClasificacionComponent implements OnInit {
    @Input() asignatura: Asignatura
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() clasificaciones: string[] = []
    @Input() data: number[] = []
    @Input() dataTotal: number[] = []
    @Input() tipoClasificacion: string
    @Input() tipoEvaluacion: string
    public grafico: BaseChart
    enableGraph: boolean = false
    subQuery: Subscription

    constructor(protected i18nService: I18nService, protected store: Store<State>) {}

    ngOnInit() {
        if (!this.tipoClasificacion) this.tipoClasificacion = "eje temático"

        this.subQuery = combineLatest([this.asignatura$]).subscribe(([asignatura]) => {
            this.asignatura = asignatura
            this.setGrafico()
        })
    }

    asignatura$ = this.store.pipe(select(selectSelectedAsignatura))

    setGrafico() {
        this.data = this.data || []
        this.dataTotal = this.dataTotal || []

        let sufix = ""
        if (this.asignatura) {
            let confEvaluacionTipo = this.asignatura["confByEvaluacionTipo"]?.[this.tipoEvaluacion]
            if (!confEvaluacionTipo) {
                confEvaluacionTipo = this.asignatura["confByEvaluacionTipo"]?.[config.plataforma.evaluacionDefault]
            }

            sufix = " para " + (confEvaluacionTipo?.abreviacion ?? this.asignatura.asignatura)
        }

        this.loadingLayout.standby()
        let g1: any = {}
        g1.titulo =
            this.i18nService.translate("grafico_desempeno_clasificacion.titulo") + this.tipoClasificacion + sufix
        g1.descripcion =
            this.i18nService.translate("grafico_desempeno_clasificacion.descripcion") + this.tipoClasificacion + "."
        g1.ejeX = this.clasificaciones
        g1.ejeY = { min: 0, max: 100 }
        g1.data = [
            {
                nombre: this.i18nService.translate("grafico_desempeno_clasificacion.desempeno"),
                data: this.data.map((x, i) => (100 * x) / this.dataTotal[i])
            }
        ]
        g1.tooltip = '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.1f}%</b><br/>'
        this.grafico = new BarChart(g1)
        this.loadingLayout.ready()
    }

    ngOnChanges(changes: SimpleChanges) {
        this.setGrafico()
    }
}
