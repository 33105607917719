import { BaseModel, Validations, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { UsuarioChile } from "./usuario_chile.model"
import { Lugar } from "./lugares.model"
import { PreferenciaCarrera } from "./preferencia_carreras.model"

export class UsuarioRegistroUah extends BaseModel {
    public nombre: string
    public apellido_paterno: string
    public apellido_materno: string
    public email: string
    public password: string
    public password_confirmation: string

    public rut: string
    public telefono: string
    public celular: string
    public nivel_id: number

    public preferencias_carrera: PreferenciaCarrera[] = []

    public static global_validations = [Validations.equalPasswords(["password", "password_confirmation"])]

    constructor() {
        super()
    }

    public nombreCompleto() {
        return this.nombre + " " + this.apellido_paterno + " " + this.apellido_materno
    }

    public toString() {
        return this.email
    }

    public setPreferenciaCarrera(preferencias) {
        this.preferencias_carrera = []
        for (let preferencia of preferencias) {
            let p = new PreferenciaCarrera()
            p.carrera_id = preferencia.id
            p.orden = preferencia.orden
            this.preferencias_carrera.push(p)
        }
    }

    public toUsuario() {
        var user = new Usuario()
        user.nombre = this.nombre
        user.apellido_paterno = this.apellido_paterno
        user.apellido_materno = this.apellido_materno
        user.email = this.email
        user.password = this.password
        user.password_confirmation = this.password_confirmation

        if (this.nivel_id) {
            user.nivel_id = this.nivel_id
        }
        user.pais_id = 1 // Generalizar
        user.rol_id = 3 // Generalizar, debería siempre ser 3 (alumno)
        user.usuario_colombia = null
        if (!user.usuario_chile) {
            user.usuario_chile = new UsuarioChile()
        }
        user.usuario_chile.rut = this.rut
        user.usuario_chile.telefono = this.telefono
        user.usuario_chile.celular = this.celular
        user.preferencia_carreras = this.preferencias_carrera
        return user
    }
}
