<nav class="navbar navbar-default">
    <div class="navbar-collapse collapse nav-justified-menu">
        <ul class="nav navbar-nav">
            <!-- <li routerLinkActive="active-menu">
                <a [routerLink]="['/dudas_categorias']" role="button"><ogr-icon name='dialogos' align="top"></ogr-icon>&nbsp;Comunidad</a>
                <a [routerLink]="['/dudas']" role="button"><ogr-icon name='dialogos' align="top"></ogr-icon>&nbsp;Comunidad</a>
            </li> -->
            <!-- <li routerLinkActive="active-menu">
                <a [routerLink]="['/pruebas']" role="button"><ogr-icon name='guia' align="top"></ogr-icon>&nbsp;Pruebas curriculares</a>
            </li> -->
            <li routerLinkActive="active-menu">
                <a [routerLink]="['/planes_estudio']" role="button">
                    <ogr-icon name="editar-prueba" align="top"></ogr-icon>
                    &nbsp;Planes de Estudio
                </a>
                <a
                    [routerLink]="['/planes_estudio']"
                    style="display: none; pointer-events: none; visibility: hidden"
                ></a>
                <a
                    [routerLink]="['/plan_estudios']"
                    style="display: none; pointer-events: none; visibility: hidden"
                ></a>
                <a
                    [routerLink]="['/planes_personales']"
                    style="display: none; pointer-events: none; visibility: hidden"
                ></a>
            </li>
            <li routerLinkActive="active-menu">
                <a [routerLink]="['/ensayos']" role="button">
                    <ogr-icon name="prueba" align="top"></ogr-icon>
                    &nbsp;Generador de Ensayos
                </a>
            </li>
            <li routerLinkActive="active-menu">
                <a [routerLink]="['/bibliotecas']" role="button">
                    <ogr-icon name="libro2-o" align="top"></ogr-icon>
                    &nbsp;Biblioteca
                </a>
                <a [routerLink]="['/biblioteca']" style="display: none; pointer-events: none; visibility: hidden"></a>
                <a [routerLink]="['/materiales']" style="display: none; pointer-events: none; visibility: hidden"></a>
            </li>
            <!-- <li routerLinkActive="active-menu">
                <a [routerLink]="['/streaming']" role="button">
                    <ogr-icon name='video' align="top"></ogr-icon>&nbsp;Videos y clases
                </a>
                <i checkStreamingOnline name="camara-video" class="live">
                    <ogr-icon name="camara-video" class="icono"></ogr-icon>
                    en vivo
                </i>
            </li> -->
        </ul>
    </div>
</nav>
