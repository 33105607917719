import { AppConfig, BaseForm, Validations } from "@puntaje/shared/core"

import { Validators } from "@angular/forms"
import { Lugares } from "./lugares.service"
import { Usuarios } from "./usuarios.service"
import { Clasificaciones } from "@puntaje/nebulosa/api-services"
import { HttpClient } from "@angular/common/http"

declare const config: AppConfig

export class UsuarioRegistroUahForm extends BaseForm {
    companyName: string = ""

    public static get formParams(): any {
        let companyName = config.plataforma.info.companyName
        let cursos: string[] = ["Egresado", "Cuarto Medio", "Tercero Medio", "Segundo Medio", "Primero Medio"]

        return {
            nombre: {
                label: "Nombre",
                type: "text",
                visible: true,
                placeholder: "Nombre",
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_paterno: {
                label: "Apellido paterno",
                type: "text",
                visible: true,
                placeholder: "Apellido paterno",
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_materno: {
                label: "Apellido materno",
                type: "text",
                visible: true,
                placeholder: "Apellido materno",
                validations: [Validators.minLength(2)]
            },
            rut: {
                label: "RUT",
                type: "text",
                visible: true,
                placeholder: "11111111-1",
                validations: [Validators.required, Validations.validateRut],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueRut }]
            },
            email: {
                label: "Email",
                type: "text",
                visible: true,
                placeholder: "correo@mail.com",
                validations: [Validators.required, Validations.validateEmail],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueEmail }]
            },
            password: {
                label: "Contraseña",
                type: "password",
                visible: true,
                placeholder: "Contraseña",
                validations: [Validators.required, Validators.minLength(8)],
                serviceValidators: [{ class: HttpClient, validator: Validations.checkPasswordBreach }]
            },
            password_confirmation: {
                label: "Confirmar contraseña",
                type: "password",
                visible: true,
                placeholder: "Repetir contraseña",
                global: true,
                validations: [Validators.required, Validators.minLength(8)]
            },
            terminos_y_condiciones: {
                innerhtml:
                    "He leído y acepto las <a href='/condicionesUso' target='_blank'>condiciones generales de uso de " +
                    companyName +
                    "</a>",
                type: "checkbox",
                visible: true,
                validations: [Validators.requiredTrue],
                customError: "Debe aceptar los términos generales de uso de " + companyName
            },
            telefono: {
                label: "Teléfono celular",
                type: "text-fix",
                visible: true,
                placeholder: "81234567",
                prefix: "+569",
                validations: [Validations.validateTelefonoUss]
            },
            nivel_id: {
                label: "Curso",
                type: "select",
                visible: true,
                options: {
                    class: Clasificaciones,
                    params: {
                        clasificacion_tipo: { clasificacion_tipo: "curso" },
                        clasificacion: { clasificacion: cursos }
                    }
                }
            },
            preferencia: { label: "Carrera", type: "select", visible: true },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true }
        }
    }
}
