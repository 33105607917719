import { BaseForm, Validations } from "@puntaje/shared/core"

import { Validators } from "@angular/forms"
import { Usuarios } from "./usuarios.service"

export class UsuariosCompletarRegistroSocialUahChileForm extends BaseForm {
    companyName: string = ""
    // constructor(){
    //  super();
    //  this.companyName = config.plataforma.info.companyName;
    // }

    public static get formParams(): any {
        return {
            usuario_id: { type: "hidden", visible: true },
            rut: {
                label: "RUT",
                type: "text",
                validations: [Validators.required, Validations.validateRut],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueRutNotSelf }]
            },
            telefono: {
                label: "Teléfono celular",
                type: "text-fix",
                visible: true,
                placeholder: "81234567",
                prefix: "+569",
                validations: [Validations.validateTelefonoUss]
            }
        }
    }
}
