import {
    AmazonType,
    ApiType,
    AppConfiguration,
    AppType,
    AsignaturaType,
    EvaluacionTipoMap,
    HojaRespuestaType,
    PlataformaType
} from "@puntaje/shared/core"

export class BaseConfig implements AppConfiguration {
    navegacion = {
        urlHistorial: ["/evaluaciones/resultados/historial"]
    }

    generar = {
        generar: true,
        intrumentosRecomendados: true,
        pruebasPropias: false,
        buscador: true
    }

    registro = {
        enableCarreras: true,
        carrerasObligatorio: true,
        universidades: ["Universidad San Sebastián "],
        amount: 3,
        enableNivel: true,
        nivelObligatorio: true,
        enableColegio: true,
        colegioObligatorio: true,
        enableTelefono: true,
        telefonoObligatorio: true,
        nombresObligatorio: true,
        apellidoPaternoObligatorio: true,
        apellidoMaternoObligatorio: true
    }
    app = {
        assets: {
            logoLarge: "assets/img/logos/logoLarge.png",
            logoMedium: "assets/img/logos/logoMedium.png",
            logoSmall: "assets/img/logos/logoSmall.png",
            logoLargeLight: "assets/img/logos/logoLargeLight.png",
            logoMediumLight: "assets/img/logos/logoMediumLight.png",
            logoSmallLight: "assets/img/logos/logoSmallLight.png",
            logoSquare: "assets/img/logos/logoSquare.png",
            logoSquareLight: "assets/img/logos/logoSquareLight.png",
            streamingOffline: ""
        },
        name: "profesores",
        loader: "aprendolibre2",
        routeUser: null,
        routeUserInfo: null,
        paths: {
            pendingInfo: "/pending_info",
            landing: "/landing"
        },
        baseStyle: {
            navbar: {
                logo: "logoSmallLight",
                logoMobile: "logoSquareLight"
            },
            footer: {
                logo: "logoSmallLight",
                logosExtras: [
                    {
                        link: "https://www.uahurtado.cl/",
                        src: "assets/img/acreditacion.svg",
                        title: "Acreditación"
                    }
                ]
            },
            landing: {
                backgroundImage:
                    "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/clones/fondo_uah.png",
                logo: "logoLargeLight",
                subtitle: "Prepara tu Prueba De Invierno con UAH",
                subtitleHasShadow: null,
                logoFooter: "logoSmallLight"
            },
            sharedLayout: {
                backgroundImage:
                    "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/clones/fondo_uah.png",
                logo: "logoMediumLight"
            }
        },
        navigationByApp: {
            alumnos: [
                {
                    icon: "editar-prueba",
                    name: "Desafíate personalizando tu ensayo",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "prueba",
                    name: "Practica",
                    route: "/evaluaciones/recomendadas"
                },
                {
                    icon: "resultados",
                    name: "Resultados",
                    route: "/pruebas/resultados"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes adaptativos",
                    route: "/planes_personales"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes de clase",
                    route: "/plan_clases"
                },
                {
                    icon: "dialogos",
                    name: "Foro en comunidad",
                    route: "/dudas_categorias"
                },
                {
                    icon: "trofeo-o",
                    name: "Logros",
                    route: "/usuario/:id/logro"
                }
            ],
            profesores: [
                {
                    icon: "editar-prueba",
                    name: "Evaluar",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "resultados",
                    name: "Resultados",
                    route: "/evaluaciones/resultados"
                },
                {
                    icon: "megafono-o",
                    name: "Mis noticias",
                    route: "/noticias"
                }
            ]
        },
        menuConfig: {
            tipo: "horizontal"
        },
        showcasesByApp: {
            alumnos: [
                {
                    nombre: "Planes de estudio",
                    identificador: "planes-estudios-showcasebox",
                    columna: 0,
                    orden: 0
                },
                {
                    nombre: "Notificaciones",
                    identificador: "notificaciones-showcasebox",
                    columna: 2,
                    orden: 0
                },
                {
                    nombre: "Banners",
                    identificador: "arte-home-showcasebox",
                    columna: 2,
                    orden: 1
                }
            ],
            profesores: []
        }
    } as AppType

    amazon = {
        s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/",
        s3BucketUriNebu: "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/"
    } as AmazonType

    api = {
        checkTokenPath: "/check_token",
        authPath: "/authenticate"
    } as ApiType

    asignaturas = [
        {
            id: null,
            asignatura: "Matemática",
            icono: "matematica-4",
            clase: "matematica",
            abreviacion: "Matemática",
            asignaturaPlataforma: "Matemática",
            evaluacionTipo: ["ensayo", "curricular", "diagnostico ensayo", "prueba de transición", "paes"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Lenguaje",
            icono: "lenguaje-4",
            clase: "lenguaje",
            abreviacion: "Lenguaje",
            asignaturaPlataforma: "Lenguaje",
            evaluacionTipo: ["ensayo", "curricular", "diagnostico ensayo", "prueba de transición", "paes"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Historia y Ciencias Sociales",
            icono: "historia-4",
            clase: "historia",
            abreviacion: "Historia",
            asignaturaPlataforma: "Historia y Ciencias Sociales",
            evaluacionTipo: ["ensayo", "curricular", "diagnostico ensayo", "prueba de transición", "paes"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Ciencias Naturales",
            icono: "ciencias-4",
            clase: "ciencias",
            abreviacion: "Ciencias",
            asignaturaPlataforma: "Ciencias",
            evaluacionTipo: ["prueba de transición", "paes"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Ciencias Física",
            icono: "fisica-4",
            clase: "fisica",
            abreviacion: "Física",
            asignaturaPlataforma: "Ciencias Física",
            evaluacionTipo: ["ensayo", "curricular", "diagnostico ensayo"],
            materialVisible: {
                ensayo: true,
                curricular: false
            }
        },
        {
            id: null,
            asignatura: "Ciencias Química",
            icono: "quimica-5",
            clase: "quimica",
            abreviacion: "Química",
            asignaturaPlataforma: "Ciencias Química",
            evaluacionTipo: ["ensayo", "curricular", "diagnostico ensayo"],
            materialVisible: {
                ensayo: true,
                curricular: false
            }
        },
        {
            id: null,
            asignatura: "Ciencias Biología",
            icono: "biologia-4",
            clase: "biologia",
            abreviacion: "Biología",
            asignaturaPlataforma: "Ciencias Biología",
            evaluacionTipo: ["ensayo", "curricular", "diagnostico ensayo"],
            materialVisible: {
                ensayo: true,
                curricular: false
            }
        }
    ] as AsignaturaType[]

    plataforma = {
        name: "UAH",
        id: null,
        pais: "chile",
        lugarTipo: "Comuna",
        lugarLabel: "Comuna",
        identificadorUsuario: ["RUT"],
        identificadorEstablecimiento: ["rbd"],
        grupoUsuarioAlias: "curso",
        clasificacionTipoNivel: "curso",
        info: {
            social: {
                facebook: "https://www.facebook.com/AdmisionUAH/",
                twitter: "https://www.instagram.com/admisionuah/?hl=es-la",
                youtube: "https://www.youtube.com/playlist?list=PL20DC17AF3CC63E6C"
            },
            soporte: {
                mail: "contactouah@ogr.cl",
                telefono: ""
            },
            contacto: {
                mail: "ventas@ogr.cl",
                telefono: ""
            },
            companyName: "ensayopsu.uahurtado.cl",
            companySite: "ensayopsu.uahurtado.cl"
        },
        evaluacionTipoAdaptiveDefault: "diagnostico prueba transicion",
        evaluacionDefault: "paes",
        resumenGeneral: {
            curriculum: "curricular",
            generadores: [83, 15, 16, 17, 18, 84, 19, 20, 85]
        },
        tiposInstrumentoInformeUso: ["curricular"],
        evaluacionTipos: ["paes"],
        añoInicial: 2020,
        periodos: [
            {
                label: "",
                fechaInicial: [0, 1],
                fechaFinal: [11, 31, 23, 59, 59]
            }
        ],
        streamingDefault: null,
        iconSet: {
            noticiasShowcase: "megafono-o",
            establecimientosShowcase: "establecimiento-o",
            cursosShowcase: "comunidad-o"
        },
        instrumentoMensualTipo: "curricular",
        tiposNotificacion: [
            {
                tipo: "curricular mexico",
                label: "Pruebas"
            },
            {
                tipo: "duda",
                label: "Dudas"
            },
            {
                tipo: "respuesta_correcta_duda",
                label: "Respuestas Correctas Dudas"
            },
            {
                tipo: "respuesta_duda",
                label: "Respuestas Dudas"
            },
            {
                tipo: "retroalimentacion",
                label: "Retroalimentaciones"
            }
        ],
        sesionAlias: "logoSquareLight",
        identificadorUsuarioAlias: null,
        canTextHighlight: true,
        labelHighlight: "Resaltar Texto",
        enableAchievements: true,
        locale: "es-cl",
        evaluacionResponder: "estandar",
        disableZopim: true,
        realizarButton: "Generar Prueba",
        descargarButton: "Descargar",
        customGenerateTitle: "Generar Prueba"
    } as PlataformaType

    evaluaciones = {
        "prueba de transición": {
            alias: "prueba de transición",
            nombreTipoCalificacion: "puntos",
            layaoutResultadoCalificacion: "horizontal",
            cantidadPreguntas: 5,
            clasificaciones: {
                estadisticas: ["nivel 1 prueba de transición", "nivel 2 prueba de transición"],
                groupBy: ["nivel 1 prueba de transición,nivel 2 prueba de transición"],
                clasificacionTiposTablaEspecificaciones: [
                    "nivel 1 prueba de transición",
                    "habilidad prueba de transición"
                ],
                configuration: [
                    {
                        name: "Nivel 1",
                        htmlId: "nivel1",
                        hasHijos: true,
                        clasificacionTipo: "nivel 1 prueba de transición"
                    }
                ],
                methods: "clasificaciones",
                referencesTo: {
                    "nivel 2 prueba de transición": "nivel 1 prueba de transición"
                },
                cambiarPregunta: ["nivel 1 prueba de transición"],
                desempenoEvaluacion: "nivel 1 prueba de transición,nivel 2 prueba de transición",
                estadisticasEvolutivas: "nivel 1 prueba de transición,nivel 2 prueba de transición"
            },
            clasificacionDistribucion: "ejes",
            clasificacionTipoResultados: "eje temático LGE",
            clasificacionMaterial: {
                clasificacion: "nivel 1 prueba de transición",
                label: "eje"
            },
            planPersonalClasificacionMateriales: [],
            clasificacionTiposEstadisticas: [
                {
                    nombre: "nivel 1 prueba de transición",
                    compuesto: true,
                    alumnos: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "nivel 2 prueba de transición",
                    compuesto: true,
                    alumnos: false,
                    profesores: true,
                    informeAnual: false
                },
                {
                    nombre: "habilidad prueba de transición",
                    compuesto: true,
                    alumnos: false,
                    profesores: true,
                    informeAnual: true
                }
            ],
            cursoTipo: "curso",
            cursos: [
                {
                    label: "1º Medio",
                    clasificacion: "Primero Medio"
                },
                {
                    label: "2º Medio",
                    clasificacion: "Segundo Medio"
                },
                {
                    label: "3º Medio",
                    clasificacion: "Tercero Medio"
                },
                {
                    label: "4º Medio",
                    clasificacion: "Cuarto Medio"
                }
            ],
            cursosMaterial: [],
            estrellas: [
                {
                    max: 266,
                    min: 150
                },
                {
                    max: 383,
                    min: 267
                },
                {
                    max: 500,
                    min: 384
                },
                {
                    max: 617,
                    min: 501
                },
                {
                    max: 734,
                    min: 618
                },
                {
                    max: 850,
                    min: 735
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            rangoCalificacion: {
                min: 150,
                tickInterval: 50,
                max: 850
            },
            streamingChannelAlias: "Clases Prueba de transición"
        },
        "diagnostico prueba transicion": {
            cursos: [],
            estrellas: [],
            cursosMaterial: [],
            clasificaciones: {
                groupBy: [],
                methods: "clasificaciones",
                estadisticas: [],
                referencesTo: {},
                configuration: [],
                cambiarPregunta: [],
                clasificacionTiposTablaEspecificaciones: [],
                desempenoEvaluacion: "nivel 1 prueba de transición,nivel 2 prueba de transición"
            },
            clasificacionMaterial: {},
            clasificacionTiposEstadisticas: [],
            nivelTipoInstrumentoInformeFinal: [],
            planPersonalClasificacionMateriales: [],
            rangoCalificacion: {
                min: 150,
                tickInterval: 50,
                max: 850
            }
        },
        paes: {
            alias: "PAES",
            cantidadPreguntas: 5,
            clasificaciones: {
                estadisticas: ["Eje PAES"],
                estadisticasEvolutivas: ["Eje PAES"],
                desempenoEvaluacion: "Eje PAES",
                groupBy: ["Eje PAES,Unidad Temática PAES"],
                clasificacionTiposTablaEspecificaciones: ["Eje PAES", "Unidad Temática PAES"],
                methods: "clasificaciones",
                referencesTo: {
                    "Unidad Temática PAES": "Eje PAES"
                },
                configuration: [
                    {
                        name: "Eje PAES",
                        htmlId: "Eje_PAES",
                        hasHijos: true,
                        clasificacionTipo: "Eje PAES"
                    }
                ],
                cambiarPregunta: ["nivel 1 prueba de transición"]
            },
            clasificacionDistribucion: "ejes",
            clasificacionMaterial: {
                clasificacion: "Eje PAES",
                label: "eje"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "Eje PAES",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "Unidad Temática PAES",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: false
                },
                {
                    nombre: "Habilidad PAES",
                    alumnos: false,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                }
            ],
            cursoTipo: "curso",
            cursos: [
                {
                    label: "7º Medio",
                    clasificacion: "Séptimo Básico"
                },
                {
                    label: "8º Medio",
                    clasificacion: "Octavo Básico"
                },
                {
                    label: "1º Medio",
                    clasificacion: "Primero Medio"
                },
                {
                    label: "2º Medio",
                    clasificacion: "Segundo Medio"
                },
                {
                    label: "3º Medio",
                    clasificacion: "Tercero Medio"
                },
                {
                    label: "4º Medio",
                    clasificacion: "Cuarto Medio"
                }
            ],
            cursosMaterial: [],
            estrellas: [
                {
                    max: 266,
                    min: 150
                },
                {
                    max: 383,
                    min: 267
                },
                {
                    max: 500,
                    min: 384
                },
                {
                    max: 617,
                    min: 501
                },
                {
                    max: 734,
                    min: 618
                },
                {
                    max: 850,
                    min: 735
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            rangoCalificacion: {
                min: 150,
                tickInterval: 50,
                max: 850
            },
            streamingChannelAlias: "Clases Prueba de transición",
            planPersonalClasificacionMateriales: []
        },
        curricular: {
            alias: "prueba",
            cantidadPreguntas: 4,
            clasificaciones: {
                clasificacionTiposMaterialPlanClase: [{ clasificacion: "eje temático LGE", label: "eje" }],
                estadisticas: ["eje temático LGE"],
                estadisticasEvolutivas: "eje temático LGE",
                desempenoEvaluacion: "eje temático LGE",
                groupBy: [
                    "eje temático LGE,objetivo de aprendizaje",
                    "unidad LGE,objetivo de aprendizaje",
                    "unidad LOCE,aprendizaje esperado"
                ],
                clasificacionTiposTablaEspecificaciones: ["objetivo de aprendizaje", "habilidad cognitiva LGE"],
                methods: "clasificaciones",
                referencesTo: {
                    "objetivo de aprendizaje": "curso"
                },
                configuration: [
                    {
                        clasificacionTipo: "eje temático LGE",
                        name: "Eje de contenidos",
                        htmlId: "eje",
                        hasHijos: true
                    },
                    {
                        clasificacionTipo: "unidad LGE",
                        name: "Unidad",
                        htmlId: "unidadlge",
                        hasHijos: true
                    },
                    {
                        clasificacionTipo: "unidad LOCE",
                        name: "Unidad",
                        htmlId: "unidad",
                        hasHijos: true
                    }
                ],
                cambiarPregunta: ["eje temático LGE", "objetivo de aprendizaje"]
            },
            clasificacionDistribucion: "ejes",
            clasificacionMaterial: {
                clasificacion: "eje temático LGE",
                label: "eje"
            },
            planPersonalClasificacionMateriales: [{ clasificacion: "eje temático LGE", label: "eje" }],
            clasificacionTiposEstadisticas: [
                {
                    nombre: "eje temático LGE",
                    compuesto: false,
                    alumnos: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    // HEY LISTEN! acordarse que hay que manejar este caso de forma general
                    nombre: "aprendizaje esperado",
                    compuesto: false,
                    alumnos: false,
                    profesores: false,
                    informeAnual: true
                },
                {
                    nombre: "objetivo de aprendizaje",
                    compuesto: false,
                    alumnos: false,
                    profesores: true,
                    informeAnual: false
                }
            ],
            cursos: [
                { clasificacion: "Séptimo Básico", label: "7º Básico" },
                { clasificacion: "Octavo Básico", label: "8º Básico" },
                { clasificacion: "Primero Medio", label: "1º Medio" },
                { clasificacion: "Segundo Medio", label: "2º Medio" },
                { clasificacion: "Tercero Medio", label: "3º Medio" },
                { clasificacion: "Cuarto Medio", label: "4º Medio" }
            ],
            cursosMaterial: [
                { id: 7, clasificacion: "Séptimo Básico", label: "7º Básico" },
                { id: 8, clasificacion: "Octavo Básico", label: "8º Básico" },
                { id: 9, clasificacion: "Primero Medio", label: "1º Medio" },
                { id: 10, clasificacion: "Segundo Medio", label: "2º Medio" },
                { id: 11, clasificacion: "Tercero Medio", label: "3º Medio" },
                { id: 12, clasificacion: "Cuarto Medio", label: "4º Medio" }
            ],
            estrellas: [
                { min: 1, max: 2 },
                { min: 2, max: 3 },
                { min: 3, max: 4 },
                { min: 4, max: 5 },
                { min: 5, max: 6 },
                { min: 6, max: 7 }
            ],
            nivelTipoInstrumentoInformeFinal: [
                "7º Básico",
                "8º Básico",
                "1º Medio",
                "2º Medio",
                "3º Medio",
                "4º Medio"
            ],
            rangoCalificacion: {
                min: 0,
                tickInterval: 25,
                max: 400
            },
            streamingChannelAlias: "Clases Curriculares",
            hideEstadistica: false,
            baseUrl: "/pruebas",
            cursoTipo: "curso",
            escalaEvaluacion: "nota"
        }
    } as EvaluacionTipoMap

    instrumentosPredefinidos = {
        zonas: ["Columna 1", "Columna 2", "Columna 3"]
    }

    hojaRespuesta = {
        sectores: [
            [1, 2],
            [2, 3],
            [3, 4],
            [5, 2],
            [2, 7],
            [7, 4],
            [5, 6],
            [6, 7],
            [7, 8]
        ],
        rutFormaByMarkers: {
            10: {
                rutMarkerUp: 6,
                rutMarkerBottom: 10,
                rutCols: 11,
                formaMarkerUp: 9,
                formaMarkerBottom: 6,
                formaCols: 11
            }
        },
        alignByMarkers: {
            10: [1, 5]
        },
        validMarkers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        alternativas: 5
    } as HojaRespuestaType

    mensajesLogin = {
        idPlaceholder: "Email o RUT",
        invalidLoginId: "Debes incluir un correo electrónico o RUT válido.",
        invalidLoginMessage1:
            "<strong>La combinación de correo electrónico/contraseña o RUT/contraseña es incorrecta.</strong> Las contraseñas de ",
        invalidLoginMessage2:
            ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.'
    }
}
