import { Component, EventEmitter, ViewChild, Injector, ChangeDetectorRef } from "@angular/core"
import { BaseRegisterCompletarSocialComponent } from "../base_register_completar_social.component"
import { GenericModalComponent, AuthService, SessionService, BaseForm } from "@puntaje/shared/core"
import {
    Usuarios,
    GrupoUsuarios,
    Establecimientos,
    Lugares,
    UsuariosCompletarRegistroSocialUahForm
} from "@puntaje/puntaje/api-services"
import { Router } from "@angular/router"
import { Clasificaciones } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "usuario-completar-social-uah",
    templateUrl: "./register-completar-social-uah.component.html",
    styleUrls: ["../base_register_completar.component.scss"]
})
export class RegisterCompletarSocialUahComponent extends BaseRegisterCompletarSocialComponent {
    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent
    openModal: EventEmitter<any> = new EventEmitter<any>()

    formType: typeof BaseForm = UsuariosCompletarRegistroSocialUahForm
    params: any = this.formType.formParams
    usuarioPaisParams: any = this.params["usuario_" + this.pais].class.formParams

    constructor(
        usuariosService: Usuarios,
        authService: AuthService,
        sessionService: SessionService,
        router: Router,
        injector: Injector,
        grupoUsuariosService: GrupoUsuarios,
        cdr: ChangeDetectorRef,
        establecimientosService: Establecimientos,
        lugaresService: Lugares,
        clasificacionesService: Clasificaciones
    ) {
        super(
            usuariosService,
            authService,
            sessionService,
            router,
            injector,
            grupoUsuariosService,
            cdr,
            establecimientosService,
            lugaresService,
            clasificacionesService
        )
    }

    updatePreferencias(preferencias) {
        this.usuario.preferencia_carreras = preferencias
    }
}
