<ll-titulo>
    {{ evaluacionTipoAlias | titlecase }}
    {{ titulo ? titulo : ("evaluacion_recomendada.recomendadas" | t | genderize: tipoInstrumento) }}
</ll-titulo>

<ll-top-contenido>
    <menu-evaluacion-tipo-asignaturas
        [showTitleOverMenu]="false"
        [evaluacionTipos]="evaluacionTipos"
    ></menu-evaluacion-tipo-asignaturas>
</ll-top-contenido>

<evaluaciones-recomendadas-alumno
    *ngIf="asignaturaId"
    [asignaturaId]="asignaturaId"
    [evaluacionTipo]="tipoInstrumento"
></evaluaciones-recomendadas-alumno>
