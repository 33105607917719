import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from "@angular/router"
import { AppConfig, SessionService } from "@puntaje/shared/core"

declare const config: AppConfig

@Injectable({ providedIn: "root" })
export class ProGuard implements CanActivate {
    constructor(private sessionService: SessionService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (config.plataforma.name == "Tu Reto") {
            if (!this.sessionService.isPro()) {
                this.router.navigate(["/completar_pago"])

                return false
            }
        }

        return this.sessionService.isPro()
    }
}
