<cui-showcasebox [openStyle]="true">
    <cui-showcasebox-title-icon>
        <ogr-icon name="prueba" class="icon"></ogr-icon>
    </cui-showcasebox-title-icon>
    <cui-showcasebox-title-text>Ensayos PAES</cui-showcasebox-title-text>
    <cui-showcasebox-content>
        <div class="ensayos">
            <p class="ensayo">
                <span class="ensayo-link">
                    <ogr-icon [name]="'matematica'" class="ogricon ensayo-icon"></ogr-icon>
                    Competencias Matemáticas
                </span>
                <button [routerLink]="['/ensayos', 4791, 'realizar']" class="btn ensayo-btn">Comenzar</button>
            </p>
            <p class="ensayo">
                <span class="ensayo-link">
                    <ogr-icon name="lenguaje" class="ogricon ensayo-icon lenguaje"></ogr-icon>
                    Competencias Lectoras
                </span>
                <button [routerLink]="['/ensayos', 4790, 'realizar']" class="btn ensayo-btn">Comenzar</button>
            </p>
        </div>
    </cui-showcasebox-content>
</cui-showcasebox>
